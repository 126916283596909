
<!-- <div class="paddington">
    <button mat-stroked-button color="primary" style="padding: 10px; padding-left: 20px; padding-right: 20px;" (click)="getBack()">
        <span style="font-size: 1rem; font-weight: 700;display: flex;align-items: center;">
            <mat-icon>arrow_back</mat-icon>&nbsp;
         Go Back
        </span> 
     </button>
    <button mat-flat-button color="primary" printSectionId="print" [useExistingCss]="true" style="padding: 10px; padding-left: 20px; padding-right: 20px;" ngxPrint>
       <div style="font-size: 1rem; font-weight: 700; display: flex;align-items: center;">
        <mat-icon>print</mat-icon>&nbsp;
        Print Invoice
       </div> 
    </button>
</div> -->

<!-- <mat-dialog-content > -->
   <!-- <div class="card" style="margin: 10px;">
        <mat-card>
<div class="print">

    <div class="wrapper1 margin1">
        <div class="image" >
            <img src="../..//../assets//images/sm-logo1.png" alt="LOGO0">
         
        </div>
        <div class="sales" style="border-left: hidden;">
            <div class="name">
                <span style="font-weight: 700;color: gray;"> Delivery Slip #:</span> 278
            </div>
            <div class="name">
                <span style="font-weight: 700;color: gray;">Delivery due date:</span> {{order.orderedDate.slice(0,10) | date:'dd MMM yyyy'}}
                
            </div>
        </div>
        <div class="contacts" style="border-top: hidden; border-bottom: hidden;">
            <h4>From:</h4>
            <div class="mar-left">{{wareHouseName}},</div>
            <div class="mar-left">{{address.addressLine1}} {{address.addressLine2}}</div>
            <div class="mar-left">{{address.street}} {{address.city}}</div>
            <div class="mar-left">{{address.state}} {{address.country}}</div>
            <div class="mar-left">{{address['pin/zip']}}</div>
        </div>
        <div class="company-details" style="border-top: hidden; border-bottom: hidden; border-left: hidden;">
            <h4>To:</h4>
            <div class="mar-left">{{order.shipToAddress.adNameFirst}} {{order.shipToAddress.adNameLast}},</div>
            <div class="mar-left">{{order.shipToAddress.adLine1}} {{order.shipToAddress.adLine2}}</div>
            <div class="mar-left">{{order.shipToAddress.street}} {{order.shipToAddress.city}}</div>
            <div class="mar-left">{{order.shipToAddress.state}} {{order.shipToAddress.country}}</div>
            <div class="mar-left">{{order.shipToAddress.zip}} {{order.shipToAddress.phone}}</div>
        </div>
        <div class="table" style="padding:0; border-left: hidden; border-right: hidden; border-bottom: hidden; margin-bottom: 0;">
            <table style="padding:0px; width: 100%;" cellspacing="0"> 
                <thead>
                    <tr>
                        <th class="head-font2">#.</th>
                        <th class="head-font2">ID</th>
                        <th class="head-font2" style="width: 35%;">Items</th>
                        <th class="head-font2">Unit</th>
                        <th class="head-font2">Qty</th>
                        <th class="head-font2">Price/Item ({{order.totalAmount.currency}})</th>
                        <th class="head-font2">Total Price ({{order.totalAmount.currency}})</th>
                        <th class="head-font2">Tax (%)</th>
                        <th class="head-font2">Tax({{order.totalAmount.currency}})</th>
                        <th class="head-font2">Amount ({{order.totalAmount.currency}})</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let item of order.items.item;let indexItem=index;">
                        <td>{{indexItem+1}}</td>
                        <td>{{item.productId}}</td>
                        <td style="width: 35%;">{{item.productShortName||item.productLongName}}</td>
                        <td align="right">{{item.uom}}</td>
                        <td align="right">{{item.quantity}}</td>
                        <td align="right"> {{(item.price.actual).toFixed(2)}}  </td>
                        <td align="right">{{((item.price.actual)*(item.quantity)).toFixed(2)}}</td>
                        <td align="right">{{item.price.tax?(((item.price.tax/(item.price.actual))*100).toFixed(2)):'0.00'}}</td>
                        <td align="right">{{item.price.tax?(item.price.tax*item.quantity).toFixed(2):'0.00'}} </td>
                        <td align="right">{{((item.price.actual*item.quantity)+(item.price.tax!*item.quantity)).toFixed(2)}} </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div class="prices" style="border-top: hidden;">
            <div>
                <div class="head-font">Total Tax ({{order.totalAmount.currency}}):</div>
                <div>{{toNumber(order.netTaxAmount).toFixed(2)}}</div>
            </div>
            <div>
                <div class="head-font">Shipping Cost ({{order.totalAmount.currency}}):</div>
                <div>{{toNumber(order.shippingCost).toFixed(2)}}</div>
            </div>
            <div>
                <div class="head-font">Discount ({{order.totalAmount.currency}}):</div>
                <div>{{toNumber(order.totalDiscount).toFixed(2)}}</div>
            </div>
            <div>
                <div class="head-font">Grand Total ({{order.totalAmount.currency}}):</div>
                <div>{{toNumber(order.grandTotal).toFixed(2)}}</div>
            </div>
        </div>
        
        
    </div>
</div>    
</mat-card>

</div> -->

<mat-dialog-content *ngIf="order">
    <div id="print" class="print" style="margin:1em;">
    
   
    <div style="margin: 5px;" class="item-center">
    <div *ngIf="logo" class="column11"><img src="{{logo}}" height="80px" width="80px" onerror="this.style.display='none'"></div>
    <div class="column1">
        <h3>Delivery Slip
            <br>
            <b *ngIf="order">{{order.orderDisplayName}}</b>

        </h3> 
        <span>Delivery Due Date : {{order.deliveryTime | date:'dd MMM yyyy'}}</span><br>
        <span>Submitted Date :  {{order.orderedDate.slice(0,10) | date:'dd MMM yyyy'}}</span>
    </div>
</div>
<div style="margin:5px" class="">
   <div class="column3 flex-col">
    <div class="content-title">From </div>
    <div class="text-bold">{{wareHouseName}}</div>
    <div class="text-bold">{{address.addressLine1}} {{address.addressLine2}}</div>
    <div class="text-bold">{{address.street}} {{address.city}}</div>
    <div class="text-bold">{{address.state}} {{address.country}}</div>
    <div class="text-bold">{{address['pin/zip']}}</div>
   </div>
   <div  class="column3 flex-col">
    <div *ngIf="checkAddressId(order.retailerAddress.addressId,order.shipToAddress.addressId)" class="content-title">BILL TO </div>
    <div *ngIf="checkAddressId(order.retailerAddress.addressId,order.shipToAddress.addressId)" class="text-bold">{{order.retailerAddress.adNameFirst}} {{order.retailerAddress.adNameLast}}</div>
    <div *ngIf="checkAddressId(order.retailerAddress.addressId,order.shipToAddress.addressId)" class="text-bold">{{order.retailerAddress.adLine1}} {{order.retailerAddress.adLine2}}</div>
    <div *ngIf="checkAddressId(order.retailerAddress.addressId,order.shipToAddress.addressId)" class="text-bold">{{order.retailerAddress.street}} {{order.retailerAddress.city}}</div>
    <div *ngIf="checkAddressId(order.retailerAddress.addressId,order.shipToAddress.addressId)" class="text-bold">{{order.retailerAddress.state}} {{order.retailerAddress.country}}</div>
    <div *ngIf="checkAddressId(order.retailerAddress.addressId,order.shipToAddress.addressId)" class="text-bold">{{order.retailerAddress.zip}}</div>
    <div *ngIf="checkAddressId(order.retailerAddress.addressId,order.shipToAddress.addressId)" class="text-bold">{{order.retailerAddress.phone}}</div>
    </div>
   <div class="column3 flex-col">
    <div class="content-title">DELIVERY ADDRESS</div>
    <div class="text-bold">{{order.shipToAddress.adNameFirst}} {{order.shipToAddress.adNameLast}}</div>
    <div class="text-bold">{{order.shipToAddress.adLine1}} {{order.shipToAddress.adLine2}}</div>
    <div class="text-bold">{{order.shipToAddress.street}} {{order.shipToAddress.city}}</div>
    <div class="text-bold">{{order.shipToAddress.state}} {{order.shipToAddress.country}}</div>
    <div class="text-bold">{{order.shipToAddress.zip}}</div>
    <div class="text-bold">{{order.shipToAddress.phone || order.retailerShop.phone}}</div>
    <div class="text-bold">Buyer Group: {{order.buyerGroup}}</div>
   </div>
</div>
<div class="table-area" id="pdfTable" #pdfTable>
    <table>
        <thead><tr>
            <th>#</th>
            <th>SKU CODE</th>
            <th>ITEM NAME</th>
            <th>RACK INFO</th>
            <th>QTY</th>
            <th>UNIT</th>
            <th>UNIT PRICE <span>(EXCL.TAX)</span></th>
            <th class="number-val">TOTAL (EXCL TAX)</th></tr>
        </thead>
        <tbody>
            <tr *ngFor="let item of order.items.item;let indexItem=index;">
                <td>{{indexItem+1}}</td>
                <td>{{item.skuShopId || item.skuErpSrcId || item.skuId}}</td>
                <td>{{item.productShortName||item.productLongName}}</td> 
                <td *ngIf="item.rackCode">{{item.rackCode}}</td>
                <td *ngIf="!item.rackCode">-</td>
                <td>{{item.quantity}}</td>
                <td>{{item.uom}}</td>
                <td> {{item.price.unitPriceExclTax}}  </td> 
                <td class="number-val">{{item.price.totalItemPriceExclTax}} </td>
            </tr> <tr>  
           <td></td>
           <td></td>
           <td></td>
           <td></td> 
           <td class="font-weight600" colspan="3">SUB TOTAL (EXCL TAX)</td>
           <td class="number-val font-weight600">{{order.subTotalExclTax | currency : order.totalAmount.currency: 'symbol'}}</td>
       </tr>          
        </tbody>
    </table>
</div>
<div class="flex-container">
    <div class="column flex-col">
        <span class="content-title">Submitted By {{order.user.userType}}</span>
        <span class="font-weight600">   {{order.user.userName}} </span>
        <span  class="font-weight600">   {{order.user.phone}} </span>
        <span class="font-weight600">   {{order.user.email}} </span>
 
    </div>
    <div class="column">
       <table>
       <table style="width:100%">
        <tr>
            <th class="align-left">Tax Amount:</th>
            <td class="number-val">{{toNumber(order.netTaxAmount).toFixed(2) | currency : order.totalAmount.currency: 'symbol'}}</td>
          </tr>
        <tr>
            <th class="align-left">Discount Amount: <br>(%):</th>
            <td class="number-val">{{toNumber(order.totalDiscount).toFixed(2) | currency : order.totalAmount.currency: 'symbol'}} <br><span>({{order.discountPercent}})</span></td>
         </tr> 
           <tr>
            <th class="align-left">Shipping Cost:</th>
            <td class="number-val">{{toNumber(order.shippingCost).toFixed(2) | currency : order.totalAmount.currency: 'symbol'}}</td>
          </tr>
           <tr style="border-bottom:2px solid black ;">
              <th class="align-left">Grand Total:</th>
              <td class="number-val">{{toNumber(order.grandTotal).toFixed(2) | currency : order.totalAmount.currency: 'symbol'}}</td>
           </tr>
         </table>
   </table>
   </div>

</div>
<div *ngIf="order.userNotes" class="flex-col">
    <b>ORDER NOTE</b>
    <span>{{order.userNotes}}</span>
</div>
<mat-divider></mat-divider>
 
</div>
</mat-dialog-content>

<div class="paddington">
    <button mat-stroked-button (click)="close()" style="margin-right: 5px;" ><mat-icon>close</mat-icon> Close</button>
     <button  mat-icon-button class="example-icon"   matTooltip="Print Delivery Slip" printSectionId="print" [useExistingCss]="true" ngxPrint>
        <img src="../assets/icon/print.png" alt=""style="height:24px;">

    </button>
    <button mat-icon-button matTooltip="Download as PDF" (click)="openPDF()"><img src="../assets/icon/pdf.png" alt=""style="height:24px;"></button>
    <button mat-icon-button matTooltip="Download as Word" (click)="Export2Word()"><img src="../assets/icon/word.png" alt=""style="height:24px;"></button>
</div>