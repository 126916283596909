import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, UrlTree,Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from './Service/auth.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuardGuard implements CanActivate {


  constructor(private router:Router, private authService:AuthService){}


  canActivate(): boolean{
    if(!this.authService.isLoggedIn())
    {
      console.log('not true');
      
      this.router.navigate(['/login'])
      return false;
    }
    return true;
  }
  
}
