
<div>

    <mat-toolbar>
       <span><img style="Padding:0px,20px,10px,10px;" src="../../../assets/icon/Order_fulfillment.png" width='136' alt=""></span> 

        <nav mat-tab-nav-bar ngClass = "tabs">
            <a mat-tab-link routerLink="/live-orders" routerLinkActive="active-link"><strong>Live Orders</strong></a>
            <a mat-tab-link routerLink="/live-payments" routerLinkActive="active-link"><strong>Live Payments</strong></a>
            <a mat-tab-link routerLink="/order-history" routerLinkActive="active-link"><strong>Order History</strong></a>
            <a mat-tab-link routerLink="/payment-history" routerLinkActive="active-link"><strong>Payment History</strong></a>
           
         </nav>  
         
       <span class="example-spacer"></span>
       <!-- <button *ngIf="userName" style="margin-right:28px;" mat-icon-button (click)="openOfs()" [class.spinner]="loading" [disabled]="loading" class="example-icon">
        <img src="../assets/icon/cart.png"  alt="">
        <img src="../assets/icon/orderManager.png"  alt="" height="40px">
      </button>  -->
      <button mat-icon-button [matMenuTriggerFor]="menu" class="example-icon">
        <mat-icon color="primary">person</mat-icon>
      </button>
      
        <mat-menu #menu="matMenu" >
         <span mat-menu-item>{{businessName}}</span>
          
          <button mat-menu-item style="display: flex;
          align-items: center;
          justify-content: center;" (click)="logout()" matTooltip="Logout" matTooltipPosition="below">
            <mat-icon>logout</mat-icon>
        </button>
        </mat-menu>
      
    
   
     </mat-toolbar>
    
</div>
<div>
    <router-outlet></router-outlet>
</div>



