import { HttpErrorResponse } from '@angular/common/http';
import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { observable } from 'rxjs';
import { Payment, PaymentList } from 'src/app/Models/paymentList';
import { DataService } from 'src/app/Service/data.service';
import { OrderListService } from 'src/app/Service/order-list.service';
import { PaymentListService } from 'src/app/Service/payment-list.service';
import { ProcessPaymentService } from 'src/app/Service/process-payment.service';
import { ReportService } from 'src/app/Service/report.service';
import { ConfirmDialogComponent } from 'src/app/shared/components/confirm-dialog/confirm-dialog.component';
import { AddCommonPaymentDialogComponent } from '../add-common-payment-dialog/add-common-payment-dialog.component';
import { AddPaymentDialogComponent } from '../add-payment-dialog/add-payment-dialog.component';



@Component({
  selector: 'app-payment-details',
  templateUrl: './payment-details.component.html',
  styleUrls: ['./payment-details.component.css']
})
export class PaymentDetailsComponent implements OnInit {

  displayedColumns: string[]=['paymentId','orderId', 'buyer','submittedUser',  'paidAmount','date', 'paymentMethod', 'referenceNote','referenceNumber','action']
  dataSource:any; 
  disabled=true
  list:Payment[]=[]
  retailerList=JSON.parse(sessionStorage.getItem('retailers')!)
  @ViewChild('input') input!: ElementRef;
  @ViewChild(MatPaginator) paginator!:MatPaginator;
  @ViewChild(MatSort) sort!:MatSort;
  isError: boolean=false;
  ifExport: boolean=false;
  page: any;
  totalOrders: any;
 


  constructor(private orderListService:OrderListService,private cdr:ChangeDetectorRef,private paymentListService:PaymentListService,private reportService:ReportService,private dataService:DataService,private processPaymentService:ProcessPaymentService,private router:Router,private toastr:ToastrService,private dialog:MatDialog) { 
  }
      
  ngOnInit(): void {
    this.paymentListService.getPayments("live","25","0","").subscribe(data=>{
      if(data){
      this.dataSource= new MatTableDataSource<any>(data.payment)
      this.dataSource.sort=this.sort;
      this.dataSource.paginator=this.paginator;
      
      this.disabled=false
      this.totalOrders = data.totalPaymentsCount
      }
      
    },(error:HttpErrorResponse)=>{
      console.log("errorororororor");
      this.disabled=false
      if (error instanceof HttpErrorResponse) {
        if (error.error instanceof ErrorEvent) {
          console.error("Error Event");
        } else {
          console.log(`error status : ${error.status} ${error.statusText}`);
          switch (error.status) {
            case 401:      //login
              this.router.navigateByUrl("/login");
              console.log(`redirect to login`);
              this.toastr.error("Session Expired, please login again")
              break;
            
          }
        }
      }
    })

   

  }

  /* ngAfterViewInit(): void {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  } */

  
  pageChanged(event:any){
      
    this.page = event;
    this.paymentListService.getPayments("live",event.pageSize.toString(), (event.pageIndex * event.pageSize).toString(),"")
    .subscribe(data=>{
      if(data)
      {
        this.dataSource=new MatTableDataSource(data.payment);
        this.cdr.detectChanges();
        /* this.dataSource.paginator=this.paginator;
        this.totalOrders = data.totalPaymentsCount */
        this.disabled=false
      }
      
    },(error:HttpErrorResponse)=>{
      console.log("errorororororor");
      this.disabled=false
      if (error instanceof HttpErrorResponse) {
        if (error.error instanceof ErrorEvent) {
          console.error("Error Event");
        } else {
          console.log(`error status : ${error.status} ${error.statusText}`);
          switch (error.status) {
            case 401:      //login
              this.router.navigateByUrl("/login");
              console.log(`redirect to login`);
              this.toastr.error("Session Expired, please login again")
              break;
            
          }
        }
      }
    })
}

  /* filterBuyer(buyer:string)
  {
        let filterList=this.dataSource.filter((x: { retailer: { name: string | string[]; }; })=>x.retailer.name.includes(buyer))
          this.dataSource= new MatTableDataSource<Payment>(filterList)
          this.dataSource.sort=this.sort;
          this.dataSource.paginator=this.paginator;
  }
  
  buyer(event:any){

    let buyer=event.value==="all"?"":event.value;
    this.filterBuyer(buyer);
  } */

  applyFilter(filterValue: string) {
    /* const filterValue = (event.target as HTMLInputElement).value; */
    this.dataSource.filter = filterValue;
  }

  searchByPaymentId(paymentId:string){
    this.paymentListService.getPayments("live","25","0",paymentId).subscribe(data=>{
      if(data){
      this.dataSource= new MatTableDataSource<any>(data.payment)
      this.cdr.detectChanges();
      }
      
    })
  }

  processPayment=(paymentId:string,value: string)=>{

    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      maxWidth: "400px",
      data: {
          title: "Confirm Payment Processing? This action cannot be undone.",
          btn1: "No",
          btn2: "Yes"
         }
    });
    dialogRef.afterClosed().subscribe((result: boolean) => {
      console.log('The dialog was closed');
      console.log(result);//returns undefined
     
    if(result === true)
    {
    
    this.processPaymentService.putProcessPayment(value,paymentId).subscribe (res=>{
      console.log(res);
      if(res.acknowledgement.status==="SUCCESS")
      {
        this.toastr.success(res.acknowledgement.message)
        if(this.page){
        this.paymentListService.getPayments("live",this.page.pageSize.toString(), (this.page.pageIndex * this.page.pageSize).toString(),"")
    .subscribe(data=>{
      if(data)
      {
        this.dataSource=new MatTableDataSource(data.payment);
        this.cdr.detectChanges();
       
      
        this.disabled=false
      }
      
    })
  }
  else{
    this.paymentListService.getPayments("live","25","0","").subscribe(data=>{
      if(data){
      this.dataSource= new MatTableDataSource<any>(data.payment)
      this.disabled=false
      }
      
    })
  }
        
      }
      else
      {this.disabled=false;
        this.toastr.error(res.acknowledgement.message)}
      

    },(err:HttpErrorResponse)=>{
      this.disabled=false;
    this.toastr.error('ERROR','Unfortunately payment could not be processed,please try again')
      console.log("errorororororor");
    })
  }
  else{
    this.disabled=false
    return;
  }
  })
  }


  downloadPaymentReport(){
   
    this.isError=false;
    this.ifExport=true
    
        this.reportService.downloadPaymentReport("live").subscribe(
          (          res: { fileName: string; fileUrl: string; }) => {
            this.ifExport=false
            if(res.fileName)
           { 
             //window.open(res.fileUrl)
            // window.location.href =res.fileUrl;
            //var downloadURL = window.URL.createObjectURL(res.fileName);
            
            const link = document.createElement('a');
            link.setAttribute('target', '_self');
            link.setAttribute('href', res.fileUrl);
            link.setAttribute('download',res.fileName);
            document.body.appendChild(link);
            link.click();
            link.remove(); 
            
           }
            else
            this.toastr.error("Error downloading file")
            
          },
          (err: HttpErrorResponse) => {
            if (err) {
              this.ifExport=false
              this.toastr.error(err.error.message, "ERROR")
            }
  
  
          }
        );
      

    
  }
  openDialog(): void {
    const dialogRef = this.dialog.open(AddCommonPaymentDialogComponent, {
      width: '600px',
      data: {}
    });
    dialogRef.afterClosed().subscribe(result=>{
      if(result)
      {
        if(this.page){
          this.paymentListService.getPayments("live",this.page.pageSize.toString(), (this.page.pageIndex * this.page.pageSize).toString(),"")
      .subscribe(data=>{
        if(data)
        {
          this.dataSource=new MatTableDataSource(data.payment);
          this.cdr.detectChanges();
         
        }
        
      })
    }
    else{
      this.paymentListService.getPayments("live","25","0","").subscribe(data=>{
        if(data){
        this.dataSource= new MatTableDataSource<any>(data.payment)
      
        }
        
      })
    }
      }
    })
  }

  refresh_details(){
    this.input.nativeElement.value = '';
    if(this.page){
      this.paymentListService.getPayments("live",this.page.pageSize.toString(), (this.page.pageIndex * this.page.pageSize).toString(),"")
  .subscribe(data=>{
    if(data)
    {
      this.dataSource=new MatTableDataSource(data.payment);
      this.cdr.detectChanges();
     
    }
    
  })
}
else{
  this.paymentListService.getPayments("live","25","0","").subscribe(data=>{
    if(data){
    this.dataSource= new MatTableDataSource<any>(data.payment)
   
    }
    
  })
}
     
  }
}
