import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpRequest, HttpHeaders, HttpBackend, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { ToastrService } from 'ngx-toastr';
import { Order } from '../Models/orderList';


@Injectable({
  providedIn: 'root'
})
export class ProcessOrderService {
  httpOptions = {
    headers: new HttpHeaders({ 'Authorization': sessionStorage.getItem('AuthorizationToken')! })
  }
  private http!: HttpClient

  baseUrl = environment.serviceURL;
  constructor(
    handler: HttpBackend,
    private toastr: ToastrService
  ) {
    this.http = new HttpClient(handler);

  }

  bulkProcess=(obj: { orderIds: number[]; })=> {
    let httpOptions={
      headers:new HttpHeaders({'Authorization':sessionStorage.getItem('AuthorizationToken')!}),
     
      
  }

    return this.http.put<any>(this.baseUrl + 'process/orders',obj,httpOptions);
  }
  processOrder = (orderId:string, act: string,reason:string) => {
    let httpOptions={
      headers:new HttpHeaders({'Authorization':sessionStorage.getItem('AuthorizationToken')!}),
      params: new HttpParams().set('reasonForReject',reason)
      
  }

    return this.http.put<any>(this.baseUrl + 'order/' + orderId + '/' + act, {},httpOptions);
  }

  orderByIds= (obj:string)=> {
    let httpOptions={
      headers:new HttpHeaders({'Authorization':sessionStorage.getItem('AuthorizationToken')!}),
     /*  params : new HttpParams().set('orderIds', obj)  */ 
      
  }

    return this.http.get<any>(this.baseUrl + 'order'+ '?orderIds=' +obj,httpOptions);
  }

  asyncProcessOrder = async(order: Order, act: string) => {
    this.httpOptions={
      headers:new HttpHeaders({'Authorization':sessionStorage.getItem('AuthorizationToken')!})
  }

    let res= await this.http.put<any>(this.baseUrl + 'order/' + order.orderId + '/' + act + '/', {}, this.httpOptions).toPromise();
    return res
  }

}
