import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router,Params } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from './../../Service/auth.service';
import { OrderService } from 'src/app/Service/order.service';

@Component({
  selector: 'app-sso',
  templateUrl: './sso.component.html',
  styleUrls: ['./sso.component.css']
})
export class SSOComponent implements OnInit {

  authToken:string="";
  errorMessage: any;

  constructor(private authService :AuthService,private router: Router,private route:ActivatedRoute,private toastr:ToastrService,private ordersService:OrderService) { }

  ngOnInit(): void {

    this.authToken=this.route.snapshot.params.token;

        this.authService.ssoLogin(this.authToken)
          .subscribe( async loginData => {
            console.log("here")
            if (loginData.body?.acknowledgment.status === "SUCCESS") {
              console.log(loginData.body);
              console.log("here")
              console.log(loginData.headers.get("Authorization"))
              console.log("here")
              sessionStorage.setItem("AuthorizationToken", loginData.headers.get("Authorization")!)
              console.log("here")
              console.log(sessionStorage.getItem("retailers"));
              console.log("here")
              
              sessionStorage.setItem("CompanyName", loginData.body.warehouse.warehouseShopName)
              sessionStorage.setItem("OriginalCompany",loginData.body.companyName)
              sessionStorage.setItem("Logo",loginData.body.warehouse.warehouseLogo)
              sessionStorage.setItem("Address",JSON.stringify(loginData.body.warehouse.address))
              sessionStorage.setItem("WareHouseId",loginData.body.warehouse.warehouseShopId.toString())
              sessionStorage.setItem("isLogined", "true")
              console.log(sessionStorage.getItem('isLogined'));
              this.fetch_retailer(0,0,'')
              // sessionStorage.setItem("retailers",JSON.stringify(loginData.body.retailers))
              console.log(sessionStorage.getItem("retailers"));
              //this.orderListService.periodicFetch()
              this.router.navigateByUrl('/live-orders')
              
            }
            else if(loginData.body?.acknowledgment.status==="ERRORED")
            {
              console.log(loginData.body);
              console.log("here error ")
              this.toastr.error(loginData.body?.acknowledgment.status,loginData.body?.acknowledgment.message);
             
            }
          }, (err: HttpErrorResponse) => {
            console.log("errorororororor");
            console.log(err);
            if (err?.error?.acknowledgement?.status === "ERRORED") {
              this.errorMessage = err.error.acknowledgement.message
             
              this.toastr.error("ERROR", err.error.acknowledgement.message)
              console.log('error while logging in');
              
            }
            else
            {
              this.toastr.error("ERROR","Please try later")
              
            }

          });
     

  }

  async fetch_retailer(limit: number, offset: number, groupId: any) {
    // Cancel the previous subscription if any
   this.ordersService.getRetailersList(limit, offset, groupId).subscribe({
      next: (response) => {
        console.log('retailers list', response);
        sessionStorage.setItem("retailers",JSON.stringify(response.retailers))
      },
    });
  }

}
