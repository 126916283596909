import { Component, NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuardGuard } from './auth-guard.guard';
import { MyNavbarComponent } from './components/my-navbar/my-navbar.component';
import { OfsTableComponent } from './components/ofs-table/ofs-table.component';
import { OrderDetailsComponent } from './components/order-details/order-details.component';
import { PaymentDetailsComponent } from './components/payment-details/payment-details.component';
import { SSOComponent } from './components/sso/sso.component';
import { EditOrderPageComponent } from './pages/edit-order-page/edit-order-page.component';
import { LiveOrdersComponent } from './pages/live-orders/live-orders.component';
import { LoginPageComponent } from './pages/login-page/login-page.component';
import { OrderHistoryComponent } from './pages/order-history/order-history.component';
import { PagenotfoundComponent } from './pages/pagenotfound/pagenotfound.component';
import { PaymentHistoryComponent } from './pages/payment-history/payment-history.component';
import { ProFormaPageComponent } from './pages/pro-forma-page/pro-forma-page.component';

const routes: Routes = [
  {
    path:'',canActivate:[AuthGuardGuard],
    component:MyNavbarComponent,
    children:[
      {
        path:'live-orders', component:OfsTableComponent,
      },
      {
        path:'live-payments', component:PaymentDetailsComponent,
      },

      {
        path:'order-history',component:OrderHistoryComponent,
      },
      {
        path:'payment-history',component:PaymentHistoryComponent,
      },
      {
        path:'order-details',component:OrderDetailsComponent,
      },
      {
        path:'pro-forma-invoice',component:ProFormaPageComponent,
      },
      {
        path:'edit-order',component:EditOrderPageComponent,
      }
    ]
  },
  {
    path:'sso/:token',
    component:SSOComponent
  },
  {
    path:'login',
    component:LoginPageComponent
  },
  {
    path: '**',
    component:PagenotfoundComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes,{useHash:true})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
