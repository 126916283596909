<h4 style="text-align: left;color:'#3F51B5';">Edit Order : {{data.orderDisplayName || data.orderId}}</h4>
<mat-dialog-content >
    <div [formGroup]="form" >
    <div formArrayName="quantities" class="center-container">
        <div class="tableWPaginator">

            <table mat-table class="full-width-table" [dataSource]="dataSource" *ngIf="order">
                
                
                <!-- Id Column -->
                <ng-container matColumnDef="index">
                    <th mat-header-cell *matHeaderCellDef [ngStyle]="{'border-bottom': '1px solid rgba(63, 81, 181, 0.4)'}">SN</th>
                    <td mat-cell *matCellDef="let row;let i=index" [ngStyle]="{'border-bottom': '1px solid rgba(63, 81, 181, 0.4)'}">{{i+1}}</td>
                </ng-container>
                
                <!-- buyerName Column -->
                <ng-container matColumnDef="id">
                    <th mat-header-cell *matHeaderCellDef  [ngStyle]="{'border-bottom': '1px solid rgba(63, 81, 181, 0.4)'}">ID</th>
                    <td mat-cell *matCellDef="let row" [ngStyle]="{'border-bottom': '1px solid rgba(63, 81, 181, 0.4)'}">#{{row.skuShopId || row.skuErpSrcId || row.skuId}}</td>
                </ng-container>
                
                <!-- contactNumber Column -->
                <ng-container matColumnDef="item" >
                    <th mat-header-cell *matHeaderCellDef  [ngStyle]="{'border-bottom': '1px solid rgba(63, 81, 181, 0.4)'}">Item</th>
                    <td mat-cell *matCellDef="let row" [ngStyle]="{'border-bottom': '1px solid rgba(63, 81, 181, 0.4)'}">{{row.productShortName||row.productLongName}}</td>
                </ng-container>

                <ng-container matColumnDef="orderedqty" >
                    <th mat-header-cell *matHeaderCellDef  [ngStyle]="{'border-bottom': '1px solid rgba(63, 81, 181, 0.4)'}">Ordered Quantity</th>
                    <td mat-cell *matCellDef="let row" [ngStyle]="{'border-bottom': '1px solid rgba(63, 81, 181, 0.4)'}">{{row.quantity}}</td>
                </ng-container>
                
                <!-- Name Column -->
                <ng-container matColumnDef="qty" >
                    <th mat-header-cell  *matHeaderCellDef  [ngStyle]="{'border-bottom': '1px solid rgba(63, 81, 181, 0.4)'}">Processing Quantity</th>
                    <td mat-cell  *matCellDef="let row;let quantity of quantityArray.controls;let i=index;" [ngStyle]="{'border-bottom': '1px solid rgba(63, 81, 181, 0.4)'}" [formGroupName]="i">
                        <mat-form-field appearance="legacy">
                            <mat-label>
                                quantity
                            </mat-label>
                            <input matInput formControlName="quantity" required>
                        </mat-form-field>
                    </td>
                </ng-container>
                
                <tr mat-header-row *matHeaderRowDef="displayedColumns" ></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;let i=index;let quantity of quantityArray.controls;" [formGroupName]="i" ></tr>
                
            </table>

            <mat-slide-toggle #slide (change)="(onChange($event))">Split this Order?</mat-slide-toggle>

            <!-- <mat-paginator #paginator
            [length]="dataSource?.data?.length"
            [pageIndex]="0"
            [pageSize]="5"
            [pageSizeOptions]="[5, 10, 20]">
        </mat-paginator> -->
    </div>
    </div>
</div>

</mat-dialog-content >
<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close>Close</button>
    <button mat-flat-button color="primary" (click)="submit()"  [class.spinner]='disabled' [disabled]="disabled" style="padding: 5px 20px;">Submit</button>
</mat-dialog-actions>
