import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpHeaders, HttpBackend,HttpParams } from '@angular/common/http';
import {ToastrService} from 'ngx-toastr'
import { environment } from 'src/environments/environment';
@Injectable({
  providedIn: 'root'
})
export class AddDiscountService {
  // private http!:HttpClient;
  baseUrl=environment.serviceURL;
  httpOptions={
    headers:new HttpHeaders({'Authorization':sessionStorage.getItem('AuthorizationToken')!})
  }
  constructor(handler:HttpBackend,private http:HttpClient,
    private toastr:ToastrService) 
    {
      // this.http=new HttpClient(handler);
     }
     postAddDiscount=(obj:any,orderId:number)=>{
      this.httpOptions={
        headers:new HttpHeaders({'Authorization':sessionStorage.getItem('AuthorizationToken')!})
    }
        return this.http.post<any>(this.baseUrl+'order/discount/'+orderId,obj,this.httpOptions)
     }
     getDiscount=(id:string)=>{
      this.httpOptions={
        headers:new HttpHeaders({'Authorization':localStorage.getItem('AuthorizationToken')!})
    }
       return this.http.get<any>(this.baseUrl+'order/discount/'+id,this.httpOptions);
    }
     
}
