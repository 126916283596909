import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpHeaders, HttpBackend,HttpParams } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import {ToastrService} from 'ngx-toastr'
import { environment } from 'src/environments/environment';
import { OrderList } from '../Models/orderList';

@Injectable({
  providedIn: 'root'
})
export class SearchOrdersService {
  // private http!:HttpClient;
  baseUrl=environment.serviceURL
  

  constructor(handler:HttpBackend, private http: HttpClient,
    private toastr:ToastrService) 
    {
      // this.http=new HttpClient(handler);
     }
     getOrderHistoryWDate=(startDate:string,endDate:string,status:string,retailer:string)=>{
      let httpOptions={
        headers:new HttpHeaders({'Authorization':sessionStorage.getItem('AuthorizationToken')!}),
        params: new HttpParams().set('fromDate',startDate)
          .set('toDate',endDate)
      }
      
      return this.http
     .get<any>(this.baseUrl+'search/order',httpOptions);
    }
    getOrderHistory(){
      // let httpOptions={
      //   headers:new HttpHeaders({'Authorization':sessionStorage.getItem('AuthorizationToken')!}),
      //   params: new HttpParams().set('orderId',orderId)
          
      // }
      return this.http.get<any>(this.baseUrl+'search/order');
    }
    getOrderHistoryWId(orderId:string){
      let httpOptions={
        headers:new HttpHeaders({'Authorization':sessionStorage.getItem('AuthorizationToken')!}),
        params: new HttpParams().set('orderId',orderId)
          
      }
      return this.http.get<any>(this.baseUrl+'search/order',httpOptions);
    }

    getOrderHistoryWDateWstatus=(startDate:string,endDate:string,status:string,retailer:string)=>{
      let httpOptions={
        headers:new HttpHeaders({'Authorization':sessionStorage.getItem('AuthorizationToken')!}),
        params: new HttpParams().set('fromDate',startDate)
          .set('toDate',endDate)
          .set('orderStatus',status)
          
      }
      
      
      return this.http
     .get<any>(this.baseUrl+'search/order',httpOptions);
    }

    searchOrder=(startDate:string,endDate:string,status:string,retailer:string,orderId:string, groupIds: any, type:string, limit:any, offset:any)=>{
      if(startDate === null)
      startDate=""
      if(endDate === null)
      endDate=""
      if(retailer === null)
      retailer=""
      let httpOptions={
        headers:new HttpHeaders({'Authorization':sessionStorage.getItem('AuthorizationToken')!}),
        params: new HttpParams().set('fromDate',startDate)
          .set('toDate',endDate)
          .set('orderStatus',status)
          .set('orderId',orderId)
          .set('retailerName',retailer)
          .set('groupId',groupIds)
          .set('type',type)
          .set('limit',limit)
          .set('offset',offset)
      }
      
      
      return this.http
     .get<any>(this.baseUrl+'search/order',httpOptions);
    }

    summeryReport(orderId: string,retailer: string, startDate: string, endDate: string,status : string, groupIds: any) {
      if(startDate === null)
      startDate=""
      if(endDate === null)
      endDate=""
      if(retailer === null)
      retailer=""
      let httpOptions={
        headers:new HttpHeaders({'Authorization':sessionStorage.getItem('AuthorizationToken')!}),
        params: new HttpParams().set('fromDate',startDate)
          .set('toDate',endDate)
          .set('orderStatus',status)
          .set('orderId',orderId)
          .set('retailerName',retailer)
          .set('groupId',groupIds)
      }
      return this.http.get<any>(`${this.baseUrl}resultSummary`, httpOptions)
    }

}
