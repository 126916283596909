import { DatePipe } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { Inject } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSelectChange } from '@angular/material/select';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AddPaymentModel,AddSharedPaymentModel } from 'src/app/Models/addPaymentModel';
import { Payment } from 'src/app/Models/paymentList';
import { AddPaymentService } from 'src/app/Service/add-payment.service';
import { AuthService } from 'src/app/Service/auth.service';
import { DataService } from 'src/app/Service/data.service';
import { OrderService } from 'src/app/Service/order.service';

@Component({
  selector: 'app-add-common-payment-dialog',
  templateUrl: './add-common-payment-dialog.component.html',
  styleUrls: ['./add-common-payment-dialog.component.css']
})
export class AddCommonPaymentDialogComponent implements OnInit {
  
  spinner=false
  form!:FormGroup
  paymentModes=['Cash On Delivery', 'Cheque', 'Demand Draft', 'Online']
  retailerList:any;
  retailerName: any;
  retailerBalance: any='';
  
  constructor(public dialogRef: MatDialogRef<AddCommonPaymentDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data:any,private fb:FormBuilder,
    private addPaymentService:AddPaymentService,private datePipe:DatePipe,private ordersService:OrderService,
    private router:Router,private toastr:ToastrService, private authService: AuthService) { }
    
    ngOnInit(): void {
      // this.authService.getBuyerInfo().subscribe(res=>{
      //   console.log(res);
      //   this.retailerList = res.retailers;
      // });
      this.ordersService.getRetailersList(0, 0, '').subscribe({
        next: (response) => {
          console.log('retailers list', response);
          this.retailerList = response.retailers;
        },
      });
    this.form = this.fb.group({
      paidAmount: ['',[Validators.required, Validators.min(0)]],
      paidDate: [new Date(), Validators.required],
      paymentMode:['',Validators.required],
      referenceNumber:[''],
      paymentNote:[''],
      retailer:['',Validators.required]
    });
  }

  onNoClick(): void {
    this.dialogRef.close(false);
  
  
  }

  retailerSelected(change:any) {
    this.retailerName = this.retailerBalance =""
    this.retailerName = change.value.retailerName;
    this.retailerBalance = change.source.value.accountBalance;
    console.log(this.retailerName,this.retailerBalance )
    

  }
  myFilter1 = (d: Date | null): boolean => {
    const time = (d || new Date()).getTime();
    const today=new Date(Date.now()) 
    return (time<today.getTime());
  }

  submit=()=>{
    this.spinner=true
    var paymentObj:AddSharedPaymentModel={
      retailerId:this.form.value.retailer.retailerId.toString(),
      paidAmount:this.form.value.paidAmount.toString(),
      paidDate:this.datePipe.transform(this.form.value.paidDate , 'dd/MM/yyyy')?.toString()!,
      paymentModeId:this.form.value.paymentMode.toString(),
      paymentNote:this.form.value.paymentNote,
      refNum:this.form.value.referenceNumber,
      shopId:sessionStorage.getItem("WareHouseId")!

    }
    
    this.addPaymentService.addSharedPayement(paymentObj).subscribe(res=>{
      if(res.status==="SUCCESS")
      {
      this.toastr.success("SUCCESS",res.message)
      

    this.dialogRef.close(true);
      }
      else

this.toastr.error("Error",res.message)
    },(err:HttpErrorResponse)=>{
      console.log(err);
      this.toastr.error("ERROR",'Unfortunately Payment couldn\'t be added. Please Try again')
      
      console.log("errorororororor");
      // sessionStorage.removeItem("AuthorizationToken");
        // sessionStorage.removeItem("CompanyName");
        // sessionStorage.removeItem("userName");
        // sessionStorage.removeItem("passWord");
        // sessionStorage.setItem("isLogined","false");
        
        this.dialogRef.close(false);
        
    }
    )
    
  }

  

}
