import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpHeaders, HttpBackend,HttpParams } from '@angular/common/http';
import {ToastrService} from 'ngx-toastr'
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ProcessPaymentService {
private http!:HttpClient;
baseUrl=environment.serviceURL;
httpOptions={
  headers:new HttpHeaders({'Authorization':sessionStorage.getItem('AuthorizationToken')!})
}
  constructor(handler:HttpBackend,
    private toastr:ToastrService) 
    {
      this.http=new HttpClient(handler);
     }
     putProcessPayment=(action:string,paymentId:string):Observable<any>=>{
      this.httpOptions={
        headers:new HttpHeaders({'Authorization':sessionStorage.getItem('AuthorizationToken')!})
      }
      return this.http.put<any>(this.baseUrl+'payment/'+paymentId+'/'+action,{},this.httpOptions)
     }
}
