import { HttpErrorResponse } from '@angular/common/http';
import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { observable } from 'rxjs';
import { AddCommonPaymentDialogComponent } from 'src/app/components/add-common-payment-dialog/add-common-payment-dialog.component';
import { Payment, PaymentList } from 'src/app/Models/paymentList';
import { DataService } from 'src/app/Service/data.service';
import { OrderListService } from 'src/app/Service/order-list.service';
import { PaymentListService } from 'src/app/Service/payment-list.service';
import { ProcessPaymentService } from 'src/app/Service/process-payment.service'; 
import { ReportService } from 'src/app/Service/report.service';
import { ConfirmDialogComponent } from 'src/app/shared/components/confirm-dialog/confirm-dialog.component';

@Component({
  selector: 'app-payment-history',
  templateUrl: './payment-history.component.html',
  styleUrls: ['./payment-history.component.css']
})
export class PaymentHistoryComponent implements OnInit {displayedColumns: string[]=['paymentId','orderId', 'buyer','submittedUser',  'paidAmount','date', 'paymentMethod', 'referenceNote','referenceNumber','action']
dataSource:any;
disabled=true
list:Payment[]=[]
retailerList=JSON.parse(sessionStorage.getItem('retailers')!)
@ViewChild('input') input!: ElementRef;
@ViewChild(MatPaginator) paginator!:MatPaginator;
@ViewChild(MatSort) sort!:MatSort;
  isError: boolean=false;
  totalOrders: number=0;

  ifExport: boolean=false;
  page: any;



constructor(private orderListService:OrderListService,private cdr:ChangeDetectorRef,private paymentListService:PaymentListService,private reportService:ReportService,private dataService:DataService,private processPaymentService:ProcessPaymentService,private router:Router,private toastr:ToastrService,private dialog:MatDialog) { 
  
}
  ngOnInit(): void { 
    this.generate();
  }

generate(){
  this.paymentListService.getPayments("history","25","0","").subscribe(data=>{
    if(data){
    this.dataSource= new MatTableDataSource<any>(data.payment)
    this.dataSource.sort=this.sort;
    this.dataSource.paginator=this.paginator;
    this.disabled=false
    this.totalOrders = data.totalPaymentsCount
    }
    
  },(error:HttpErrorResponse)=>{
    console.log("errorororororor");
    this.disabled=false
    if (error instanceof HttpErrorResponse) {
      if (error.error instanceof ErrorEvent) {
        console.error("Error Event");
      } else {
        console.log(`error status : ${error.status} ${error.statusText}`);
        switch (error.status) {
          case 401:      //login
            this.router.navigateByUrl("/login");
            console.log(`redirect to login`);
            this.toastr.error("Session Expired, please login again")
            break;
          
        }
      }
    }
  })
}
statusColour(status:any){
  if(status==='Pending')
    return '#ecbc29'
else if(status==='Accepted')
  return '#45b6af'
else if(status==='Shipped')
  return '#e563a2'
else if(status==='Rejected')
  return '#f3565d'
else
  return '#72ac69'
  
}
/* ngAfterViewInit(): void {
  this.dataSource.paginator = this.paginator;
  this.dataSource.sort = this.sort;
} */

filterBuyer(buyer:string)
{
      let filterList=this.list.filter(x=>x.retailer.name.includes(buyer))
        this.dataSource= new MatTableDataSource<Payment>(filterList)
        this.dataSource.sort=this.sort;
        this.dataSource.paginator=this.paginator;
}

buyer(event:any){

  let buyer=event.value==="all"?"":event.value;
  this.filterBuyer(buyer);
}


applyFilter(event: Event) {
  const filterValue = (event.target as HTMLInputElement).value;
  this.dataSource.filter = filterValue.trim().toLowerCase();
  this.dataSource.paginator=this.paginator;
 
}

searchByPaymentId(paymentId:string){
  this.paymentListService.getPayments("history","25","0",paymentId).subscribe(data=>{
    if(data){
    this.dataSource= new MatTableDataSource<any>(data.payment)
    this.cdr.detectChanges();
    }
    
  })
}

pageChanged(event:any){
      
  this.page = event;
  this.paymentListService.getPayments("history",event.pageSize.toString(), (event.pageIndex * event.pageSize).toString(),"")
  .subscribe(data=>{
    if(data)
    {
      this.dataSource=new MatTableDataSource(data.payment);
      this.cdr.detectChanges();
      /* this.dataSource.paginator=this.paginator;
      this.totalOrders = data.totalPaymentsCount */
      this.disabled=false
    }
    
  },(err:HttpErrorResponse)=>{
    console.log("errorororororor");
    this.disabled=false
      this.toastr.error("ERROR","Couldn't fetch new orders")
  })
}


processPayment=(paymentId:string,value: string)=>{
  const dialogRef = this.dialog.open(ConfirmDialogComponent, {
    maxWidth: "400px",
    data: {
        title: "Confirm Payment Processing? This action cannot be undone.",
        btn1: "No",
        btn2: "Yes"
       }
  });
  dialogRef.afterClosed().subscribe((result: boolean) => {
    console.log('The dialog was closed');
    console.log(result);//returns undefined
   
  if(result === true)
  {
    
  this.processPaymentService.putProcessPayment(value,paymentId).subscribe (res=>{
    console.log(res);
    if(res.acknowledgement.status==="SUCCESS")
    {
      this.toastr.success(res.acknowledgement.message)
      if(this.page){
      this.paymentListService.getPayments("history",this.page.pageSize.toString(), (this.page.pageIndex * this.page.pageSize).toString(),"")
  .subscribe(data=>{
    if(data)
    {
      this.dataSource=new MatTableDataSource(data.payment);
      this.cdr.detectChanges();
      
    }
    
  })
}
else{
  this.paymentListService.getPayments("history","25","0","").subscribe(data=>{
    if(data){
    this.dataSource= new MatTableDataSource<any>(data.payment)
    
    }
    
  })
}
      
    }
    else
    this.toastr.error(res.acknowledgement.message)

  },(err:HttpErrorResponse)=>{
  
  this.toastr.error('ERROR','Unfortunately payment could not be processed,please try again')
    console.log("errorororororor");
  })
}
else{
return;
}
})
}

openDialog(): void {
  const dialogRef = this.dialog.open(AddCommonPaymentDialogComponent, {
    width: '600px',
    data: {}
  });
  dialogRef.afterClosed().subscribe(result=>{
    if(result)
    {
      if(this.page){
        this.paymentListService.getPayments("history",this.page.pageSize.toString(), (this.page.pageIndex * this.page.pageSize).toString(),"")
    .subscribe(data=>{
      if(data)
      {
        this.dataSource=new MatTableDataSource(data.payment);
        this.cdr.detectChanges();
       
      }
      
    })
  }
  else{
    this.paymentListService.getPayments("history","25","0","").subscribe(data=>{
      if(data){
      this.dataSource= new MatTableDataSource<any>(data.payment)
      this.dataSource.sort=this.sort;
      
      }
      
    })
  }
    }
  })
}

downloadPaymentReport(){
   
  this.isError=false;
  this.ifExport=true
  
      this.reportService.downloadPaymentReport("history").subscribe(
        (          res: { fileName: string; fileUrl: string; }) => {
          this.ifExport=false
          if(res.fileName)
         { 
           //window.open(res.fileUrl)
          // window.location.href =res.fileUrl;
          //var downloadURL = window.URL.createObjectURL(res.fileName);
          
          const link = document.createElement('a');
          link.setAttribute('target', '_self');
          link.setAttribute('href', res.fileUrl);
          link.setAttribute('download',res.fileName);
          document.body.appendChild(link);
          link.click();
          link.remove(); 
          
         }
          else
          this.toastr.error("Error downloading file")
          
        },
        (err: HttpErrorResponse) => {
          if (err) {
            this.ifExport=false
            this.toastr.error(err.error.message, "ERROR")
          }


        }
      );
    

  
}

refresh_details=async()=>{
  this.input.nativeElement.value = '';
 if(this.page){
    this.paymentListService.getPayments("history",this.page.pageSize.toString(), (this.page.pageIndex * this.page.pageSize).toString(),"")
.subscribe(data=>{
  if(data)
  {
    this.dataSource=new MatTableDataSource(data.payment);
    this.cdr.detectChanges();
    
  }
  
})
}
else{
this.paymentListService.getPayments("history","25","0","").subscribe(data=>{
  if(data){
  this.dataSource= new MatTableDataSource(data.payment)
  this.dataSource.paginator=this.paginator;
  this.totalOrders = data.totalPaymentsCount
  }
  
})
} 
   
}
}
