import { HttpErrorResponse } from '@angular/common/http';
import { ChangeDetectorRef, Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Order, OrderList } from 'src/app/Models/orderList';
import { EditOrderPageComponent } from 'src/app/pages/edit-order-page/edit-order-page.component';
import { ProFormaPageComponent } from 'src/app/pages/pro-forma-page/pro-forma-page.component';
import { DataService } from 'src/app/Service/data.service';
import { OrderByIdService } from 'src/app/Service/order-by-id.service';
import { ProcessOrderService } from 'src/app/Service/process-order.service';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { orderDetail } from '../../interfaces/orderDetail'
import { AddDiscountDialogComponent } from '../add-discount-dialog/add-discount-dialog.component';
import { AddPaymentDialogComponent } from '../add-payment-dialog/add-payment-dialog.component';
import { EditOrderDialogComponent } from '../edit-order-dialog/edit-order-dialog.component';
import { EditOrderTableComponent } from '../edit-order-table/edit-order-table.component';
import { EditShippingCostComponent } from '../edit-shipping-cost/edit-shipping-cost.component';
import { DeliverySlipComponent } from '../delivery-slip/delivery-slip.component';
import { UpdateLivePriceComponent } from '../update-live-price/update-live-price.component';
import { RejectDialogComponent } from 'src/app/shared/reject-dialog/reject-dialog.component';
import { ConfirmDialogComponent } from 'src/app/shared/components/confirm-dialog/confirm-dialog.component';




@Component({
  selector: 'app-order-details',
  templateUrl: './order-details.component.html',
  styleUrls: ['./order-details.component.css']
})



export class OrderDetailsComponent implements OnInit {
  displayedColumns: string[] = ['sn','Id', 'Items', 'Unit', 'Qty', 'Price/Item', 'Tax%', 'Amt']
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;
  @ViewChild(MatTable) table!: MatTable<Order>;
  nextStatus!: string;
  order!: Order;
  date!: string;
  orders!: Order[]
  backRoute = ""
  disabled = false;
  submttdDate: string="";
  hide: string | undefined;
  dataSource: any;
  order1: any;
  ifProcessing:boolean=false;

  constructor(private dataService: DataService, @Inject(MAT_DIALOG_DATA) public data: any, private route: ActivatedRoute, private processOrderService: ProcessOrderService, private orderByIdService: OrderByIdService, public dialog: MatDialog, private router: Router, private toastr: ToastrService,private cdr:ChangeDetectorRef) {
    // const param=this.route.snapshot.queryParamMap;
  
    if(data.order)
                  {
                    this.order = data.order
                    this.submttdDate = this.order.orderedDate.replace(/T/i, " ");
                    this.order.orderedDate = this.order.orderedDate.slice(0, 10);
                    this.dataSource = new MatTableDataSource(this.order.items.item);
                    this.dataSource.sort = this.sort;
                    this.dataSource.paginator = this.paginator;
                  }
    
    this.backRoute = data.from
    

  }


  ngOnInit(): void {
  }


    statusColour(status:any){
      if(status==='Submitted')
        return '#5094e3'
    else if(status==='Accepted')
      return '#ffa800'
    else if(status==='Shipped')
      return '#e563a2'
    else if(status==='Rejected' || 'Cancelled')
      return '#ca3433'
    else if(status==='Pending')
    return '#fbb117'
    else  if(status==='Delivered')
      return '#72ac69'
    else
     return '#000'
      
    }

  


  toDetails(id: string) {
    console.log(this.dataService.getOrderById(id));
    this.order = this.dataService.getOrderById(id)!;
    this.order1 = this.dataService.getOrderById(id)!;
    
  }


  processOrderById(orderId: string, action: string) {
    var reason = ""
    this.ifProcessing =true;
    if (orderId && action) {
      if (action === 'REJECT') {

        const dialogRef = this.dialog.open(RejectDialogComponent, {
          width: '400px',
        });

        dialogRef.afterClosed().subscribe(result => {
          if (result) {
            reason = result;
            this.processOrderService.processOrder(orderId, action, reason).subscribe(
              res => {

                if (res.acknowledgement.status === "SUCCESS") {
                  this.toastr.success("SUCCESS",res.acknowledgement.message)
                  this.orderByIdService.getOrderlist(this.order.orderId)
                .subscribe(
                  data => {

                    this.order = data.order[0]
                    this.dataSource = new MatTableDataSource(this.order.items.item);
                    this.cdr.detectChanges();
                    this.ifProcessing=false;
                  })

                }
                else {
                  this.ifProcessing=false;
                  this.toastr.error("ERROR", res.acknowledgement.message)
                }


              })



          }
        })


      }
      else {

        const dialogRef = this.dialog.open(ConfirmDialogComponent, {
          maxWidth: "400px",
          data: {
              title: "Confirm Process Order? This action cannot be undone.",
              btn1: "No",
              btn2: "Yes"
             }
        });
        dialogRef.afterClosed().subscribe((result: boolean) => {
          console.log('The dialog was closed');
          console.log(result);//returns undefined
         
        if(result === true)
        {
        this.processOrderService.processOrder(orderId, action, reason).subscribe(
          res => {

            if (res.acknowledgement.status === "SUCCESS") {
              this.toastr.success("SUCCESS",res.acknowledgement.message)
              this.orderByIdService.getOrderlist(this.order.orderId)
                .subscribe(
                  data => {

                    this.order = data.order[0]
                    this.dataSource = new MatTableDataSource(this.order.items.item);
                    this.cdr.detectChanges();
                    this.ifProcessing=false;
                  })
            }
            else {
              this.ifProcessing=false;
              this.toastr.error("ERROR", res.acknowledgement.message)
            }
          })
      }
      else{
        this.ifProcessing=false;
        return
      }
    })
    }
  }

  }

  



  openUpdatePrice(orderId: any, orderDisplayName:any): void {
    const dialogRefPrice = this.dialog.open(UpdateLivePriceComponent, {
      width: '900px',
      data: { orderId: orderId ,orderDisplayName: orderDisplayName}
    });
    dialogRefPrice.afterClosed().subscribe(result => {
      this.orderByIdService.getOrderlist(this.order.orderId)
      .subscribe(
        data => {

          this.order = data.order[0]
          this.dataSource = new MatTableDataSource(this.order.items.item);
          this.cdr.detectChanges();
          this.ifProcessing=false;
        })
      
    
     

    })

  }

  editShippingCost() {
    const dialogRef1 = this.dialog.open(EditShippingCostComponent, {
      width: '500px',
      data: { orderId: this.order.orderId, orderDisplayName:this.order.orderDisplayName }
    });

    dialogRef1.afterClosed().subscribe(result => {
      if (result) {

       
        this.orderByIdService.getOrderlist(this.order.orderId)
          .subscribe(
            data => {
              this.cdr.detectChanges();
              this.order = data.order[0]


            }

          )
      }
      else
        console.log('closed');


    });

  }

  calculation() {
    let sum: number = 0;
    if (this.dataSource)
      for (let row of this.dataSource.data) {
        if (row.price.totalItemPriceExclTax ) sum += +row.price.totalItemPriceExclTax;
      }
    return sum.toFixed(2);
  }
  

  openDialog(): void {
    const dialogRef = this.dialog.open(AddPaymentDialogComponent, {
      width: '600px',
      data: { orderId: this.order.orderId, orderDisplayName:this.order.orderDisplayName,retailerId:this.order.retailerShop.retailerShopId, shopId: this.order.warehouse.warehouseId,accountBalance:this.order.accountBalance }
    });

    dialogRef.afterClosed().subscribe(result => {
        this.orderByIdService.getOrderlist(this.order.orderId)
          .subscribe(
            data => {
              
              this.order = data.order[0]
              this.dataSource = new MatTableDataSource(this.order.items.item);
              this.cdr.detectChanges();
            }

          )

    });
  }

  openDialogDiscount(): void {
    console.log(this.order.orderId);
    const dialogRefDiscount = this.dialog.open(AddDiscountDialogComponent, {
      width: '900px',
      data: { id: this.order.orderId, no:this.order.orderDisplayName }
    });
    dialogRefDiscount.afterClosed().subscribe(result => {
     
if(result)

     {
            
        
        
        this.orderByIdService.getOrderlist(this.order.orderId)
          .subscribe(
            data => {
              
              
              this.order = data.order[0]
              this.dataSource = new MatTableDataSource(this.order.items.item);
              this.cdr.detectChanges();
             
            }, err => this.toastr.error('ERROR')
          )
      }

    });

  }

  toNumber = (number: any) => Number(number)
  
  toProForma() {

    
     
      const dialogRef = this.dialog.open(ProFormaPageComponent, {
        width:'900px',
        data: this.order.orderId
      } )
         
    }
  
  

  openDeliverySlip() {
    
              const dialogRef = this.dialog.open(DeliverySlipComponent, {
                width: '900px',height:'600px',
                data: this.order.orderId
              });
              
            

          
    //this.router.navigate(["/pro-forma-invoice"],{queryParams:{orderId:this.order.orderId,from:url}})
   
  }


  toEditOrder() {
    // let url=this.router.url
    // this.router.navigate(["/edit-order"],{queryParams:{orderId:this.order.orderId,from:url}})
    const dialogRef = this.dialog.open(EditOrderTableComponent, {
      width: "600px",
      data: { orderId: this.order.orderId,orderDisplayName:this.order.orderDisplayName,items: this.order.items }
    });
    dialogRef.afterClosed().subscribe(result => {
      this.orderByIdService.getOrderlist(this.order.orderId)
      .subscribe(
        data => {
          
          
          this.order = data.order[0]
          this.dataSource = new MatTableDataSource(this.order.items.item);
          this.cdr.detectChanges();
          
        }
      )
    });
  }
}
