import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpHeaders, HttpBackend,HttpParams } from '@angular/common/http';
import {ToastrService} from 'ngx-toastr'
import { environment } from 'src/environments/environment';
import {AddPaymentModel, AddSharedPaymentModel} from  '../Models/addPaymentModel'
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AddPaymentService {
  baseUrl=environment.serviceURL;
  private http!:HttpClient;
  httpOptions={
    headers:new HttpHeaders({'Authorization':sessionStorage.getItem('AuthorizationToken')!})
  }
  
  constructor(
    handler:HttpBackend,
    private toastr:ToastrService
  ) 
  {
    this.http=new HttpClient(handler);
   }
  addPayment=(paymentObj:AddPaymentModel):Observable<any>=>{
    this.httpOptions={
      headers:new HttpHeaders({'Authorization':sessionStorage.getItem('AuthorizationToken')!})
  }
    return this.http.post<any>(this.baseUrl+"payment/",paymentObj,this.httpOptions);}
  addSharedPayement=(paymentObj:AddSharedPaymentModel):Observable<any>=>{
    this.httpOptions={
      headers:new HttpHeaders({'Authorization':sessionStorage.getItem('AuthorizationToken')!})
  }
    return this.http.post<any>(this.baseUrl+"payment/",paymentObj,this.httpOptions);
  }
}
