<h5 mat-dialog-title>Edit the Shipping Cost of  Order : {{data.orderDisplayName || data.orderId}}</h5><br>
<div mat-dialog-content>

<form [formGroup]="shippingCostForm">
    <p>Please enter the new shipping cost</p>
  <mat-form-field appearance="legacy">
    <mat-label>Update shipping cost</mat-label>
    <input matInput  type="text" id="cost" name="cost" formControlName="cost">
  </mat-form-field>
</form>
</div>
<div mat-dialog-actions>
<button mat-button (click)="onNoClick()">Cancel</button>
<button mat-button color="primary" cdkFocusInitial (click)="submit()">Save</button>
</div>

