<h1 mat-dialog-title>Reason for rejection</h1>
<div mat-dialog-content>
  
  <form [formGroup]="form">
    <mat-form-field class="example-full-width">
        <mat-label>Please enter a reason for rejecting the Order</mat-label>
        <textarea matInput placeholder="Reason for rejection" formControlName="note"></textarea>
   
        <mat-error *ngIf="form.controls['note'].errors?.required">
           Reason is <strong>required</strong>
        </mat-error>
    </mat-form-field>
    
   
  </form>
</div>
<div mat-dialog-actions>
  <button mat-button (click)="onDismiss()">Cancel</button>
  <button mat-button color="primary" (click)="onConfirm()" cdkFocusInitial>OK</button>
</div>