<div>
    <div class="head center">
        <h2> Log Out</h2>
    </div>
    <hr>
    <div class="center" style="font-size: 1rem;">
        Are you sure you want to Log Out?
    </div>
    <hr>
    <div class="around">
        <button mat-stroked-button (click)="onNoClick()" style="width: 30%;">No</button>
        <button mat-flat-button color="primary" (click)="logout()" style="width: 30%;" >Yes</button>
    </div>
</div>