import { HttpErrorResponse } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { ShippingCost } from 'src/app/Models/shippingCostModel';
import { ShippingCostService } from 'src/app/Service/shipping-cost.service';

@Component({
  selector: 'app-edit-shipping-cost',
  templateUrl: './edit-shipping-cost.component.html',
  styleUrls: ['./edit-shipping-cost.component.css']
})
export class EditShippingCostComponent implements OnInit {

  public shippingCostForm:FormGroup;
  updatedCost: string = "";
   orderId:string=""
    constructor( @Inject(MAT_DIALOG_DATA) public data:any,private fb:FormBuilder, private formBuilder: FormBuilder,
    private dialogRef: MatDialogRef<EditShippingCostComponent>,
    private router:Router,private toastr:ToastrService,private shippingCostService:ShippingCostService) {
      this.orderId=data.orderId
    this.shippingCostForm = this.formBuilder.group({
      cost: ''
    });


  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  submit() {
    this.updatedCost=this.shippingCostForm.get('cost')?.value;
    if(this.updatedCost)
    {
    var shippingObj:ShippingCost={
      orderId:this.data.orderId,
      shippingCost:this.updatedCost

    }
    if(shippingObj){
      this.shippingCostService.editShipingCost(shippingObj).subscribe(res=>{
        console.log(res);
  
        if(res.status === 'SUCCESS')
        {
          this.toastr.success("SUCCESS",res.message)
        this.dialogRef.close(true);

        }
        else{
          this.toastr.error("ERROR",res.message)
          this.dialogRef.close(false);
        }

  
      },(err:HttpErrorResponse)=>{
        console.log("errorororororor");
          this.toastr.error("ERROR","Unable to edit the cost")
          this.dialogRef.close(false);
      }
      )
    }

  }

}

  ngOnInit(): void {
  }

}
