import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { OfsTableComponent } from './components/ofs-table/ofs-table.component';
import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatButtonModule} from '@angular/material/button';
import {MatFormFieldModule} from '@angular/material/form-field';
import { OrderDetailsComponent } from './components/order-details/order-details.component';
import { PaymentDetailsComponent } from './components/payment-details/payment-details.component';
import {MatToolbarModule} from '@angular/material/toolbar'
import {MatIconModule} from '@angular/material/icon'
import {MatSidenavModule} from '@angular/material/sidenav'
import {MatTooltipModule} from '@angular/material/tooltip';
import {MatGridListModule} from '@angular/material/grid-list';
import {MatListModule} from '@angular/material/list'
import {RouterModule} from '@angular/router'
import {AppRoutingModule} from './app-routing.module';
import { LiveOrdersComponent } from './pages/live-orders/live-orders.component'
import {MatTabsModule} from '@angular/material/tabs'
import {MatInputModule} from '@angular/material/input';
import { OrderHistoryComponent } from './pages/order-history/order-history.component';
import { MyNavbarComponent } from './components/my-navbar/my-navbar.component'
import {AvatarModule} from 'ngx-avatar'
import {MatDividerModule} from '@angular/material/divider'
import {MatExpansionModule} from '@angular/material/expansion';
import { HttpClientModule,HTTP_INTERCEPTORS } from '@angular/common/http';
import {NgxPaginationModule} from 'ngx-pagination';
import {MatMenuModule} from '@angular/material/menu'; 
import { CommonModule, DatePipe } from '@angular/common';
import {MatSlideToggleModule} from '@angular/material/slide-toggle'; 
import {MatDatepickerModule} from '@angular/material/datepicker'; 
import { MatNativeDateModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { LoginPageComponent } from './pages/login-page/login-page.component';
import {ToastrModule} from 'ngx-toastr';
import { PagenotfoundComponent } from './pages/pagenotfound/pagenotfound.component'
import { NgxCaptchaModule } from 'ngx-captcha';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {MatDialog, MatDialogModule} from '@angular/material/dialog'
import { AddPaymentDialogComponent } from './components/add-payment-dialog/add-payment-dialog.component';
import {MatButtonToggleModule} from '@angular/material/button-toggle';
import { AddDiscountDialogComponent } from './components/add-discount-dialog/add-discount-dialog.component';
import { OrderHistoryTableComponent } from './components/order-history-table/order-history-table.component';
import { AddCommonPaymentDialogComponent } from './components/add-common-payment-dialog/add-common-payment-dialog.component';
import {MatRadioModule} from '@angular/material/radio';
import { ForgotPasswordDialogComponent } from './components/forgot-password-dialog/forgot-password-dialog.component';
import { SpinnerComponentComponent } from './components/spinner-component/spinner-component.component';
import { LogoutDialogComponent } from './components/logout-dialog/logout-dialog.component';
import { CdkColumnDef } from '@angular/cdk/table';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import { ProFormaPageComponent } from './pages/pro-forma-page/pro-forma-page.component'; 
import { NgxPrintModule } from 'ngx-print';
import { EditOrderDialogComponent } from './components/edit-order-dialog/edit-order-dialog.component';
import { EditOrderPageComponent } from './pages/edit-order-page/edit-order-page.component';
import { EditOrderTableComponent } from './components/edit-order-table/edit-order-table.component';
import { DeliverySlipComponent } from './components/delivery-slip/delivery-slip.component';
import {MatCardModule} from '@angular/material/card';
import { AppInterceptor } from './utils/app-network-intercepter';
import { PaymentHistoryComponent } from './pages/payment-history/payment-history.component';
import { ConfirmDialogComponent } from './shared/components/confirm-dialog/confirm-dialog.component';
import { EditShippingCostComponent } from './components/edit-shipping-cost/edit-shipping-cost.component';
import { UpdateLivePriceComponent } from './components/update-live-price/update-live-price.component';
import { RejectDialogComponent } from './shared/reject-dialog/reject-dialog.component';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { SSOComponent } from './components/sso/sso.component';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';

@NgModule({
  declarations: [
    AppComponent,
    OfsTableComponent,
    OrderDetailsComponent,
    PaymentDetailsComponent,
    LiveOrdersComponent,
    OrderHistoryComponent,
    MyNavbarComponent,
    LoginPageComponent,
    PagenotfoundComponent,
    AddPaymentDialogComponent,
    AddDiscountDialogComponent,
    OrderHistoryTableComponent,
    AddCommonPaymentDialogComponent,
    ForgotPasswordDialogComponent,
    SpinnerComponentComponent,
    LogoutDialogComponent,
    ProFormaPageComponent,
    EditOrderDialogComponent,
    EditOrderPageComponent,
    EditOrderTableComponent,
    DeliverySlipComponent,
    PaymentHistoryComponent,
    ConfirmDialogComponent,
    EditShippingCostComponent,
    UpdateLivePriceComponent,
    RejectDialogComponent,
    SSOComponent,
    
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    CommonModule,
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    MatCheckboxModule,
    MatButtonModule,
    MatFormFieldModule,
    MatToolbarModule,
    MatIconModule,
    MatSidenavModule,
    MatListModule,
    RouterModule,
    AppRoutingModule,
    MatTabsModule,
    MatInputModule,
    AvatarModule,
    MatExpansionModule,
    MatDividerModule,
    HttpClientModule,
    MatMenuModule,
    MatSlideToggleModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatSelectModule,
    ToastrModule.forRoot(),
    NgxCaptchaModule,
    ReactiveFormsModule,
    FormsModule,
    MatCardModule,
    MatDialogModule,
    MatButtonToggleModule,
    MatRadioModule,
    MatTooltipModule,
    MatProgressSpinnerModule,
    NgxPrintModule,MatGridListModule,
    NgxPaginationModule,
    MatAutocompleteModule,
    NgMultiSelectDropDownModule,
    ],
  entryComponents: [
    ConfirmDialogComponent,
    EditShippingCostComponent,
    UpdateLivePriceComponent,
    RejectDialogComponent,
    EditOrderTableComponent
  ],
  providers: [DatePipe,CdkColumnDef ,{ provide: HTTP_INTERCEPTORS, useClass: AppInterceptor, multi: true }],
  bootstrap: [AppComponent]
})
export class AppModule { }
