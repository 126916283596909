import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment'

@Injectable({
  providedIn: 'root'
})
export class OrderService {
  baseUrl = environment.serviceURL;

  constructor(private httpclient: HttpClient) { }


getOrders(type:string, limit:string , offset:string): Observable<any> {
let params =new HttpParams()
 .set('type',type)
 .set ('limit',limit)
 .set ('offset',offset)
return this.httpclient.get(this.baseUrl +"ordersList",{params:params});
}

getRetailersList(limit:any, offset: any, groupId: any) : Observable<any> {
  return this.httpclient.get(`${this.baseUrl}retailerList?limit=${limit}&offset=${offset}&groupId=${groupId}`)
}

getBuyerGroups(retailerName: string) : Observable<any> {
  // if (retailerName === ''){
  //   return this.httpclient.get(this.baseUrl + "groups")
  // }
  // else {
    return this.httpclient.get(`${this.baseUrl}groups?retailerName=${retailerName}`)

  // }
}
  

}