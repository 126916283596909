import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpHeaders, HttpBackend, HttpParams } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr'
import { environment } from 'src/environments/environment';
import { OrderList } from '../Models/orderList';
import { PaymentList } from '../Models/paymentList';

@Injectable({
  providedIn: 'root'
})
export class PaymentListService {
  
  baseUrl = environment.serviceURL;
 
  constructor(
    handler: HttpBackend,
    private toastr: ToastrService, private http: HttpClient,
  ) {

  }
  getPaymentlist = () => {

    return this.http
      .get<PaymentList>(this.baseUrl + 'payment');
  }
  getPaymentHistory = () => {


    return this.http
      .get<PaymentList>(this.baseUrl + 'paymentHistory');
  }

 

  getPayments = (type:string, limit:string , offset:string,paymentId:string) => {
    let params =new HttpParams()
    .set('type',type)
    .set ('limit',limit)
    .set ('offset',offset)
    .set('paymentId',paymentId)
    return this.http
      .get<PaymentList>(this.baseUrl + 'payment',{params});
  }
}
