<div class="dialog-config">

    <div class="head">

        <h4 mat-dialog-title>Add payment for Buyer</h4>
    </div>
    <mat-dialog-content>
        <form [formGroup]="form">

            <div class="row p-2">
               
                    <mat-form-field appearance="legacy" class="full-width">
                        <mat-label>Buyer Name</mat-label>
                        <mat-select formControlName="retailer" (selectionChange)="retailerSelected($event)">
                            <mat-option *ngFor="let retailer of retailerList" [value]="retailer" >{{retailer.retailerName}}</mat-option>
                        </mat-select>
                        <mat-error>Add Buyer Name</mat-error>
                    </mat-form-field>
                
                   
                
                
            </div>

            <div class="payment-row">
                <div class="paddingtn">
                    <mat-form-field appearance="legacy" class="full-width">
                        <mat-label>Current Balance Amount</mat-label>
                        <input matInput readonly [value]="retailerBalance"/>
                        
                    </mat-form-field>
                </div>    
                    <mat-form-field appearance="legacy" class="full-width">
                        <mat-label>Amount</mat-label>
                        <input matInput type="number" formControlName="paidAmount" required/>
                        <mat-error>Add Payment Amount</mat-error>
                    </mat-form-field>
                
                
                
            </div>
            <div class="payment-row">
                <div class="paddingtn">
                    <mat-form-field appearance="legacy" class="full-width">
                        <mat-label>Paid Date</mat-label>
                        <input matInput  [matDatepicker]="picker2" formControlName="paidDate" required>
                        <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
                        <mat-datepicker #picker2></mat-datepicker>
                        <mat-error>Add Paid Date</mat-error>
                    </mat-form-field>
                </div>  
                    <mat-form-field appearance="legacy" class="full-width">
                        <mat-label>Payment Mode</mat-label>
                        <mat-select formControlName="paymentMode">
                            <mat-option *ngFor="let status of paymentModes;let i=index" [value]="i+1">{{status}}</mat-option>
                        </mat-select>
                        <mat-error>Add Payment Mode</mat-error>
                    </mat-form-field>
                    
               
               
            </div>
            
            
            <div class="payment-row">
                <div class="paddingtn">
                    <mat-form-field appearance="legacy" class="full-width">
                        <mat-label>Reference Number</mat-label>
                        <input matInput type="text"  formControlName="referenceNumber"/>
                    </mat-form-field>
                </div>

                <mat-form-field appearance="legacy" class="full-width">
                    <mat-label>Payment Note</mat-label>
                    <input matInput type="text" formControlName="paymentNote"/>
                </mat-form-field>
            </div>
            
            
            <div class="payment-row">
                <button mat-stroked-button (click)="onNoClick()" style="margin-right: 5px;" class="full-width"><mat-icon>close</mat-icon> Close</button>
                <button mat-flat-button color="primary" class="full-width" (click)="submit()" [disabled]="!form.valid||spinner" [class.spinner]='spinner'><mat-icon>price_check</mat-icon> Submit</button>
            </div>
        </form>
        </mat-dialog-content>
        
    </div>
    