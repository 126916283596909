<div>

    <div><h4  style="text-align: left;color:'#3F51B5';" >Update Price For Order :{{data.orderDisplayName || data.orderId}}</h4></div>

    <div *ngIf="!isPriceUpdated" class="alert alert-info" role="alert">
        No need to update price as ordered price and current price are the same.
    </div>

    <div mat-dialog-content class="p-2">
        <table mat-table [dataSource]="dataSource">


            <ng-container matColumnDef="Id">
                <th mat-header-cell  [ngStyle]="{'border-bottom': '1px solid rgba(63, 81, 181, 0.4)'}" width ="8%" *matHeaderCellDef> ID </th>
                <td mat-cell *matCellDef="let element" [ngStyle]="{'border-bottom': '1px solid rgba(63, 81, 181, 0.4)'}"> {{element.skuShopId || element.skuErpSrcId || element.skuId}} </td>
            
            </ng-container>
            <ng-container matColumnDef="Item">
                <th mat-header-cell  [ngStyle]="{'border-bottom': '1px solid rgba(63, 81, 181, 0.4)'}" width ="40%" *matHeaderCellDef>Item </th>
                <td mat-cell *matCellDef="let element" [ngStyle]="{'border-bottom': '1px solid rgba(63, 81, 181, 0.4)'}" class="truncate-cell" > {{element.name}} </td>
            
            </ng-container>
            <ng-container matColumnDef="Qty">
                <th mat-header-cell  [ngStyle]="{'border-bottom': '1px solid rgba(63, 81, 181, 0.4)'}" width ="7%" *matHeaderCellDef>Qty </th>
                <td mat-cell *matCellDef="let element" [ngStyle]="{'border-bottom': '1px solid rgba(63, 81, 181, 0.4)'}"> {{element.quantity}} </td>
            
            </ng-container>
            <ng-container matColumnDef="Price">
                <th mat-header-cell  [ngStyle]="{'border-bottom': '1px solid rgba(63, 81, 181, 0.4)'}" width ="15%" *matHeaderCellDef> Ordered Price<br>(Per Unit)</th>
                <td mat-cell *matCellDef="let element" [ngStyle]="{'border-bottom': '1px solid rgba(63, 81, 181, 0.4)'}"> {{element.price.orderedItemPrice}} </td>
            
            </ng-container>
            <ng-container matColumnDef="Live Price">
                <th mat-header-cell  [ngStyle]="{'border-bottom': '1px solid rgba(63, 81, 181, 0.4)'}" width ="15%" *matHeaderCellDef>Live Price 
                   <!-- <button mat-icon-button  (click)="editPrice()" matTooltip="Edit Live Price"><mat-icon style="width: 12px !important;
                    height: 12px !important;
                    line-height: 12px !important;">edit</mat-icon> -->
                   <button mat-icon-button  (click)="editPrice()" matTooltip="Edit Live Price">
                    <img src="../assets/icon/edit.png" alt=""style="height:15px;">
    
                </button><br>(Per Unit)</th>
                <td mat-cell *matCellDef="let element;let index" [ngStyle]="{'border-bottom': '1px solid rgba(63, 81, 181, 0.4)'}"> 
                    <mat-form-field class="pt-1" appearance="outline" style="width:100px!important;" [style.fontSize.px]=13>
                        <input matInput [(ngModel)]="element.price.liveItemPrice" [disabled]="!editMode" onkeypress="return String.fromCharCode(event.charCode).match(/[^0-9.]/g) === null">
                    </mat-form-field>
                     </td>
            
            </ng-container>

           


            <ng-container matColumnDef="Total">
                <th mat-header-cell  [ngStyle]="{'border-bottom': '1px solid rgba(63, 81, 181, 0.4)'}" width ="15%" *matHeaderCellDef> Total Live Price </th>
                <td mat-cell *matCellDef="let element" [ngStyle]="{'border-bottom': '1px solid rgba(63, 81, 181, 0.4)'}"> {{element.price.liveItemPriceTotal}} </td>
            
            </ng-container>
            
            
            
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
           
        </table>
    </div>

    <div><strong>Ordered Price: &#x20b9; {{orderedPrice}}</strong> </div>
    <div><strong>Current Price: &#x20b9; {{currentPrice}} </strong> </div>

   
    <mat-dialog-actions class="pt-2" align="end">
        <button mat-stroked-button [disabled]="(!isPriceUpdated && !editMode)" (click)="updatePrice()">Update</button>
    <button mat-button mat-dialog-close>Close</button> 
</mat-dialog-actions>

</div>
