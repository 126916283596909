import { SelectionModel } from '@angular/cdk/collections';
import { DatePipe } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { AfterViewInit, Component, ViewChild , ChangeDetectorRef, OnInit} from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatRadioChange } from '@angular/material/radio';
import { MatSort } from '@angular/material/sort';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import { Observable, Subscription } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { Order, OrderList } from 'src/app/Models/orderList';
import { EditOrderPageComponent } from 'src/app/pages/edit-order-page/edit-order-page.component';
import { ProFormaPageComponent } from 'src/app/pages/pro-forma-page/pro-forma-page.component';
import { DataService } from 'src/app/Service/data.service';
import { OrderByIdService } from 'src/app/Service/order-by-id.service';
import { OrderListService } from 'src/app/Service/order-list.service';
import { OrderService } from 'src/app/Service/order.service';
import { ProcessOrderService } from 'src/app/Service/process-order.service';
import { ReportService } from 'src/app/Service/report.service';
import { SearchOrdersService } from 'src/app/Service/search-orders.service';
import { ConfirmDialogComponent } from 'src/app/shared/components/confirm-dialog/confirm-dialog.component';
import { RejectDialogComponent } from 'src/app/shared/reject-dialog/reject-dialog.component';
import { AddDiscountDialogComponent } from '../add-discount-dialog/add-discount-dialog.component';
import { AddPaymentDialogComponent } from '../add-payment-dialog/add-payment-dialog.component';
import { DeliverySlipComponent } from '../delivery-slip/delivery-slip.component';
import { EditOrderTableComponent } from '../edit-order-table/edit-order-table.component';
import { OrderDetailsComponent } from '../order-details/order-details.component';
import { UpdateLivePriceComponent } from '../update-live-price/update-live-price.component';
import { MatSelect } from '@angular/material/select';
import { MatOption } from '@angular/material/core';


export interface SearchResultSummary {
 title:string;
 active:number;
 delivered:number;
 inactive:number;
 total:number
  
}



@Component({
  selector: 'app-order-history-table',
  templateUrl: './order-history-table.component.html',
  styleUrls: ['./order-history-table.component.css']
})
export class OrderHistoryTableComponent implements OnInit{

  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatPaginator) searchPaginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;
  @ViewChild(MatTable) table!: MatTable<any>;
  @ViewChild('select') select!: MatSelect;

  retailerName:string="";
  filteredOptions!: Observable<string[]>;
  retailerList=[]
  spinner=true
  filteredData:Order[]=[];
  searchID=""
  statusString="ALL"
  orderList!:OrderList
  tableData:Order[]=[]
  startDate!:Date | null;
  endDate!:Date | null;
  searchByID=false;
  dataSource:any;
  disabled=false
  orderId="";
  selection = new SelectionModel<Order>(true, []);
  statuses=['ALL', 'Submitted', 'Accepted','Cancelled', 'Rejected', 'Shipped', 'Delivered','Pending'] 
  commandForm!: FormGroup;
  isError: boolean=false;
  ifExport: boolean=false;
  totalOrders: any;
  page: any;
  searchDataSource:any;
  isSearch:boolean=false;
  totalSearchOrders: any;
  reportSummary: SearchResultSummary[] = [];
  displayedColumns = ['select','orderId', 'buyerName', 'buyerGroup','submittedUserName','submittedDate', 'estimatedDate','amount','status','financialStatus','location','actions']
  activeOrders: number=0;
  activeOrdersTotal:number=0;
  deliveredOrdersTotal: number=0;
  deliverdOrders:number=0;
  inactiveOrdersTotal: number=0;
  inactiveOrders: number=0;
  displayedColumnsforReport: any;
  dataSourceforReport:any;
  showReportSummary: boolean=false;
  orderIdArray: number[]=[];
  selectedBuyerGroup: any = [];
  buyerGroup: any;
  disabledFields: boolean = false;
  disabledOrderIdFields: boolean = false;
  subscription!:Subscription

  allSelected=false;
  RetailersList: any;
  orderStatus: any;

  constructor(private ordersService:OrderService,private reportService:ReportService,private cdr:ChangeDetectorRef,private router:Router,private dialog:MatDialog,private orderByIdService:OrderByIdService,public date:DatePipe,private changeDetectorRefs: ChangeDetectorRef,private orderListService:OrderListService, private dataService:DataService, private processOrderService:ProcessOrderService,private searchOrdersService:SearchOrdersService, private toastr:ToastrService) 
  {

    this.commandForm = new FormGroup({ 
      startDate: new FormControl(),
      endDate: new FormControl(),
      orderId: new FormControl(),
      myControl:new FormControl(),
      status: new FormControl(),
      buyerGroup: new FormControl()
  })
   
    console.log(date.transform(Date.now()));
    var datenow=new Date(Date.now())
    var date14DaysAgo=new Date(Date.now()).setDate(datenow.getDate()-14)
    console.log(date.transform(datenow,'dd/MM/yyyy')+'hihi');
    console.log(date.transform(date14DaysAgo,'dd/MM/yyyy')+'huhu');
    
  }
 
  ngOnInit(): void{
    const retailers=JSON.parse(sessionStorage.getItem('retailers')!)
    this.fetch_retailer(0,0,'')
    this.fetch_BuyerGroups('')
    this.ordersService.getOrders("history","25","0")
    .subscribe((data: { order: unknown[] | undefined; totalOrderCount: any; })=>{
      if(data)
      {
        this.dataSource=new MatTableDataSource(data.order);
        this.cdr.detectChanges();
        this.dataSource.paginator=this.paginator;
        this.totalOrders = data.totalOrderCount
        this.spinner=false
      }
      else
      {
      this.toastr.error("ERROR","Couldn't fetch new orders")
      this.spinner=false
      }
    },(error:HttpErrorResponse)=>{
      console.log("errorororororor");
      this.spinner=false
      if (error instanceof HttpErrorResponse) {
        if (error.error instanceof ErrorEvent) {
          console.error("Error Event");
        } else {
          console.log(`error status : ${error.status} ${error.statusText}`);
          switch (error.status) {
            case 401:      //login
              this.router.navigateByUrl("/login");
              console.log(`redirect to login`);
              this.toastr.error("Session Expired, please login again")
              break;
            
          }
        }
      }
    })

    this.retailerList = retailers.map((datum: { retailerName: any; }) => datum.retailerName);
  

  this.filteredOptions = this.commandForm.controls.myControl.valueChanges
  .pipe(
    startWith(''),
    map(value => this._filter(value))
  );
  }

  private _filter(value: string): string[] {
 
    const filterValue = value?.toLowerCase();
  
    return this.retailerList.filter((retailer:any) => retailer.toLowerCase().includes(filterValue));
  }

  // fetch_retailer(limit:number,offset:number, groupId:any){
  //   this.ordersService.getRetailersList(limit,offset,groupId).subscribe({
  //     next: (response) => {
  //       this.dataService.sharedRetailerList=response.retailers
            
  //           this.RetailersList=response.retailers
  //           console.log( this.RetailersList.length)
  //           this.retailerList = this.RetailersList.map((datum: { retailerName: any; }) => datum.retailerName);
  //           this.filteredOptions = this.commandForm.controls.myControl.valueChanges
  //           .pipe(
  //             startWith(''),
  //             map(value => this._filter(value))
  //           );
  //     }
  //   })
  // }

  async fetch_retailer(limit: number, offset: number, groupId: any) {
    // Cancel the previous subscription if any
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  
    this.subscription = this.ordersService.getRetailersList(limit, offset, groupId).subscribe({
      next: (response) => {
        this.dataService.sharedRetailerList = response.retailers;
        this.RetailersList = response.retailers;
        this.retailerList = this.RetailersList.map((datum: { retailerName: any }) => datum.retailerName);
        this.filteredOptions = this.commandForm.controls.myControl.valueChanges.pipe(
          startWith(''),
          map((value) => this._filter(value))
        );
      },
    });
  }
  
  fetch_BuyerGroups(retailer: string) {
    this.ordersService.getBuyerGroups(retailer).subscribe({
      next: (response) => {
        if (response.status === 'SUCCESS') {
            this.buyerGroup = response.data
        }
      },
    })
  }
 /*  ngAfterViewInit(): void {
    this.table.dataSource = this.dataSource;
    this.dataSource.sort=this.sort;
    this.dataSource.paginator=this.paginator;
  } */

  hasRoute(route:string){
    return this.router.url==route;
  }

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource?.data.length;
    return numSelected === numRows;
  }
  checkboxLabel(row?: Order): string {
    if (!row) {
      return `${this.isAllSelected() ? 'deselect' : 'select'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.orderId}`;
  }

  pageChanged(event:any){
    this.isSearch=false;  
    this.page = event;
    this.ordersService.getOrders("history",event.pageSize.toString(), (event.pageIndex * event.pageSize).toString())
    .subscribe((data: { order: unknown[] | undefined; totalOrderCount: any; })=>{
      if(data)
      {
        this.dataSource=new MatTableDataSource(data.order);
        this.cdr.detectChanges();
       /*  this.dataSource.paginator=this.paginator;
        this.totalOrders = data.totalOrderCount */
        this.spinner=false
      }
      else
      {
      this.toastr.error("ERROR","Couldn't fetch new orders")
      this.spinner=false
      }
      
    },(error:HttpErrorResponse)=>{
      console.log("errorororororor");
      this.spinner=false
      if (error instanceof HttpErrorResponse) {
        if (error.error instanceof ErrorEvent) {
          console.error("Error Event");
        } else {
          console.log(`error status : ${error.status} ${error.statusText}`);
          switch (error.status) {
            case 401:      //login
              this.router.navigateByUrl("/login");
              console.log(`redirect to login`);
              this.toastr.error("Session Expired, please login again")
              break;
            
          }
        }
      }
    })
}

pageChangedSearch(event:any){
  this.isSearch=true;
  this.page = event;
  this.searchOrdersService.searchOrder(this.date.transform(this.startDate,'dd/MM/yyyy')!,this.date.transform(this.endDate,'dd/MM/yyyy')!,this.statusString, this.retailerName,this.orderId,this.selectedBuyerGroup,"history",event.pageSize.toString(), (event.pageIndex * event.pageSize).toString()).subscribe(res=>{
    // this.searchOrdersService.searchOrder(params).subscribe(res=>{
      // this.isSearch=true;

  /* console.log(res);
 this.orderId="" */
  this.disabled =false;
  this.spinner=false
  this.searchDataSource=new MatTableDataSource(res.order)
  this.cdr.detectChanges();
  // this.totalSearchOrders = res.totalOrderCount;
  // this.searchDataSource.paginator=this.paginatorSearch;
  
 
},(error:any)=>{
  console.log("errorororororor");
  
 this.disabled=false;
    this.isSearch=false;
    if (error instanceof HttpErrorResponse) {
      if (error.error instanceof ErrorEvent) {
        console.error("Error Event");
      } else {
        console.log(`error status : ${error.status} ${error.statusText}`);
        switch (error.status) {
          case 401:      //login
            this.router.navigateByUrl("/login");
            console.log(`redirect to login`);
            this.toastr.error("Session Expired, please login again")
            break;
          
        }
      }
    }  
})
}

processOrder(){
  this.orderIdArray=[]
  console.log(';here')
  // this.isSearch=false;
  // this.reset();
  if(this.selection.selected.length)
  {

    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      maxWidth: "400px",
      data: {
          title: "Confirm Process Orders? This action cannot be undone.",
          btn1: "No",
          btn2: "Yes"
         }
    });
    dialogRef.afterClosed().subscribe((result: boolean) => {
      console.log('The dialog was closed');
      console.log(result);//returns undefined
     
    if(result === true)
    {
    
    this.disabled=true

    
     
    console.log(this.selection.selected);
    for(let x of this.selection.selected)
     { /* let action=""
      if(x.status.value==="Submitted")
      action="ACCEPT"
      else if(x.status.value==="Accepted")
      action="SHIP"
      else if(x.status.value==="Shipped")
      action="DELIVER" */
      this.orderIdArray.push(Number(x.orderId))
  }
      if(this.orderIdArray){

      var orderIdObj={
        orderIds:this.orderIdArray
      }
      this.processOrderService.bulkProcess(orderIdObj).subscribe(
        res=>{
        
          this.selection.clear();
          
          if(res.orderArray[0]?.isSuccess === true){ 
           
           this.toastr.success("SUCCESS",res.message)
            if(this.page) {
            this.ordersService.getOrders("history",this.page.pageSize.toString(), (this.page.pageIndex * this.page.pageSize).toString())
      .subscribe(data=>{
        if(data)
        {
          this.dataSource=new MatTableDataSource(data.order);
          this.cdr.detectChanges();
        
          this.disabled=false;
        }
        
      },(error:HttpErrorResponse)=>{
        console.log("errorororororor");
        this.disabled=false
        if (error instanceof HttpErrorResponse) {
          if (error.error instanceof ErrorEvent) {
            console.error("Error Event");
          } else {
            console.log(`error status : ${error.status} ${error.statusText}`);
            switch (error.status) {
              case 401:      //login
                this.router.navigateByUrl("/login");
                console.log(`redirect to login`);
                this.toastr.error("Session Expired, please login again")
                break;
              
            }
          }
        }
      })
            
          }
          else{
            this.ordersService.getOrders("history","25","0")
      .subscribe(data=>{
        if(data)
        {
          this.dataSource=new MatTableDataSource(data.order);
          this.cdr.detectChanges();
          
          this.disabled=false;
        }
        
      },(error:HttpErrorResponse)=>{
        console.log("errorororororor");
        this.disabled=false
        if (error instanceof HttpErrorResponse) {
          if (error.error instanceof ErrorEvent) {
            console.error("Error Event");
          } else {
            console.log(`error status : ${error.status} ${error.statusText}`);
            switch (error.status) {
              case 401:      //login
                this.router.navigateByUrl("/login");
                console.log(`redirect to login`);
                this.toastr.error("Session Expired, please login again")
                break;
              
            }
          }
        }
      })
          }
        }
        else
        {
        this.toastr.error(res.message)
        this.disabled=false;
        }
        })

    }
  }
  else{
    this.disabled=false;
    this.selection.clear();
    return;
  }
});
    }
  
}

  masterToggle() {
    if (this.isAllSelected()) {
      this.selection.clear();
      return;
    }
    this.selection.select(...this.dataSource.data);
  }
  isSomeSelected() {
    
    return this.selection.selected.length > 0;
  }

  
  processOrderById(row:any,orderId:string,action:string){
    this.isSearch=false;
    this.commandForm = new FormGroup({ 
      startDate: new FormControl(),
      endDate: new FormControl(),
      orderId: new FormControl(),
      status: new FormControl(),
      buyerGroup: new FormControl(),
      myControl:new FormControl()
  })
    var reason=""
    if(orderId && action){
      if(action === 'REJECT'){

        const dialogRef = this.dialog.open(RejectDialogComponent, {
          width: '400px',
        });
        
        dialogRef.afterClosed().subscribe(result => {
          if(result){
            reason = result;
            this.processOrderService.processOrder(orderId,action,reason).subscribe(
              res=>{
              
                if(res.acknowledgement.status === "SUCCESS"){
                  this.toastr.success("SUCCESS",res.acknowledgement.message)
                  if(this.page) {  
                  this.ordersService.getOrders("history",this.page.pageSize.toString(), (this.page.pageIndex * this.page.pageSize).toString())
            .subscribe((data: { order: unknown[] | undefined; totalOrderCount: any; })=>{
              if(data)
              {
                this.dataSource=new MatTableDataSource(data.order);
                this.cdr.detectChanges();
               /*  this.dataSource.paginator=this.paginator;
                this.totalOrders = data.totalOrderCount */
                this.spinner=false
              }
              
            },(error:HttpErrorResponse)=>{
              console.log("errorororororor");
              this.spinner=false
              if (error instanceof HttpErrorResponse) {
                if (error.error instanceof ErrorEvent) {
                  console.error("Error Event");
                } else {
                  console.log(`error status : ${error.status} ${error.statusText}`);
                  switch (error.status) {
                    case 401:      //login
                      this.router.navigateByUrl("/login");
                      console.log(`redirect to login`);
                      this.toastr.error("Session Expired, please login again")
                      break;
                    
                  }
                }
              }
            })
          }
          else{
            this.ordersService.getOrders("history","25","0")
            .subscribe((data: { order: unknown[] | undefined; totalOrderCount: any; })=>{
              if(data)
              {
                this.dataSource=new MatTableDataSource(data.order);
                this.cdr.detectChanges();
                /* this.dataSource.paginator=this.paginator;
                this.totalOrders = data.totalOrderCount */
                this.spinner=false
              }
              
            },(error:HttpErrorResponse)=>{
              console.log("errorororororor");
              this.spinner=false
              if (error instanceof HttpErrorResponse) {
                if (error.error instanceof ErrorEvent) {
                  console.error("Error Event");
                } else {
                  console.log(`error status : ${error.status} ${error.statusText}`);
                  switch (error.status) {
                    case 401:      //login
                      this.router.navigateByUrl("/login");
                      console.log(`redirect to login`);
                      this.toastr.error("Session Expired, please login again")
                      break;
                    
                  }
                }
              }
            })
      
          }
                  
                }
                else
                {
                  this.spinner =false;
                this.toastr.error("ERROR",res.acknowledgement.message)
                }
              })
            
              
          
          }
          })
        
        
      }
      else{
        const dialogRef = this.dialog.open(ConfirmDialogComponent, {
          maxWidth: "400px",
          data: {
              title: "Confirm Process Order? This action cannot be undone.",
              btn1: "No",
              btn2: "Yes"
             }
        });
        dialogRef.afterClosed().subscribe((result: boolean) => {
          console.log('The dialog was closed');
          console.log(result);//returns undefined
         
        if(result === true)
        {
      this.processOrderService.processOrder(orderId,action,reason).subscribe(
        res=>{
        
          if(res.acknowledgement.status === "SUCCESS"){
            this.toastr.success("SUCCESS",res.acknowledgement.message)
            if(this.page) {  
            this.ordersService.getOrders("history",this.page.pageSize.toString(), (this.page.pageIndex * this.page.pageSize).toString())
      .subscribe((data: { order: unknown[] | undefined; totalOrderCount: any; })=>{
        if(data)
        {
          this.dataSource=new MatTableDataSource(data.order);
          this.cdr.detectChanges();
        /*   this.dataSource.paginator=this.paginator;
          this.totalOrders = data.totalOrderCount */
          this.spinner=false
        }
        
      },(error:HttpErrorResponse)=>{
        console.log("errorororororor");
        this.spinner=false
        if (error instanceof HttpErrorResponse) {
          if (error.error instanceof ErrorEvent) {
            console.error("Error Event");
          } else {
            console.log(`error status : ${error.status} ${error.statusText}`);
            switch (error.status) {
              case 401:      //login
                this.router.navigateByUrl("/login");
                console.log(`redirect to login`);
                this.toastr.error("Session Expired, please login again")
                break;
              
            }
          }
        }
      })
    }
    else{
      this.ordersService.getOrders("history","25","0")
      .subscribe((data: { order: unknown[] | undefined; totalOrderCount: any; })=>{
        if(data)
        {
          this.dataSource=new MatTableDataSource(data.order);
          this.cdr.detectChanges();
         /*  this.dataSource.paginator=this.paginator;
          this.totalOrders = data.totalOrderCount */
          this.spinner=false
        }
        
      },(error:HttpErrorResponse)=>{
        console.log("errorororororor");
        this.spinner=false
        if (error instanceof HttpErrorResponse) {
          if (error.error instanceof ErrorEvent) {
            console.error("Error Event");
          } else {
            console.log(`error status : ${error.status} ${error.statusText}`);
            switch (error.status) {
              case 401:      //login
                this.router.navigateByUrl("/login");
                console.log(`redirect to login`);
                this.toastr.error("Session Expired, please login again")
                break;
              
            }
          }
        }
      })

    }
            
          }
          else{
          this.spinner=false
          this.toastr.error("ERROR",res.acknowledgement.message) 
          }
        })
      }
      else{
        this.spinner=false
        return 
      }
    })


    }  
   
    }        

  }
  statusColour(status:any){
  if(status === 'Submitted')
      return '#5094e3'
  else if(status === 'Accepted')
    return '#D27D2D'
  else if(status === 'Shipped')
    return '#e563a2'
  else if((status ==='Rejected')||(status === 'Cancelled'))
    return '#ca3433'
  else if(status==='Pending')
  return '#FDDA0D'
  else  if(status==='Delivered')
    return '#006400'
  else
   return '#000'
    
  }
    
  

  openDeliverySlip(row:any){
    const dialogRef = this.dialog.open(DeliverySlipComponent, {
      width: '900px',height:'600px',
      data: row.orderId
    });
  }
  myFilter1 = (d: Date | null): boolean => {
    const time = (d || new Date()).getTime();
    const today=new Date(Date.now()) 
    return this.endDate?((time<this.endDate.getTime())&&(time<today.getTime())):(time<today.getTime());
  }

  myFilter2 = (d: Date | null): boolean => {
    const time = (d || new Date()).getTime();
    const today=new Date(Date.now()) 
    return this.startDate?((time>this.startDate.getTime())&&(time<today.getTime())):(time<today.getTime());
  }

  disableOrederId(event:any) {
    if (event.target.value == ''){
      this.disabledOrderIdFields = false;
      this.fetch_BuyerGroups('')
    }
    else {
      this.disabledOrderIdFields = true;
    }
  }
  
  buyerSelect(retailer: any) {
    this.fetch_BuyerGroups(retailer)
  }

  saveSearchId=(searchID:any)=>{
    if (searchID.target.value == ''){
      this.disabledFields = false;
      this.searchID = ''
    }
    else {
      this.disabledFields = true;
      this.clearFormdata();
      this.searchID=searchID.target.value
    }
  }

  filterSearch(){
    
    this.spinner=true
    if(this.searchID)
    {
      // this.filteredData=this.tableData
      // .filter(x=>x.orderId.includes(searchID)&&x.status.value.includes(this.statusString))
      // let y=new Date(this.filteredData[0].deliveryTime)
      // console.log(y.getTime());
      
      this.searchOrdersService.getOrderHistoryWId(this.searchID).subscribe(res=>{
        console.log(res);
      sessionStorage.setItem('history',JSON.stringify(res.order))
        this.dataSource=new MatTableDataSource<Order>(res.order)
        this.dataSource.sort=this.sort;
        this.dataSource.paginator=this.paginator;
        this.spinner=false
      },(err:any)=>{
        console.log("errorororororor");
        

          this.spinner=false
          
      })
    }
    else if((this.statusString!==''&&this.startDate&&this.endDate))
    {
      console.log('2')
      // this.filteredData=this.tableData
      // .filter(x=>{
      //   let xdate=new Date(x.deliveryTime);
      //   return x.orderId.includes(searchID)&&x.status.value.includes(this.statusString)&&xdate.getTime()>=this.startDate.getTime()})
      this.searchOrdersService.getOrderHistoryWDateWstatus(this.date.transform(this.startDate,'dd/MM/yyyy')!,this.date.transform(this.endDate,'dd/MM/yyyy')!,this.statusString,'').subscribe(
        res=>{
      sessionStorage.setItem('history',JSON.stringify(res.order))
          console.log(res);
        this.dataSource=new MatTableDataSource<Order>(res.order)
        this.dataSource.sort=this.sort;
        this.dataSource.paginator=this.paginator;
        this.spinner=false
        },(error:any)=>{
          console.log("errorororororor");
         
          this.spinner=false
          if (error instanceof HttpErrorResponse) {
            if (error.error instanceof ErrorEvent) {
              console.error("Error Event");
            } else {
              console.log(`error status : ${error.status} ${error.statusText}`);
              switch (error.status) {
                case 401:      //login
                  this.router.navigateByUrl("/login");
                  console.log(`redirect to login`);
                  this.toastr.error("Session Expired, please login again")
                  break;
                
              }
            }
          }
        }
      )
    }
    // else if((!this.startDate))
    // {
      //   this.filteredData=this.tableData
      //   .filter(x=>{
        //     let xdate=new Date(x.deliveryTime);
        //     return x.orderId.includes(searchID)&&x.status.value.includes(this.statusString)&&xdate.getTime()<=this.endDate.getTime()})
        // }
        else if(this.startDate&&this.endDate)
        {
          console.log('3')
          this.searchOrdersService.getOrderHistoryWDate(this.date.transform(this.startDate,'dd/MM/yyyy')!,this.date.transform(this.endDate,'dd/MM/yyyy')!,'','').subscribe(res=>{
            console.log(res);
            sessionStorage.setItem('history',JSON.stringify(res.order))
            this.dataSource=new MatTableDataSource<Order>(res.order)
            this.dataSource.sort=this.sort;
            this.dataSource.paginator=this.paginator;
            this.spinner=false            
          },(error:any)=>{
            console.log("errorororororor");
            
            this.spinner=false   
            if (error instanceof HttpErrorResponse) {
              if (error.error instanceof ErrorEvent) {
                console.error("Error Event");
              } else {
                console.log(`error status : ${error.status} ${error.statusText}`);
                switch (error.status) {
                  case 401:      //login
                    this.router.navigateByUrl("/login");
                    console.log(`redirect to login`);
                    this.toastr.error("Session Expired, please login again")
                    break;
                  
                }
              }
            }         
            
          })
        }
        else
        {
          console.log('4')
        //   console.log(this.date.transform(Date.now()));
        // var datenow=new Date(Date.now())
        // var date14DaysAgo=new Date(Date.now()).setDate(datenow.getDate()-14)
        // console.log(this.date.transform(datenow,'dd/MM/yyyy')+'hihi');
        // console.log(this.date.transform(date14DaysAgo,'dd/MM/yyyy')+'huhu');
        
        //TODO:Get the orderhistory API to Work and finish it
    
        this.searchOrdersService.getOrderHistory().subscribe(res=>{
          // this.searchOrdersService.getOrderHistoryWDate(this.date.transform(date14DaysAgo,'dd/MM/yyyy')!,this.date.transform(datenow,'dd/MM/yyyy')!,'','').subscribe(res=>{
          console.log(res);
          sessionStorage.setItem('history',JSON.stringify(res.order))
          this.dataSource=new MatTableDataSource<Order>(res.order)
          this.dataSource.sort=this.sort;
          this.dataSource.paginator=this.paginator;
          this.spinner=false            
        },(error:any)=>{
          console.log("errorororororor");
          
          this.spinner=false          
          if (error instanceof HttpErrorResponse) {
            if (error.error instanceof ErrorEvent) {
              console.error("Error Event");
            } else {
              console.log(`error status : ${error.status} ${error.statusText}`);
              switch (error.status) {
                case 401:      //login
                  this.router.navigateByUrl("/login");
                  console.log(`redirect to login`);
                  this.toastr.error("Session Expired, please login again")
                  break;
                
              }
            }
          }  
        })
        }
  }

  buyer(event:any){

    this.retailerName= event.value;
  }

  
  search(){
    this.isSearch =true;
    this.disabled =true;
    if (this.orderId) {
      this.startDate = null,
      this.endDate = null,
      this.statusString = '', 
      this.retailerName = '',
      this.selectedBuyerGroup = ''
    }
    console.log('order status', this.statusString)
    this.activeOrders = this.activeOrdersTotal = this.deliverdOrders = this.deliveredOrdersTotal = this.inactiveOrders = this.inactiveOrdersTotal=0;
    this.searchOrdersService.searchOrder(this.date.transform(this.startDate,'dd/MM/yyyy')!,this.date.transform(this.endDate,'dd/MM/yyyy')!,this.statusString,this.retailerName,this.searchID,this.selectedBuyerGroup,"history","25","0").subscribe(res=>{
    //  this.searchID="";
    //  if(this.startDate)
    //   {
      // for (var i=0;i<res.order.length;i++)
      // {
        this.orderStatus = this.mapStatus(this.statusString)
        console.log('order status code ', this.orderStatus)

        this.ordersSummeryReport(this.searchID,this.retailerName,this.date.transform(this.startDate,'dd/MM/yyyy')!,this.date.transform(this.endDate,'dd/MM/yyyy')!,this.orderStatus,this.selectedBuyerGroup)

      //   if(res.order[i].status.value === 'Accepted' || res.order[i].status.value === 'Submitted'|| res.order[i].status.value === 'Pending'||res.order[i].status.value === 'Shipped' )
      //   {
      //   this.activeOrders++;
      //   this.activeOrdersTotal=this.activeOrdersTotal+Number(res.order[i].grandTotal);
      //   }
      //   else if(res.order[i].status.value === 'Delivered'){
      //     this.deliverdOrders++;
      //     this.deliveredOrdersTotal=this.deliveredOrdersTotal+Number(res.order[i].grandTotal);

      //   }
      //   else if(res.order[i].status.value === 'Rejected' || res.order[i].status.value === 'Cancelled'){
      //     this.inactiveOrders++;
      //     this.inactiveOrdersTotal=this.inactiveOrdersTotal+Number(res.order[i].grandTotal);
      //   }
      // }

     
      // this.reportSummary=[
      //   {title:'Count',active:this.activeOrders,delivered:this.deliverdOrders,inactive:this.inactiveOrders,total:res.order.length},
      //   {title:'Amount',active:+this.activeOrdersTotal.toFixed(2),delivered:+this.deliveredOrdersTotal.toFixed(2),inactive:+this.inactiveOrdersTotal.toFixed(2),total:+(this.activeOrdersTotal +this.deliveredOrdersTotal+ this.inactiveOrdersTotal).toFixed(2)}
      // ]


      // if(this.reportSummary){
      //   this.displayedColumnsforReport= ['title','active', 'delivered', 'inactive', 'total'];
        
      //   this.dataSourceforReport=new MatTableDataSource<SearchResultSummary>(this.reportSummary);
      //   this.showReportSummary=true;
      //   this.cdr.detectChanges();
      // }
    // } 
    this.disabled =false;
      this.searchDataSource=new MatTableDataSource<Order>(res.order)
      this.cdr.detectChanges();
      // this.searchDataSource.paginator=this.searchPaginator
      this.totalSearchOrders = res.totalOrderCount;
     
    },(error:any)=>{
      console.log("errorororororor");
      
      this.disabled =false;
        
      this.isSearch =false;
      if (error instanceof HttpErrorResponse) {
        if (error.error instanceof ErrorEvent) {
          console.error("Error Event");
        } else {
          console.log(`error status : ${error.status} ${error.statusText}`);
          switch (error.status) {
            case 401:      //login
              this.router.navigateByUrl("/login");
              console.log(`redirect to login`);
              this.toastr.error("Session Expired, please login again")
              break;
            
          }
        }
      }
    })
    
  }

  mapStatus(statusString: string): string {
    switch (statusString) {
      case "Accepted":
        return "A";
      case "Submitted":
        return "I";
      case "Pending":
        return "PD";  
      case "Shipped":
        return "S";
      case "Delivered":
        return "DD";
      case "Cancelled":
        return "C";
      case "Rejected":
        return "R";
      case "":
        return "ALL";  
      default:
        return ""; 
    }
  }

  ordersSummeryReport(orderId: string,retailer: string, startDate: string, endDate: string,status : string, groupIds: any){
    this.searchOrdersService.summeryReport(orderId,retailer,startDate,endDate,status,groupIds).subscribe({
      next: (response) => {
        if (response.status === "SUCCESS") {
          this.showReportSummary = true;
          this.reportSummary=[
              {title:'Count',active:response.data.activeOrderCount,delivered:response.data.deliveredOrderCount,inactive:response.data.inactiveOrderCount,total:response.data.totalCount},
              {title:'Amount',active:response.data.activeOrderAmount !== null ? response.data.activeOrderAmount : 0,delivered:response.data.deliveredOrderAmount !== null ? response.data.deliveredOrderAmount : 0,inactive:response.data.inactiveOrderAmount !== null ? response.data.inactiveOrderAmount : 0,total:response.data.total !== null ? response.data.total : 0}
            ]
            if(this.reportSummary){
                this.displayedColumnsforReport= ['title','active', 'delivered', 'inactive', 'total'];
                console.log('orders summery report', this.reportSummary)
                this.dataSourceforReport=new MatTableDataSource<SearchResultSummary>(this.reportSummary);
                this.showReportSummary=true;
                this.cdr.detectChanges();
              }
        }
        else {
          console.log("Something went wrong!")
        }
      }
    })
  }

  downloadOrderReport(){
   
    this.isError=false;
    this.ifExport=true
    
        this.reportService.downloadOrderReport("history",this.searchID,this.retailerName,this.date.transform(this.startDate,'dd/MM/yyyy')!,this.date.transform(this.endDate,'dd/MM/yyyy')!,this.statusString, this.selectedBuyerGroup).subscribe(
          (          res: { fileName: string; fileUrl: string; message: string;}) => {
            this.ifExport=false
            if(res.fileName)
           { 
             //window.open(res.fileUrl)
            // window.location.href =res.fileUrl;
            //var downloadURL = window.URL.createObjectURL(res.fileName);
            
            const link = document.createElement('a');
            link.setAttribute('target', '_self');
            link.setAttribute('href', res.fileUrl);
            link.setAttribute('download',res.fileName);
            document.body.appendChild(link);
            link.click();
            link.remove(); 
            
           }
            else
            this.toastr.error(res.message)
            
          },
          (err: HttpErrorResponse) => {
            if (err) {
              this.ifExport=false
              this.toastr.error(err.error.message, "ERROR")
            }
  
  
          }
        );
      

    
  }
  
clearFormdata() {
  this.commandForm = new FormGroup({ 
    startDate: new FormControl(''),
    endDate: new FormControl(''),
    // orderId: new FormControl(),
    myControl: new FormControl(''),
    status: new FormControl(''),
    buyerGroup: new FormControl('')
  })
}
  logStartDate(event:any)
  {
    if (event.value == ''){
      this.disabledOrderIdFields = false;
    }
    else {
    this.disabledOrderIdFields = true;
    this.startDate=event.value;
    //this.filterSearch(this.searchID)
    }    // this.filterSearch(this.searchID)
  }
  logEndDate(event:any)
  {
    if (event.value == ''){
      this.disabledOrderIdFields = false;
    }
    else {
    this.disabledOrderIdFields = true;
    this.endDate=event.value
    //this.filterSearch(this.searchID)
    }    // this.filterSearch(this.searchID)
  }
  clearStartDate(event: any) {
    if (event.target.value == '') {
      this.startDate = null
    }
  }
  clearEndDate(event: any) {
    if (event.target.value == '') {
      this.endDate = null
    }
  }
  saveID(event:any){
    if (event.target.value == ''){
      this.disabledFields = false;
      this.orderId = ''
    }
    else {
      this.disabledFields = true;
      this.clearFormdata();
      this.orderId=event.target.value;
    }
    // this.filterSearch(event.target.value);
  }
  buyerGroups(event:any){
    if (event.value == ''){
      this.disabledOrderIdFields = false;
      this.selectedBuyerGroup = ''
    }
    else {
      this.disabledOrderIdFields = true;
      this.selectedBuyerGroup = event.value.map((id: any) => id );  
    }  }
    selectAllBuyerGroup() {
      if (this.allSelected) {
        this.select.options.forEach((item: MatOption) => item.select());
        this.disabledOrderIdFields = true;
        this.selectedBuyerGroup = this.buyerGroup.map((group: any) => group.id);
        console.log('ALL selectedBuyerGroup', this.selectedBuyerGroup)
      } else {
        this.select.options.forEach((item: MatOption) => item.deselect());
        this.selectedBuyerGroup = ''
      }
    }
     selectBuyerGroup() {
      let newStatus = true;
      this.select.options.forEach((item: MatOption) => {
        if (!item.selected) {
          newStatus = false;
          this.disabledOrderIdFields = true;
          const buyerId = this.commandForm.controls.buyerGroup.value
        this.selectedBuyerGroup = buyerId.map((id: any) => id );  
        console.log('selectedBuyerGroup', this.selectedBuyerGroup)
        }
        else {
          const buyerId = this.commandForm.controls.buyerGroup.value
          this.selectedBuyerGroup = buyerId.map((id: any) => id ) || '';  
          console.log('selectedBuyerGroup', this.selectedBuyerGroup)      
        }
      });
      this.allSelected = newStatus;
      this.fetch_retailer(0,0,this.selectedBuyerGroup)
    }
  status(event:any){
    if (event.value == ''){
      this.disabledOrderIdFields = false;
      this.statusString = ''
    }
    else {
    this.disabledOrderIdFields = true;
    this.statusString=event.value==="ALL"?"":event.value;
    }
    // this.filterSearch(this.searchID);
  }
  totalAmount(order:Order)
  {
    return Number(order.totalAmount.valueWithoutTax)+Number(order.netTaxAmount)
  }

  toDetails(id:string,index:number){
      this.orderByIdService.getOrderlist(id)
      .subscribe(
        data => {
        if(data.acknowledgement?.status === "SUCCESS")
        {
          if(data.order[0])
          {
      const dialogRef = this.dialog.open(OrderDetailsComponent, {
        width:'70%',
        data: {order:data.order[0],from:this.router.url,index:index}
      } )
      dialogRef.afterClosed().subscribe(result => {
     if(this.isSearch === false)  
     { 
       this.reset();
       this.selection.clear();
        if(this.page) {  
          this.ordersService.getOrders("history",this.page.pageSize.toString(), (this.page.pageIndex * this.page.pageSize).toString())
    .subscribe((data: { order: unknown[] | undefined; totalOrderCount: any; })=>{
      if(data)
      {
        this.dataSource=new MatTableDataSource(data.order);
        this.cdr.detectChanges();
       /*  this.dataSource.paginator=this.paginator;
        this.totalOrders = data.totalOrderCount */
        this.spinner=false
      }
      
    },(error:HttpErrorResponse)=>{
      console.log("errorororororor");
      this.spinner=false
      if (error instanceof HttpErrorResponse) {
        if (error.error instanceof ErrorEvent) {
          console.error("Error Event");
        } else {
          console.log(`error status : ${error.status} ${error.statusText}`);
          switch (error.status) {
            case 401:      //login
              this.router.navigateByUrl("/login");
              console.log(`redirect to login`);
              this.toastr.error("Session Expired, please login again")
              break;
            
          }
        }
      }
    })
  }
  else{
    this.ordersService.getOrders("history","25","0")
    .subscribe((data: { order: unknown[] | undefined; totalOrderCount: any; })=>{
      if(data)
      {
        this.dataSource=new MatTableDataSource(data.order);
        this.cdr.detectChanges();
        /* this.dataSource.paginator=this.paginator;
        this.totalOrders = data.totalOrderCount */
        this.spinner=false
      }
      
    },(error:HttpErrorResponse)=>{
      console.log("errorororororor");
      this.spinner=false
      if (error instanceof HttpErrorResponse) {
        if (error.error instanceof ErrorEvent) {
          console.error("Error Event");
        } else {
          console.log(`error status : ${error.status} ${error.statusText}`);
          switch (error.status) {
            case 401:      //login
              this.router.navigateByUrl("/login");
              console.log(`redirect to login`);
              this.toastr.error("Session Expired, please login again")
              break;
            
          }
        }
      }
    })
  
  }
}
else if(this.isSearch === true){
  this.search();
}
        })

         }
         }
       else{
        this.toastr.error("ERROR",data.acknowledgement?.message)
       }  
      },(err:HttpErrorResponse)=>{
        console.log(err);
        this.toastr.error("ERROR",'Unfortunately could not get the details of order')     
      }
      );
    
    } 

  negate=(choice:boolean)=>{
    console.log(choice);
    return !choice
    
  }

  radioChange($event: MatRadioChange) {
    console.log($event.source.name, $event.value);

    if ($event.value === "false") {
        console.log("working false");
        this.disabled=false
        
    }
    else if($event.value === "true"){
      console.log("working true");
      this.disabled=true
      
    }
}
reset(){
  this.resetForm()
  this.disabledFields = false;
  this.disabledOrderIdFields = false;
  this.isSearch = false;
  this.showReportSummary = false;
this.selection.clear();
  this.commandForm.controls.myControl.reset()
  this.retailerName="";
  this.orderId = ''
  this.searchID = ''
  this.statusString = ''
  this.selectedBuyerGroup = ''
  this.endDate = null
  this.startDate = null
  this.commandForm.reset();
  this.fetch_BuyerGroups('')
  this.fetch_retailer(0,0,'')
  this.filteredOptions = this.commandForm.controls.myControl.valueChanges
    .pipe(
      startWith(''),
      map(value => this._filter(value))
    );
  
}
resetForm() {
  this.commandForm = new FormGroup({ 
    startDate: new FormControl(),
    endDate: new FormControl(),
    orderId: new FormControl(),
    myControl :new FormControl(),
    status: new FormControl(),
    buyerGroup: new FormControl()

}) 
}
masterToggle1(){
  if (this.isAllSelected1()) {
    this.selection.clear();
    return;
  }
  this.selection.select(...this.searchDataSource.data);
}

isAllSelected1() {
  const numSelected = this.selection.selected.length;
  const numRows = this.searchDataSource?.data.length;
  return numSelected === numRows;
}

formatDate(date:string){
  var time=date.replace("T", " ")
  return moment(time).format('DD MMM YYYY');
}
toEditOrder(order: { orderId: any; items: any; }){
  // let url=this.router.url
  // this.router.navigate(["/edit-order"],{queryParams:{orderId:this.order.orderId,from:url}})
  const dialogRef = this.dialog.open(EditOrderTableComponent, {
   width:"600px",
    data: {orderId:order.orderId,items:order.items}
  });
}

toProForma(row:any){
  const dialogRef = this.dialog.open(ProFormaPageComponent, {
    width:'900px',
    data: row.orderId
  } )
}

openDialog(row:any): void {
  const dialogRef = this.dialog.open(AddPaymentDialogComponent, {
    width: '600px',
    data: {orderId:row.orderId,retailerId:row.retailerShop.retailerShopId,shopId:row.warehouse.warehouseId,accountBalance:row.accountBalance}
  });
  
  dialogRef.afterClosed().subscribe(result => {
    if(result){

      
      this.orderByIdService.getOrderlist(row.orderId)
      .subscribe(
        data=>{
          console.log(data.order[0].orderId);
          /* this.toastr.success('Successfully added payment','Success') */
        }

        )
      }
      else
        console.log('closed');
        
    
    });
  }

 openDialogDiscount(row:any): void {
  const dialogRefDiscount = this.dialog.open(AddDiscountDialogComponent, {
    width: '900px',
    data: {id:row.orderId,no:row.orderDisplayName}
  });
  dialogRefDiscount.afterClosed().subscribe(result => {
    if(result)

     {
        
          
     }  
       1

})

}

openUpdatePrice(row:any): void {
  const dialogRefLivePrice = this.dialog.open(UpdateLivePriceComponent, {
    width: '800px',
    data: {orderId:row.orderId,orderDisplayName:row.orderDisplayName}
  });
  dialogRefLivePrice.afterClosed().subscribe(result => {
    if(result)

     {}
    
         

})

}


}
