import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { AddDiscountDialogComponent } from 'src/app/components/add-discount-dialog/add-discount-dialog.component';
import { Address } from 'src/app/Models/loginResponse';
import { Order } from 'src/app/Models/orderList';
import { DataService } from 'src/app/Service/data.service';
import { OrderByIdService } from 'src/app/Service/order-by-id.service';

import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';




@Component({
  selector: 'app-pro-forma-page',
  templateUrl: './pro-forma-page.component.html',
  styleUrls: ['./pro-forma-page.component.css']
})
export class ProFormaPageComponent implements OnInit {

  order!:Order
  backRoute!:string
  wareHouseName!:string
  companyName!:string
  logo!:string
  address!:Address

  constructor(private orderByIdService:OrderByIdService,private route:ActivatedRoute,
    public dialogRef: MatDialogRef<AddDiscountDialogComponent>,private router: Router,@Inject(MAT_DIALOG_DATA) public data:string) {
    
   
    this.wareHouseName=sessionStorage.getItem('CompanyName')!
    this.companyName=sessionStorage.getItem('OriginalCompany')!
    this.logo=sessionStorage.getItem('Logo')!
    this.address=JSON.parse(sessionStorage.getItem('Address')!)
    console.log(this.address);
    
   }

  ngOnInit(): void {
    if(typeof this.data === 'string')
    this.orderByIdService.getOrderlist(this.data)
    .subscribe(
      res => {
      if(res.acknowledgement?.status === "SUCCESS")
      {
        if(res.order[0])
        this.order = res.order[0]
       }
      
    }
    ); 
  }
  


  toDetails(id:string){
   
  }

  toNumber=(number:any)=>Number(number)
  getBack(){
    this.router.navigateByUrl(this.backRoute)
  }
  close(){
    this.dialogRef.close(false);
  
  }

  public openPDF(): void {

    if(typeof this.data === 'string')
    this.orderByIdService.downloadInvoice(this.data,'Invoice')
    .subscribe(
      res => {
            
          var file = new Blob([res], { type: 'application/pdf' })
          var fileURL = URL.createObjectURL(file);
         // window.open(fileURL); 
          var a         = document.createElement('a');
          a.href        = fileURL; 
          a.target      = '_blank';
          a.download    = 'invoice.pdf';
          document.body.appendChild(a);
          a.click();
      
    }
    ); 
   

  }


  public openPDF1(): void {
     let DATA: any = document.getElementById('printInvoice');
      html2canvas(DATA).then((canvas) => {
      let fileWidth = 208;
      let fileHeight = (canvas.height * fileWidth) / canvas.width;
      const FILEURI = canvas.toDataURL('image/png');
      let PDF = new jsPDF('p', 'mm', 'a4');
      let position = 0;
      PDF.addImage(FILEURI, 'PNG', 0, position, fileWidth, fileHeight);
      PDF.addPage();
      PDF.save('Invoice.pdf');
    }); 



    
    }

    public openPDF2(): void {
     
    const data:any = document.getElementById('printInvoice');
  html2canvas(data).then((canvas:any) => {
  const imgWidth = 210;
  const pageHeight = 295;
  const imgHeight = (canvas.height * imgWidth) / canvas.width;
  let heightLeft = imgHeight;
  let position = 0;
  heightLeft -= pageHeight;
  const doc = new jsPDF('p', 'mm','a4');
  doc.addImage(canvas, 'PNG', 0, position, imgWidth,imgHeight, '', 'FAST');
  doc.setLineWidth(5);
      doc.setDrawColor(255, 255, 255);
      doc.rect(0, 0, 210, 295);
  while (heightLeft >= 0) {
    position = heightLeft - imgHeight;
    doc.addPage();
    doc.addImage(canvas, 'PNG', 0, position, imgWidth,imgHeight, '', 'FAST');
    doc.setLineWidth(5);
    doc.setDrawColor(255, 255, 255);
    doc.rect(0, 0, 210, 295);
    heightLeft -= pageHeight;
  }
  
  doc.save('Invoice.pdf');
});
    }

    Export2Word(filename = 'Invoice'){
      var HtmlHead = "<html xmlns:o='urn:schemas-microsoft-com:office:office' xmlns:w='urn:schemas-microsoft-com:office:word' xmlns='http://www.w3.org/TR/REC-html40'><head><meta charset='utf-8'><title>Export HTML To Doc</title></head><body>";
  
      var EndHtml = "</body></html>";
    
    var html:any = HtmlHead + document.getElementById('printInvoice')?.innerHTML +EndHtml;
  
      
    
      var blob = new Blob(['\ufeff', html], {
          type: 'application/msword'
      });
      
      // Specify link url
      var url = 'data:application/vnd.ms-word;charset=utf-8,' + encodeURIComponent(html);
      
      // Specify file name
      filename = filename?filename+'.doc':'document.doc';
      
      // Create download link element
      var downloadLink = document.createElement("a");
    
      document.body.appendChild(downloadLink);
      const nav = (window.navigator as any);
    if (nav.msSaveOrOpenBlob) {
      nav.msSaveOrOpenBlob(blob, filename);
    }
     else{
          // Create a link to the file
          downloadLink.href = url;
          
          // Setting the file name
          downloadLink.download = filename;
          
          //triggering the function
          downloadLink.click();
      }
      
      document.body.removeChild(downloadLink);
    }

}
