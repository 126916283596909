<div class=" border margin" >
    <div class="flex-outer border_bottom">
      <div>
        <mat-form-field appearance="legacy" class="col-lg-7">
          <mat-label>Search by PaymentId</mat-label>
          <input matInput #input >
          <!-- <mat-icon style="font-size: 1.5rem;" matSuffix>search</mat-icon> -->
        </mat-form-field>
        <button mat-raised-button color="primary" (click)="searchByPaymentId(input.value)"> Search </button>
      </div>
      <div>
  
        <!-- <mat-form-field appearance="legacy"  style="width: 100%; ">
          <mat-label>Buyer Name</mat-label>
          <mat-select (selectionChange)="buyer($event)">
            <mat-option value="all">All</mat-option>
            <mat-option *ngFor="let retailer of retailerList" [value]="retailer.retailerName">{{retailer.retailerName}}</mat-option>
          </mat-select>
        </mat-form-field> -->
      </div>
    <div style="justify-content:flex-start;display: flex;align-items: center;margin-bottom: 10px;">
      <button mat-flat-button color="primary"  (click)="refresh_details()" [disabled]="disabled" [class.spinner]='disabled'><mat-icon>refresh</mat-icon> Refresh</button>
    </div>
    <!-- <button mat-flat-button color="accent" ><mat-icon>add_shopping_cart</mat-icon> New Orders</button> -->
    <div  style="justify-content:flex-end;display: flex;margin-bottom: 10px;">
      <button mat-raised-button class="ml-2"  color="primary" (click)="downloadPaymentReport()"> Report <img src="../assets/icon/download.png"  style="height:24px;"> </button> &nbsp;&nbsp;
      <button mat-flat-button color="primary" (click)="openDialog()"><mat-icon>add_circle</mat-icon> Add Payment</button>
    </div>
    
   

      <!-- <div><button mat-flat-button color="primary" ><mat-icon>description</mat-icon> Download Order Report</button></div> -->
    </div>
    <div>
    <mat-paginator [length]="totalOrders" 
    [pageIndex]="0"
    [pageSize]="25"
    [pageSizeOptions]="[10,20,50]"
    showFirstLastButtons="true"
    (page)="pageChanged($event)"
    ></mat-paginator>
  </div>
    <div class="example-loading-shade">
        <div class="overlay" *ngIf="disabled">
        <mat-spinner *ngIf="disabled" class="absolute-center"></mat-spinner>
      </div>
      <table mat-table [dataSource]="dataSource | paginate: { itemsPerPage: 25, currentPage: page, totalItems:totalOrders}" style="width: 100%;">
    
        
        <!-- Progress Column -->
        <ng-container matColumnDef="buyer">
          <th mat-header-cell *matHeaderCellDef>Buyer </th>
          <td mat-cell *matCellDef="let row"> {{row.retailer.name}} </td>
        </ng-container>
        
        <!-- Name Column -->
        <ng-container matColumnDef="date">
          <th mat-header-cell *matHeaderCellDef>Date </th>
          <td mat-cell *matCellDef="let row"> {{row.paidDate}} </td>
        </ng-container>
        
        <!-- Fruit Column -->
        <!-- <ng-container matColumnDef="orderId">
          <th mat-header-cell *matHeaderCellDef> OrderId </th>
          <td mat-cell *matCellDef="let row"> {{row.orderId}} </td>
        </ng-container> -->
        
        <!-- Fruit Column -->
        <ng-container matColumnDef="paidAmount">
          <th mat-header-cell *matHeaderCellDef> Paid Amount </th>
          <td mat-cell *matCellDef="let row">{{row.paidAmount}} </td>
        </ng-container>
        
        <!-- Fruit Column -->
        <ng-container matColumnDef="paymentId">
          <th mat-header-cell *matHeaderCellDef> Payment ID </th>
          <td mat-cell *matCellDef="let row"> {{row.paymentId}} </td>
        </ng-container>
        <!-- Fruit Column -->
        <ng-container matColumnDef="orderId">
          <th mat-header-cell *matHeaderCellDef> Order ID </th>
          <td mat-cell *matCellDef="let row"> {{row.orderDisplayName || row.orderId}} </td>
        </ng-container>
        <!-- Fruit Column -->
        <ng-container matColumnDef="submittedUser">
          <th mat-header-cell *matHeaderCellDef> Submitted User</th>
          <td mat-cell *matCellDef="let row"> {{row.submittedUser.name}} </td>
        </ng-container>
        
        <!-- Fruit Column -->
        <ng-container matColumnDef="paymentMethod">
          <th mat-header-cell *matHeaderCellDef>Payment Method </th>
          <td mat-cell *matCellDef="let row"> {{row.paymentMode}} </td>
        </ng-container>
        
        <ng-container matColumnDef="referenceNumber">
          <th mat-header-cell *matHeaderCellDef>Reference No</th>
          <td mat-cell *matCellDef="let row"> {{row.referenceNumber}} </td>
        </ng-container>
        
        <!-- Fruit Column -->
        <ng-container matColumnDef="referenceNote">
          <th mat-header-cell *matHeaderCellDef>Payment Note </th>
          <td mat-cell *matCellDef="let row"> {{row.paymentNote==''?'':row.paymentNote.length>20?row.paymentNote.slice(0,19):row.paymentNote}} </td>
        </ng-container>
        
        
        
        <!-- ID Column -->
        <ng-container matColumnDef="action">
          <th mat-header-cell *matHeaderCellDef> Status </th>
          <td mat-cell *matCellDef="let row"><span  [ngStyle]="{'color':statusColour(row.status)}">{{row.status}}</span></td>
        </ng-container>
        
        <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true" style="background-color: #f6f6ff;"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        
        <!-- Row shown when there is no matching data. -->
        <tr class="mat-row" *matNoDataRow>
          <td class="mat-cell" colspan="100" align="center">No data matching the filter</td>
        </tr>
      </table>
    </div>
  
    </div>
      
  