import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpHeaders, HttpBackend,HttpParams } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import {ToastrService} from 'ngx-toastr'
import { environment } from 'src/environments/environment';
import { GetPriceResponse, UpdateLivePrice } from '../Models/updatePrice';
import { UpdateObj } from '../components/update-live-price/update-live-price.component';

@Injectable({
  providedIn: 'root'
})
export class UpdatePriceService {
  private http!:HttpClient;
  baseUrl=environment.serviceURL;
  httpOptions={
    headers:new HttpHeaders({'Authorization':sessionStorage.getItem('AuthorizationToken')!})
  }
  constructor(handler:HttpBackend,) {
    this.http=new HttpClient(handler);
}

getLivePrice=(id:string)=>{
  this.httpOptions={
    headers:new HttpHeaders({'Authorization':sessionStorage.getItem('AuthorizationToken')!})
}
   return this.http.get <GetPriceResponse>(this.baseUrl+'orderLivePrice/'+id,this.httpOptions);
}

updateLivePrice=(id:string,items:any)=>{
  this.httpOptions={
    headers:new HttpHeaders({'Authorization':sessionStorage.getItem('AuthorizationToken')!})
}
   return this.http.post<UpdateLivePrice>(this.baseUrl+'orderLivePrice/'+id,items,this.httpOptions);
}

}
