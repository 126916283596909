import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpHeaders, HttpBackend,HttpParams } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import {ToastrService} from 'ngx-toastr'
import { environment } from 'src/environments/environment';
import { LoginResponse } from '../Models/loginResponse';
import { LoginModel } from '../Models/loginModel';
import { OrderListService } from './order-list.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  baseUrl=environment.serviceURL;
  ofsUrl=environment.ofsURL;
  // private http!:HttpClient;
  httpOptions={
    headers:new HttpHeaders({'Content-Type':'application/json'}),
    observe: 'response' as 'response'
  }
  returnUrl:any;
  httpOption!: { params: HttpParams; };

  constructor
  (
    handler:HttpBackend,
    private route:ActivatedRoute,
    private router:Router,
    private toastr:ToastrService, private http: HttpClient,
    private orderListService:OrderListService
    
    ) 
    {
      // this.http= new HttpClient(handler);
      this.returnUrl='/login';
     }

     login(loginObj:LoginModel):Observable<HttpResponse<LoginResponse>>
     {
    return this.http.post<any>(this.baseUrl+"/login",loginObj,this.httpOptions);  
     }
    //  openOfs(userName:string,passWord:string):Observable<HttpResponse<any>>{
      openOfs():Observable<HttpResponse<any>>{
        
        const userName=sessionStorage.getItem('userName');
        const passWord= sessionStorage.getItem('passWord');
        if(userName!==null && passWord!==null){
          
      this.httpOption={
        params: new HttpParams()
      .set('username', userName)
       .set('password', passWord)
      }}
      return this.http.post<any>(this.ofsUrl+"loginvalidation",this.httpOption.params,
      {
        headers: new HttpHeaders()
          .set('Content-Type', 'application/x-www-form-urlencoded')
      }
    );
      }
     updateToken(token: string ) {
      if (token && token.length) {
          sessionStorage.setItem('AuthorizationToken', token)
      }}
     isLoggedIn() {


      if(sessionStorage.getItem("AuthorizationToken"))
      {
        console.log('true');
        
        return true
      }
      else
        return false
      // return true
      }
    
      logoutHandler()
      {
        sessionStorage.clear()
        this.orderListService.clearIntervalfunction()
        this.router.navigate([this.returnUrl]);
        this.toastr.success("Logged out Successfully","Success")
        
      }


      ssoLogin(token:string):Observable<HttpResponse<LoginResponse>>

    {
      return this.http.get<any>(this.baseUrl+"SSOLogin/"+token,this.httpOptions);   
    }

    getBuyerInfo(){
      return this.http.get<any>(this.baseUrl+"me/");
    }
}
