<div class="center">
    <h2 mat-dialog-title>Add Discount for Order No : {{data.no || data.id}}</h2>
</div>
<div mat-dialog-content class="">
<!-- <div class="col-8">
</div> -->
<div  *ngIf="orderCosts" class="row">
    <div class="col-8">
    <mat-table #table [dataSource]="dataSource" >
        <ng-container matColumnDef="id">
            <th mat-header-cell *matHeaderCellDef>ID</th>
            <td mat-cell *matCellDef="let element">{{element.skuShopId || element.skuErpSrcId || element.skuId}}</td>
        </ng-container>
        <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef>Name</th>
            <td mat-cell *matCellDef="let element">{{element.name}}</td>
        </ng-container>
        <ng-container matColumnDef="quantity">
            <th mat-header-cell *matHeaderCellDef>Quantity</th>
            <td mat-cell *matCellDef="let element">{{element.quantity}}</td>    
        </ng-container>
        <ng-container matColumnDef="itemprice">
            <th mat-header-cell *matHeaderCellDef>Item Price <span style="font-size: 12px;">(Incl Tax)</span></th>       
            <td mat-cell *matCellDef="let element">{{element.itemPrice}}<td>

        </ng-container>
        <ng-container matColumnDef="totalprice">
            <th mat-header-cell *matHeaderCellDef>Total Price <span style="font-size: 12px;">(Incl Tax)</span></th>     
           <td mat-cell *matCellDef="let element">{{element.totalItemPrice}}</td>

        </ng-container>
        <ng-container matColumnDef="discount">
            <th mat-header-cell *matHeaderCellDef>Discount</th>
            <td mat-cell *matCellDef="let element"> <input type="text" [min]="0" [value]="element.itemDiscount" [(ngModel)]="element.itemDiscount"><td>
        </ng-container>
        
  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </mat-table>
 <div style="margin-top: 10px;">
        
<form [formGroup]="form">
    <div class="toggle-btn">
        <mat-button-toggle-group name="discountChoice" aria-label="Discount Choice"  formControlName="choice">
            <mat-button-toggle value="DEC">Amount</mat-button-toggle>
            <mat-button-toggle value="PERCENT">Percentage</mat-button-toggle>
        </mat-button-toggle-group>

        <mat-form-field style="margin-left: 10px;margin-top: 15px;" appearance="outline">
            <mat-label>Discount</mat-label>
            <input matInput type="text" formControlName="discount"/>
        </mat-form-field>
    </div>
   
</form>
    </div>
</div>
<div *ngIf="orderCosts" class="col-4">
    <table>
        <table style="width:100%">
            <tr>
              <th class="align-left">Sub Total <span style="font-size: 12px;">(Excl Tax)</span>:</th>
              <td class="number-val">{{orderCosts.totalAmount.valueWithoutTax | currency : orderCosts.totalAmount.currency: 'symbol'}}</td>
            </tr>
            <tr>
              <th class="align-left">Shipping Cost</th>
              <td class="number-val">{{orderCosts.shippingCost | currency : orderCosts.totalAmount.currency: 'symbol'}}</td>
            </tr>
            <tr>
              <th class="align-left">Tax Amount:</th>
              <td class="number-val">{{orderCosts.netTaxAmount | currency : orderCosts.totalAmount.currency: 'symbol'}}</td>
            </tr>
            <tr>
                <th class="align-left">Discount Amount:</th>
                <td class="number-val">{{orderCosts.totalDiscount | currency : orderCosts.totalAmount.currency: 'symbol'}}</td>
             </tr>
            <tr>
               <th class="align-left">Grand Total:</th>
               <td class="number-val">{{orderCosts.grandTotal | currency : orderCosts.totalAmount.currency: 'symbol' }}</td>
            </tr>
          </table>
    </table>

</div>
</div>
<div *ngIf="!orderCosts">
    <mat-spinner style="margin:0 auto;"  mode="indeterminate"></mat-spinner>
</div>
<div class="payment-row">
        <button mat-stroked-button (click)="close()" style="margin-right: 5px;" ><mat-icon>close</mat-icon> Close</button>
        <button mat-flat-button color="primary"   (click)="submit()" [class.spinner]=spinner [disabled]="(!form.valid)||spinner"><mat-icon>price_check</mat-icon> Submit</button>

</div> </div>