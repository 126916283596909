
<!-- <div class="paddington"> -->
    <!-- <button mat-stroked-button color="primary" style="padding: 10px; padding-left: 20px; padding-right: 20px;" (click)="getBack()">
        <span style="font-size: 1rem; font-weight: 700;display: flex;align-items: center;">
            <mat-icon>arrow_back</mat-icon>&nbsp;
         Go Back
        </span> 
     </button> -->
    <!-- <button mat-flat-button color="primary" printSectionId="print" [useExistingCss]="true" style="padding: 10px; padding-left: 20px; padding-right: 20px;" ngxPrint>
       <div style="font-size: 1rem; font-weight: 700; display: flex;align-items: center;">
        <mat-icon>print</mat-icon>&nbsp;
        Print Invoice
       </div> 
    </button>
</div> -->

<!-- <mat-dialog-content > -->
    <!-- <div class="card" style="margin: 10px;;">
        <mat-card>
<div id="print">

    <div class="wrapper1 margin1">
        <div class="image" >
            <img src="{{logo}}"
            alt="LOGO0" 
            height="80px"
            width="80px">
            <div>
                <h3>{{companyName}}</h3>
            </div>
        </div>
        <div class="sales" style="border-left: hidden;">
            <div class="name">
                <span style="font-weight: 700;color: gray;"> Pro Forma Invoice #:</span> 278
            </div>
            <div class="name">
                <span style="font-weight: 700;color: gray;">Pro Forma Date:</span> {{order.orderedDate.slice(0,10) | date:'dd MMM yyyy'}}
                
            </div>
        </div>
        <div class="contacts" style="border-top: hidden; border-bottom: hidden;">
            <h4>From:</h4>
            <div class="mar-left">{{wareHouseName}},</div>
            <div class="mar-left">{{address.addressLine1}} {{address.addressLine2}}</div>
            <div class="mar-left">{{address.street}} {{address.city}}</div>
            <div class="mar-left">{{address.state}} {{address.country}}</div>
            <div class="mar-left">{{address['pin/zip']}}</div>
        </div>
        <div class="company-details" style="border-top: hidden; border-bottom: hidden; border-left: hidden;">
            <h4>To:</h4>
            <div class="mar-left">{{order.shipToAddress.adNameFirst}} {{order.shipToAddress.adNameLast}},</div>
            <div class="mar-left">{{order.shipToAddress.adLine1}} {{order.shipToAddress.adLine2}}</div>
            <div class="mar-left">{{order.shipToAddress.street}} {{order.shipToAddress.city}}</div>
            <div class="mar-left">{{order.shipToAddress.state}} {{order.shipToAddress.country}}</div>
            <div class="mar-left">{{order.shipToAddress.zip}} {{order.shipToAddress.phone}}</div>
        </div>
        <div class="table" style="padding:0; border-left: hidden; border-right: hidden; border-bottom: hidden; margin-bottom: 0;">
            <table style="padding:0px; width: 100%;" cellspacing="0"> 
                <thead>
                    <tr> 
                        <th class="head-font2">#.</th>
                        <th class="head-font2">ID</th>
                        <th class="head-font2" style="width: 35%;">Items</th>
                        <th class="head-font2">Unit</th>
                        <th class="head-font2">Qty</th>
                        <th class="head-font2">Price/Item ({{order.totalAmount.currency}})</th>
                        <th class="head-font2">Total Price ({{order.totalAmount.currency}})</th>
                        <th class="head-font2">Tax (%)</th>
                        <th class="head-font2">Tax({{order.totalAmount.currency}})</th>
                        <th class="head-font2">Amount ({{order.totalAmount.currency}})</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let item of order.items.item;let indexItem=index;">
                        <td>{{indexItem+1}}</td>
                        <td>{{item.productId}}</td>
                        <td style="width: 35%;">{{item.productShortName||item.productLongName}}</td>
                        <td align="right">{{item.uom}}</td>
                        <td align="right">{{item.quantity}}</td>
                        <td align="right"> {{(item.price.actual).toFixed(2)}}  </td>
                        <td align="right">{{((item.price.actual)*(item.quantity)).toFixed(2)}}</td>
                        <td align="right">{{item.price.tax?(((item.price.tax/(item.price.actual))*100).toFixed(2)):'0.00'}}</td>
                        <td align="right">{{item.price.tax?(item.price.tax*item.quantity).toFixed(2):'0.00'}} </td>
                        <td align="right">{{((item.price.actual*item.quantity)+(item.price.tax!*item.quantity)).toFixed(2)}} </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div class="prices" style="border-top: hidden;">
            <div>
                <div class="head-font">Total Tax ({{order.totalAmount.currency}}):</div>
                <div>{{toNumber(order.netTaxAmount).toFixed(2)}}</div>
            </div>
            <div>
                <div class="head-font">Shipping Cost ({{order.totalAmount.currency}}):</div>
                <div>{{toNumber(order.shippingCost).toFixed(2)}}</div>
            </div>
            <div>
                <div class="head-font">Discount ({{order.totalAmount.currency}}):</div>
                <div>{{toNumber(order.totalDiscount).toFixed(2)}}</div>
            </div>
            <div>
                <div class="head-font">Grand Total ({{order.totalAmount.currency}}):</div>
                <div>{{toNumber(order.grandTotal).toFixed(2)}}</div>
            </div>
        </div>
        
        
    </div>
</div>    
</mat-card>
</div> -->

<mat-dialog-content >
<div id="printInvoice" *ngIf="order" class="printformClass" style="margin:1em;height:auto"> 
    <div style="margin: 5px;" class="item-center">
    <div *ngIf="logo" class="column11"><img src="{{logo}}" height="80px" width="80px" onerror="this.style.display='none'"></div>
    <div class="column1">
        <h3>Invoice - {{order.orderDisplayName}}</h3> 
         <h6>(Non GST/VAT compliant)</h6>          
        <span>Delivery Due Date : {{order.deliveryTime | date:'dd MMM yyyy'}}</span><br>
        <span>Submitted Date :  {{order.orderedDate.slice(0,10) | date:'dd MMM yyyy'}}</span>
    </div>
</div>
<div style="margin:5px" class="">
   <div class="column11" style=" display: flex; flex-direction: column;">
       <span class="content-title">From</span> 
       <div class="text-bold">{{wareHouseName}}</div>
       <div class="text-bold">{{address.addressLine1}} {{address.addressLine2}}</div>
       <div class="text-bold">{{address.street}} {{address.city}}</div>
       <div class="text-bold">{{address.state}} {{address.country}}</div>
       <div class="text-bold">{{address['pin/zip']}}</div>

   </div>
   <div class="column1" style=" display: flex; flex-direction: column;">
       <div class="content-title">BILL TO</div>  
       <div class="text-bold">{{order.retailerAddress.adNameFirst}} {{order.retailerAddress.adNameLast}}</div>
       <div class="text-bold">{{order.retailerAddress.adLine1}} {{order.retailerAddress.adLine2}}</div>
       <div class="text-bold">{{order.retailerAddress.street}} {{order.retailerAddress.city}}</div>
       <div class="text-bold">{{order.retailerAddress.state}} {{order.retailerAddress.country}}</div>
       <div class="text-bold">{{order.retailerAddress.zip}} {{order.retailerAddress.phone}}</div>
       <div class="text-bold">Buyer Group: {{order.buyerGroup}}</div>
   </div>
</div>
<div class="table-area">
    <table>
        <thead><tr>
            <th>#</th>
            <th>ITEM CODE</th>
            <th>ITEM NAME</th>
            <th>UNIT</th>
            <th>UNIT PRICE <br><span style="font-size:10px;">(EXCL.TAX)</span></th>
            <th>TAX  <span style="font-size:10px">(%)</span></th>
            <th>QTY</th>
            <th class="number-val">TOTAL <br><span style="font-size:10px;"> (EXCL TAX)</span></th></tr>
        </thead>
        <tbody>
            <tr *ngFor="let item of order.items.item;let i =index;">
                <td>{{i+1}}</td>
                <td>{{item.skuShopId || item.skuErpSrcId || item.skuId}}</td>
                <td>{{item.productShortName||item.productLongName}}</td>
                <td>{{item.uom}}</td>
                <td> {{item.price.unitPriceExclTax}}  </td> 
                <td>{{item.price.tax?(((item.price.tax/(item.price.actual))*100).toFixed(2)):'0.00'}}</td>
                <td>{{item.quantity}}</td>
                <td class="number-val">{{item.price.totalItemPriceExclTax}} </td>
            </tr> 
       <tr>          
           <td></td>
           <td></td>
           <td></td>
           <td></td> 
           <td class="font-weight600" colspan="3">SUB TOTAL (EXCL TAX): </td>
           <td class="number-val font-weight600">{{order.subTotalExclTax | currency : order.totalAmount.currency: 'symbol'}}</td>
       </tr>          
        </tbody>
    </table>
</div>
<div class="flex-container">
    <div class="column" *ngIf="order.userNotes || order.orderNotes">
        <div class="text-bold"><b>Order Notes:</b></div>
            <div class="text-bold text-alignment-fit">{{order.userNotes ?? order.orderNotes}}</div>
    </div>
   <div class="column">
       <table>
       <table style="width:100%">
        <tr>
            <th class="align-left">Discount Amount :</th>
            <td class="number-val">{{toNumber(order.totalDiscount).toFixed(2) | currency : order.totalAmount.currency: 'symbol'}}</td>
         </tr>
          
           <tr>
             <th class="align-left">Tax Amount:</th>
             <td class="number-val">{{toNumber(order.netTaxAmount).toFixed(2) | currency : order.totalAmount.currency: 'symbol'}}</td>
           </tr>
           <tr>
            <th class="align-left">Shipping Cost:</th>
            <td class="number-val">{{toNumber(order.shippingCost).toFixed(2) | currency : order.totalAmount.currency: 'symbol'}}</td>
          </tr>
          <tr style="border-bottom:2px solid black ;">
              <th  class="align-left">Grand Total:</th>
              <td class="number-val">{{toNumber(order.grandTotal).toFixed(2) | currency : order.totalAmount.currency: 'symbol'}}</td>
           </tr>
         </table>
   </table>
   </div>

</div>
</div> 
</mat-dialog-content>
<div class="paddington">
    <button mat-stroked-button (click)="close()" style="margin-right: 5px;" ><mat-icon>close</mat-icon> Close</button>
     <button  mat-icon-button class="example-icon"   matTooltip="Print Invoice" printSectionId="printInvoice" [useExistingCss]="true" ngxPrint>
        <img src="../assets/icon/print.png" alt=""style="height:24px;">

    </button> 
    <button mat-icon-button matTooltip="Download as PDF" (click)="openPDF()"><img src="../assets/icon/pdf.png" alt=""style="height:24px;"></button>
    <button mat-icon-button matTooltip="Download as Word" (click)="Export2Word()"><img src="../assets/icon/word.png" alt=""style="height:24px;"></button>
</div>