<div *ngIf="order">
    <div class="row" style="padding: 0%;">
    <div class="col-lg-4"><h4  style="text-align: left;color:'#3F51B5';" >ORDER DETAILS</h4></div>
        <div class="col-lg-8" style="padding-right: 0%;float:right !important">
            
            <button mat-icon-button *ngIf="order.status.value !=='Rejected' && order.status.value !== 'Cancelled' && order.status.value !=='Delivered' && order.status.value !=='Shipped'" class="example-icon editButton" (click)="toEditOrder()" matTooltip="Edit Order">
                <img src="../assets/icon/editorder.png" alt=""style="height:24px;">

            </button>

            <button mat-icon-button
                *ngIf="order.status.value === 'Accepted'"
                class="example-icon editButton" matTooltip="Add Discount"  (click)="openDialogDiscount()">
                <img src="../assets/icon/discount.png" alt=""style="height:24px;">

            </button>
            <button mat-icon-button (click)="openUpdatePrice(order.orderId,order.orderDisplayName)"
            *ngIf="order.status.value ==='Submitted'||order.status.value === 'Pending' || order.status.value === 'Accepted'"
            class="example-icon editButton" matTooltip="Update Live Price">
            <img src="../assets/icon/updateprice.png" alt=""style="height:24px;">

        </button>
            <button mat-icon-button (click)="openDialog()"
                *ngIf="(order.status.value ==='Submitted'||order.status.value === 'Pending' || order.status.value === 'Accepted' || order.status.value === 'Shipped' || order.status.value === 'Delivered') && (order.finacialStatus !== 'PAID')"
                class="example-icon editButton" matTooltip="Add Payment">
                <img src="../assets/icon/addpayment.png" alt=""style="height:24px;">

            </button>
            <button *ngIf="order.status.value !=='Rejected' && order.status.value !== 'Cancelled'" mat-icon-button class="example-icon" (click)="toProForma()" matTooltip="Proforma Invoice">
                <img src="../assets/icon/invoice.png" alt=""style="height:24px;">

            </button>
            <button *ngIf="order.status.value !=='Rejected' && order.status.value !== 'Cancelled'" mat-icon-button class="example-icon"  (click)="openDeliverySlip()" matTooltip="Delivery slip">
                <img src="../assets/icon/deliveryslip.png" alt=""style="height:24px;">

            </button>

            <button *ngIf="order.status.value === ('Submitted')||order.status.value === ('Pending')" mat-icon-button  [ngStyle]="{'filter:opacity(0.25);':ifProcessing === true}" 
                class="example-icon editButton" matTooltip="Accept Order" (click)="processOrderById(order.orderId,'ACCEPT')">
                <img src="../assets/icon/acceptorder.png" alt=""style="height:24px;">

            </button>
            <button *ngIf="order.status.value === ('Submitted')||order.status.value === ('Pending')" mat-icon-button [ngStyle]="{'filter:opacity(0.25);':ifProcessing === true}"
                class="example-icon" matTooltip="Reject Order"  (click)="processOrderById(order.orderId,'REJECT')">
                <img src="../assets/icon/rejectorder.png" alt=""style="height:24px;">

            </button>
            <button *ngIf="order.status.value === ('Accepted')" mat-icon-button class="example-icon" [ngStyle]="{'filter:opacity(0.25);':ifProcessing === true}"
                matTooltip="Ship Order"  (click)="processOrderById(order.orderId,'SHIP')">
                <img src="../assets/icon/shiporder.png" alt=""style="height:24px;">

            </button>

            <button *ngIf="order.status.value === ('Shipped')" mat-icon-button class="example-icon" [ngStyle]="{'filter:opacity(0.25);':ifProcessing === true}"
                matTooltip="Deliver Order"  (click)="processOrderById(order.orderId,'DELIVER')">
                <img src="../assets/icon/deliverorder.png" alt=""style="height:24px;">

            </button>

            <!-- <button mat-flat-button color="primary" matTooltip="Print Invoice" printSectionId="ivoice" [useExistingCss]="true" ngxPrint>
        Pro Forma Invoice
    </button> -->
            <!-- <button mat-flat-button color="primary" matTooltip="Print Delivery Slip" printSectionId="ivoice" [useExistingCss]="true" ngxPrint>
        Delivery Slip
    </button>  -->
       <!-- <button mat-stroked-button matTooltip=" Add Payment" color="primary" *ngIf="nextStatus!=='rejected'" (click)="openDialog()"><mat-icon>payments</mat-icon></button>
       <button mat-stroked-button matTooltip=" Add Discount" color="primary" *ngIf="nextStatus==='deliver'|| nextStatus==='ship'" (click)="openDialogDiscount()"><mat-icon>discount</mat-icon></button> -->
    <!-- <button mat-stroked-button color="accent" (click)="toProForma()"></button>  -->
    <!-- <button mat-stroked-button matTooltip="Edit Order"  color="primary" *ngIf="nextStatus==='acceptOrReject'||nextStatus==='ship'" (click)="toEditOrder()"> <mat-icon>edit</mat-icon></button> -->
      <!-- <button mat-flat-button color="primary" matTooltip="Print Invoice" printSectionId="ivoice" [useExistingCss]="true" ngxPrint>
            <mat-icon>print</mat-icon> 
        </button> -->
            <!-- <button mat-flat-button color="primary" matTooltip="Print deliverySlip" printSectionId="deliverySlip" [useExistingCss]="true" ngxPrint>
            <mat-icon>print</mat-icon> 
        </button> -->
        </div>
    
</div>
<div mat-dialog-content style="padding-top:20px">
   



        <div class="row" style="padding-left:20px">
            <div class="col" > Buyer Info : 
               <span style="padding-left:128px"></span>{{order.retailerShop.retailerShopName}}
               <div style="padding-left:209px">{{order.retailerShop.phone}}</div>
                <div>Delivery Address :<span style="padding-left:85px">{{order.shipToAddress.adLine1}} {{order.shipToAddress.adLine2}}</span></div>
                                 
                <div style="padding-left:209px">{{order.shipToAddress.street}} {{order.shipToAddress.city}}</div>
                <div style="padding-left:209px">{{order.shipToAddress.state}} {{order.shipToAddress.country}}</div>
                <div style="padding-left:209px">{{order.shipToAddress.zip}} {{order.shipToAddress.phone}}</div>
                <div>Delivery Date : &nbsp;<span style="padding-left:100px">{{order.deliveryTime.includes(':')?(order.deliveryTime| date:'dd MMM yyyy'):order.deliveryTime}}</span></div>
 
            </div>

            <div class="col" style="padding-top:10px">

                <strong>Order ID : {{order.orderDisplayName||order.orderId}} </strong> 
                <div [ngStyle]="{'color':statusColour(order.status.value)}">Order Status : {{order.status.value}}</div>
                <div>Submitted user: {{order.user.userName}} ({{order.user.userType}})</div>
                <div>Phone : {{order.user.phone}}</div>
                <div>Email : {{order.user.email}}</div>
                <div>Date : {{submttdDate | date:'dd MMM yyyy h:mm a'}}</div>
                <div>Location : {{order.location?.locationName || 'Not Applicable'}}</div>

     
            </div>
        </div>
<div class="p-2">
        <table mat-table [dataSource]="dataSource">

            <ng-container matColumnDef="sn">
                <th mat-header-cell  [ngStyle]="{'border-bottom': '1px solid rgba(63, 81, 181, 0.4)'}" *matHeaderCellDef> SN </th>
                <td mat-cell *matCellDef="let element; let i = index;"> {{i+1}} </td>
                <td mat-footer-cell *matFooterCellDef></td>
              </ng-container>
        
            <ng-container matColumnDef="Id">
                <th mat-header-cell  [ngStyle]="{'border-bottom': '1px solid rgba(63, 81, 181, 0.4)'}" *matHeaderCellDef> ID </th>
                <td mat-cell *matCellDef="let element" [ngStyle]="{'border-bottom': '1px solid rgba(63, 81, 181, 0.4)'}"> {{element.skuShopId || element.skuErpSrcId || element.skuId}} </td>
                <td mat-footer-cell *matFooterCellDef></td>
            </ng-container>
            <ng-container matColumnDef="Items">
                <th mat-header-cell  [ngStyle]="{'border-bottom': '1px solid rgba(63, 81, 181, 0.4)'}" *matHeaderCellDef> Item </th>
                <td mat-cell *matCellDef="let element"  [ngStyle]="{'border-bottom': '1px solid rgba(63, 81, 181, 0.4)'}"> {{element.productShortName||element.productLongName}} </td>
                <td mat-footer-cell *matFooterCellDef></td>
            </ng-container>


            <ng-container matColumnDef="Unit">
                <th mat-header-cell  [ngStyle]="{'border-bottom': '1px solid rgba(63, 81, 181, 0.4)'}" *matHeaderCellDef> Unit </th>
                <td mat-cell *matCellDef="let element"  [ngStyle]="{'border-bottom': '1px solid rgba(63, 81, 181, 0.4)'}"> {{element.uom}} </td>
                <td mat-footer-cell *matFooterCellDef></td>
            </ng-container>


            


            <ng-container matColumnDef="Price/Item">
                <th mat-header-cell *matHeaderCellDef  [ngStyle]="{'border-bottom': '1px solid rgba(63, 81, 181, 0.4)'}"> Unit Price <br><span style="font-size: smaller;color:black"> (Excl Tax.)</span></th>
                <td mat-cell *matCellDef="let element"  [ngStyle]="{'border-bottom': '1px solid rgba(63, 81, 181, 0.4)'}"> {{element.price.unitPriceExclTax}}</td>
                <td mat-footer-cell *matFooterCellDef></td>
            </ng-container>
            <!-- <ng-container matColumnDef="Totalprice">
                <th mat-header-cell *matHeaderCellDef> Total </th>
                <td mat-cell *matCellDef="let element"> {{((element.price.actual)*(element.quantity)).toFixed(2)}} </td>
            </ng-container> -->


            <ng-container matColumnDef="Tax%" >
                <th mat-header-cell *matHeaderCellDef [ngStyle]="{'border-bottom': '1px solid rgba(63, 81, 181, 0.4)'}" > Tax<br> % </th>
                <td mat-cell *matCellDef="let element" [ngStyle]="{'border-bottom': '1px solid rgba(63, 81, 181, 0.4)'}">
                    {{element.price.unitTaxPercent}} </td>
                    <td mat-footer-cell *matFooterCellDef>Subtotal (Excl Tax)</td>
            </ng-container>


            <!-- <ng-container matColumnDef="Tax">
                <th mat-header-cell *matHeaderCellDef> Tax (INR)</th>
                <td mat-cell *matCellDef="let element">
                    {{element.price.tax?(element.price.tax*element.quantity).toFixed(2):'0.00'}} </td>
            </ng-container> -->

            <ng-container matColumnDef="Qty">
                <th mat-header-cell *matHeaderCellDef  [ngStyle]="{'border-bottom': '1px solid rgba(63, 81, 181, 0.4)'}"> Qty </th>
                <td mat-cell *matCellDef="let element"  [ngStyle]="{'border-bottom': '1px solid rgba(63, 81, 181, 0.4)'}"> {{element.quantity}} </td>
                <td mat-footer-cell *matFooterCellDef></td>
            </ng-container>


            <ng-container matColumnDef="Amt">
                <th mat-header-cell *matHeaderCellDef [ngStyle]="{'border-bottom': '1px solid rgba(63, 81, 181, 0.4)'}" > Total <br><span style="font-size: smaller;color:black"> (Excl Tax.)</span></th>
                <td mat-cell *matCellDef="let element" [ngStyle]="{'border-bottom': '1px solid rgba(63, 81, 181, 0.4)'}">
                    {{element.price.totalItemPriceExclTax}} </td>
                <td mat-footer-cell *matFooterCellDef>{{calculation()}} </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            <tr mat-footer-row *matFooterRowDef="displayedColumns"></tr>
           
        </table>
    </div>

        <!-- 
        <div class="ordertable" style="padding: 0;">
            <table style="padding:0px; width: 100%;" cellspacing="0"> 
                <thead>
                    <tr>
                         Id ,Name, Unit, Rak Info ,Price ,Quantity,Total Price 
                        <th class="head-font2">#.</th>
                        <th class="head-font2">ID</th>
                        <th class="head-font2" style="width: 35%;">Items</th>
                        <th class="head-font2">Unit</th>
                        <th class="head-font2">Qty</th>
                        <th class="head-font2">Price/Item ({{order.totalAmount.currency}})</th>
                        <th class="head-font2">Total Price ({{order.totalAmount.currency}})</th>
                        <th class="head-font2">Tax (%)</th>
                        <th class="head-font2">Tax({{order.totalAmount.currency}})</th>
                        <th class="head-font2">Amount ({{order.totalAmount.currency}})</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let item of order.items.item;let indexItem=index;">
                        <td>{{indexItem+1}}</td>
                        <td>{{item.productId}}</td>
                        <td style="width: 35%;">{{item.productShortName||item.productLongName}}</td>
                        <td align="right">{{item.uom}}</td>
                        <td align="right">{{item.quantity}}</td>
                        <td align="right"> {{(item.price.actual).toFixed(2)}}  </td>
                        <td align="right">{{((item.price.actual)*(item.quantity)).toFixed(2)}}</td>
                        <td align="right">{{item.price.tax?(((item.price.tax/(item.price.actual))*100).toFixed(2)):'0.00'}}</td>
                        <td align="right">{{item.price.tax?(item.price.tax*item.quantity).toFixed(2):'0.00'}} </td>
                        <td align="right">{{((item.price.actual*item.quantity)+(item.price.tax!*item.quantity)).toFixed(2)}} </td>
                    </tr>
                </tbody>
            </table>
        </div> -->
        <div class="row" style="padding-left: 3%;">
            <div class="col">
                <!-- <div class="row">
                    <div class="col" >Submitted Date: &nbsp;</div>
                    <div class="col" style="padding-right: 3%;">{{submttdDate | date:'dd MMM yyyy h:mm a'}}</div>
                </div> -->
                <!-- <div class="row">
                    <div class="col">Delivery Date:&nbsp;</div>
                    <div class="col" style="padding-right: 3%;">{{order.deliveryTime.includes(':')?(order.deliveryTime| date:'dd MMM
                        yyyy'):order.deliveryTime}}</div>
                </div>
 -->            <div class="row">
 <div class="col">
    Payment Status :
</div>
<div class="col" style="padding-right: 3%;">
    {{order.finacialStatus || 'Not Recorded'}}
</div>
</div>
                <div class="row">
                    <div class="col">
                        Paid Amount :
                    </div>
                    <div class="col" style="padding-right: 3%;">
                        
                        {{toNumber(order.paidAmount).toFixed(2) | currency : order.totalAmount.currency: 'symbol'}}
                    </div>
                </div>
                <div class="row">
                    <div class="col">
                        Due Amount :
                    </div>
                    <div class="col" style="padding-right: 3%;">
                       
                        {{toNumber(order.outstandingAmount).toFixed(2) | currency : order.totalAmount.currency: 'symbol'}}
                    </div>
                </div>
                <div class="row">
                    <div class="col">
                        Account Balance : 
                    </div>
                    <div class="col" style="padding-right: 3%;">
                        {{toNumber(order.accountBalance).toFixed(2) | currency : order.totalAmount.currency: 'symbol'}}
                    </div>
                </div>
                <div class="row">
                    <div class="col" >
                        Order Notes:
                    </div>
                    <div class="col" style="padding-right: 3%;">
                        {{order.notes[0]?.notes!}} {{order.userNotes!}}
                    </div>
                </div>


            </div>
            <div class="col">
                <div class="row">
                    <div class="col">Total Tax:</div>
                    <div class="col"><span style="display:flex !important;
                        justify-content:flex-end!important;padding-right:40%!important">{{toNumber(order.netTaxAmount).toFixed(2) | currency : order.totalAmount.currency: 'symbol'}}</span></div>
                </div>
                <div class="row">
                    <div class="col">Shipping Cost:</div>
                    <div class="col">
                        
                        <span style="display:flex !important;
                        justify-content:flex-end!important;padding-right:40%!important"><button  *ngIf="order.status.value ==='Submitted'||order.status.value === 'Pending' || order.status.value === 'Accepted'" style="margin: 0!important;min-width:40px;
    line-height: 20px;" mat-button (click)="editShippingCost()"  matTooltip="Edit Shipping Cost">
                            <img src="../assets/icon/edit.png"  alt="" style="height:18px;">
                           
                          </button>{{toNumber(order.shippingCost).toFixed(2) | currency : order.totalAmount.currency: 'symbol'}}</span></div>
                </div>
                <div class="row">
                    <div class="col">Discount </div>
                    <div class="col"><span style="display:flex !important;
                        justify-content:flex-end!important;padding-right:40%!important">{{toNumber(order.totalDiscount).toFixed(2) | currency : order.totalAmount.currency: 'symbol'}}</span></div>
                </div>
                <div class="row">
                    <div class="col">Grand Total:</div>
                    <div class="col"><span style="display:flex !important;
                        justify-content:flex-end!important;padding-right:40%!important">{{toNumber(order.grandTotal).toFixed(2) | currency : order.totalAmount.currency: 'symbol'}}</span></div>
                </div>
            </div>

            <!-- </mat-card> -->
        </div>
        <div id="deliverySlip" class="print">
            <app-delivery-slip></app-delivery-slip>
        </div>
        <div id="ivoice" class="print">
            <app-pro-forma-page></app-pro-forma-page>
        </div>

    
</div>
    <mat-dialog-actions align="end">
        <button mat-button mat-dialog-close>Close</button> 
        <!-- <button mat-stroked-button [disabled]="disabled"
            [class.spinner]="disabled" color="primary" (click)="processOrder(nextStatus.toUpperCase())"
            *ngIf="nextStatus!=='acceptOrReject'&&nextStatus!=='delivered'&&nextStatus!=='rejected'">
            <mat-icon>navigate_next</mat-icon> {{nextStatus}}
        </button>
        <div *ngIf="hide !=='REJECT'"><button mat-stroked-button [disabled]="disabled" [class.spinner]="disabled"
                color="primary" (click)="processOrder('ACCEPT')" *ngIf="nextStatus==='acceptOrReject'">
                <mat-icon>thumb_up_alt</mat-icon> Accept
            </button></div>
        <div *ngIf="hide !== 'ACCEPT'"><button mat-stroked-button [disabled]="disabled" [class.spinner]="disabled"
                color="warn" (click)="processOrder('REJECT')" *ngIf="nextStatus==='acceptOrReject'">
                <mat-icon>thumb_down_alt</mat-icon> Reject
            </button></div> -->

    </mat-dialog-actions>

</div>