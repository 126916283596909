import { HttpErrorResponse } from '@angular/common/http';
import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA,MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { ItemListArray, Order } from 'src/app/Models/updatePrice';
import { UpdatePriceService } from 'src/app/Service/update-price.service';
import {ConfirmDialogComponent} from '../../../app/shared/components/confirm-dialog/confirm-dialog.component'
export interface UpdateObj{
orderItemId : string,
price : string
}
@Component({
  selector: 'app-update-live-price',
  templateUrl: './update-live-price.component.html',
  styleUrls: ['./update-live-price.component.css']
})
export class UpdateLivePriceComponent implements OnInit {

  displayedColumns:string[]=['Id','Item','Qty','Price','Live Price','Total']
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;
  @ViewChild(MatTable) table!: MatTable<ItemListArray>;
  dataSource: any;
  isPriceUpdated:boolean=false;
  orderedPrice: string="";
  currentPrice: string="";
  currency: string="";
  editMode: boolean= false;
  updateObj:UpdateObj = {} as UpdateObj;
  updateArray: UpdateObj[] = [];
  

  constructor(public dialogRef: MatDialogRef<UpdateLivePriceComponent>,
    @Inject(MAT_DIALOG_DATA) public data:any,private updatePriceService:UpdatePriceService,private toastr:ToastrService,private dialog:MatDialog) {

     console.log(data.orderId);
    }

  ngOnInit(): void {
    this.editMode=false;
    this.findPrice();
  }

  editPrice(){
    this.editMode=true;
  }

  findPrice():void{
    this.updatePriceService.getLivePrice(this.data.orderId).subscribe(res => {
      console.log(res)
      if(res)
      {
      this.dataSource=new MatTableDataSource(res.itemListArray);
         
       
      this.isPriceUpdated = res.isPriceChanged;
      this.orderedPrice = res.orderedGrandTotal
      this.currentPrice =res.liveGrandTotal
      this.currency = res.currency
    }
   });
  }

  updatePrice(){
   
    if(!this.editMode)
    
    {
      var obj ={
        items: this.updateArray
      }
    this.updatePriceService.updateLivePrice(this.data.orderId,obj).subscribe(res => {
      console.log(res)
      if(res.status === "SUCCESS")
      {
      this.toastr.success(res.message)
      this.dialogRef.close();
      }
      else
      this.toastr.error(res.message)
  });
}
if(this.editMode){
 /*  console.log(this.dataSource.data) */
 const dialogRef = this.dialog.open(ConfirmDialogComponent, {
  maxWidth: "400px",
  data: {
      title: "Are you sure you want to update all ordered prices to the live price for the order "+ this.data.orderId,
      btn1: "No",
      btn2: "Yes"
     }
});
dialogRef.afterClosed().subscribe((result: boolean) => {
  console.log('The dialog was closed');
  console.log(result);//returns undefined
 
if(result === true)
{
  this.dataSource.data.forEach((post: { orderItemId: any; price: { liveItemPrice: any; }; }) => {
    
    this.updateArray.push({
      orderItemId: post.orderItemId,
      price: post.price.liveItemPrice
    })
  
  })
  
  if(this.updateArray)
  {
    var obj ={
      items: this.updateArray
    }
  this.updatePriceService.updateLivePrice(this.data.orderId,obj).subscribe(res => {
    console.log(res)
    if(res.status === "SUCCESS")
    {
    this.toastr.success(res.message)
    this.dialogRef.close();
    }
    else
    this.toastr.error(res.message)
});
}
}

})

}
}
}
