import { HttpBackend, HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { EditItemObject } from '../Models/editItemModel';
import { EditOrderResponse, OrderList } from '../Models/orderList';

@Injectable({
  providedIn: 'root'
})
export class EditOrderService {
  baseUrl=environment.serviceURL;
  // private http!:HttpClient;
  constructor(handler:HttpBackend,private http: HttpClient,
    private toastr:ToastrService) {
    // this.http=new HttpClient(handler);
     }
  EditOrderPostReq=(obj:EditItemObject):Observable<EditOrderResponse>=>{
    let httpOptions={
      headers:new HttpHeaders({'Authorization':sessionStorage.getItem('AuthorizationToken')!})
    }
    return this.http.post<any>(this.baseUrl+'order/edit',obj,httpOptions)
  }
}
