import { Component, ElementRef, Inject, Input, OnInit, ViewChild } from '@angular/core';
import { Form, FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { EditItemObject } from 'src/app/Models/editItemModel';
import { Item, Items, Order,OrderList } from 'src/app/Models/orderList';
import { EditOrderService } from 'src/app/Service/edit-order.service';
import { MatDialog, MatDialogConfig, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {ConfirmDialogComponent} from '../../../app/shared/components/confirm-dialog/confirm-dialog.component'
import { MatSlideToggle, MatSlideToggleChange } from '@angular/material/slide-toggle';
import { OrderByIdService } from 'src/app/Service/order-by-id.service';

@Component({
  selector: 'app-edit-order-table',
  templateUrl: './edit-order-table.component.html',
  styleUrls: ['./edit-order-table.component.css']
})
export class EditOrderTableComponent implements OnInit {
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;
  @ViewChild(MatTable) table!: MatTable<Order>;
  @ViewChild('slide') matSlideToggle!: MatSlideToggle;
  @Input()
  order!:Order;
  @Input()
  backRoute!:string;
  quantity!:number[];
  orders!:Order[];
  disabled=false
  checked = false;
  tableData!:Item[]
  dataSource!: MatTableDataSource<Item>;
  form!:FormGroup
  constructor(private fb:FormBuilder,private router:Router,private editOrderService:EditOrderService,
      private toastr:ToastrService,private dialog:MatDialog,private orderByIdService:OrderByIdService,
      public dialogRef: MatDialogRef<EditOrderTableComponent>,
    @Inject(MAT_DIALOG_DATA) public data:any) {
    
    
   }

  displayedColumns = ['index','id','item','orderedqty','qty']

  ngOnInit(): void {
    
    
    this.orderByIdService.getOrderlist(this.data.orderId)
    .subscribe(
     res => {
      if(res.acknowledgement.status === "SUCCESS")
      {
      this.order = res.order[0]
      this.quantity=this.order.items.item.map(x=>x.quantity)
    this.form=this.fb.group({quantities :this.fb.array([])})
    for(let x of this.quantity)
    {
      console.log(x);
      
      const qtyformfield=this.fb.group({quantity:[x.toString(),Validators.required,]})
      this.quantityArray.push(qtyformfield)
    }
      this.tableData=this.order.items.item;
      this.dataSource=new MatTableDataSource<Item>(this.tableData);
      this.dataSource.paginator=this.paginator;
      }
    })
      
      
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  get quantityArray(){
    return this.form.get('quantities') as FormArray
  }
  // getBack(){
  //   this.router.navigateByUrl(this.backRoute)
  // }
  
  submit(){
    this.disabled=true
    this.dialogRef.close();
    let editObj:EditItemObject={
      orderId:this.order.orderId,
      split:this.checked.toString(),
      items:[]
    }
    for(let x=0;x<this.form.value.quantities.length;x++)
    {
      let item={orderItemId:this.order.items.item[x].orderItemId,quantity:this.form.value.quantities[x].quantity.toString()}
      editObj.items.push(item)
    }
    
    this.editOrderService.EditOrderPostReq(editObj).subscribe(response=>{
      if(response.isSuccess === true)
      {
        this.orderByIdService.getOrderlist(this.order.orderId)
    .subscribe(
      data => {
      if(data.acknowledgement.status === "SUCCESS")
      {
      this.order = data.order[0]

      this.disabled=false
      }
    })
      this.toastr.success(response.message,'Success')
      }
      else if(response.isSuccess === false){
        this.disabled=false;
        this.toastr.error(response.message,"Error")
      }
    },err=>{
      this.disabled=false;
      this.toastr.error("Couldn't process the order","Error")
    })
  }

  onChange(ob: MatSlideToggleChange) {
    
    this.checked = ob.checked;
    if(this.checked === true)
    {
      const dialogRef = this.dialog.open(ConfirmDialogComponent, {
        maxWidth: "400px",
        data: {
            title: "Confirm Split Order? This action cannot be undone.",
            btn1: "No",
            btn2: "Yes"
           }
      });
      dialogRef.afterClosed().subscribe((result: boolean) => {
        console.log('The dialog was closed');
        console.log(result);//returns undefined
        this.checked=result
        if(result === false)
        this.matSlideToggle.toggle()
  });
  }
  }
    
}
