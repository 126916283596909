import { Injectable, OnDestroy } from '@angular/core';
import { HttpClient, HttpResponse, HttpHeaders, HttpBackend,HttpParams } from '@angular/common/http';
import { Observable, of, Subject } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import {ToastrService} from 'ngx-toastr'
import { environment } from 'src/environments/environment';
import { Order, OrderList } from '../Models/orderList';
import { AuthService } from './auth.service';
import { shareReplay } from 'rxjs/operators';


@Injectable({
  providedIn: 'root'
})
export class OrderListService implements OnDestroy {
    private number=0
    // private http!:HttpClient;
    baseUrl=environment.serviceURL;
    // httpOptions={
    //     headers:new HttpHeaders({'Authorization':sessionStorage.getItem('AuthorizationToken')!})
    // }
    interval!:any
    private subject=new Subject<any>();
  constructor(
      handler:HttpBackend,
      private http: HttpClient,
      private toastr:ToastrService,
  ) 
  {
      // this.http=new HttpClient(handler);
     // if(sessionStorage.getItem('AuthorizationToken'))
      //  this.periodicFetch()
     
   }
   getOrderlist=()=>
   {
  //   this.httpOptions={
  //     headers:new HttpHeaders({'Authorization':sessionStorage.getItem('AuthorizationToken')!})
  // }

    return this.http
   .get<OrderList>(this.baseUrl+'orders',);
   }
   public getOrdeReport(obj: any): Observable<any> {
    let url =`APIEndpoint/report/customer-down`;
    return this.http.post(url, obj, {
      responseType: "blob",
      headers: new HttpHeaders().append("Content-Type", "application/json")
    });
  }
   periodicFetch=()=>{
     this.interval=setInterval(()=>{
       this.getOrderlist().pipe(shareReplay()).subscribe(res=>{
         if(localStorage.getItem('liveOrders'))
         {  let localList:Order[]=JSON.parse(localStorage.getItem('liveOrders')!)
           if(localList.length!==res.order.length)
           {
             sessionStorage.setItem('liveOrders',JSON.stringify(res.order))
             this.subject.next(res.order)
             this.toastr.info('Notification','New Orders Available')
            }
            else if(localList.length)
            {
              if(localList[localList.length-1].orderId!==res.order[res.order.length-1].orderId)
              {
                sessionStorage.setItem('liveOrders',JSON.stringify(res.order))
                this.subject.next(res.order)
                let audio=new Audio('../../assets/audio/RZFWLXE-bell-hop-bell.mp3')
                audio.play();
                this.toastr.info('Notification','New Orders Available')
              }
              else
                console.log('Same array');
                
            }
            else
              console.log('length is zero');
            
         }
         else
          console.log('sessionStorage empty');
         
         
       },
       err=>{
         console.log(err);
         console.log('Error in fetching');
         
      },
       )
     },90000)
   }
   shareSubject():Observable<any>{
     return this.subject.asObservable();
   }
   clearIntervalfunction(){
     clearInterval(this.interval);
   }

   ngOnDestroy()
   {
     this.clearIntervalfunction();
     console.log('cleared set Interval');
     
   }
   

}



