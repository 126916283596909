<div>
    <div class="head center">
        <h2> Forgot Password?</h2>
    </div>
    <hr>
    <div class="center" style="font-size: 1rem;">
        Please contact Admin
    </div>
    <hr>
    <div class="center">
        <button mat-stroked-button (click)="onNoClick()">Back</button>
    </div>
</div>
