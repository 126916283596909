import { HttpErrorResponse } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { observable } from 'rxjs';
import { AddDiscountService } from 'src/app/Service/add-discount.service';
import { OrderByIdService } from 'src/app/Service/order-by-id.service';

@Component({
  selector: 'app-add-discount-dialog',
  templateUrl: './add-discount-dialog.component.html',
  styleUrls: ['./add-discount-dialog.component.css']
})
export class AddDiscountDialogComponent implements OnInit {

  choice="Amount"
  spinner=false
  form!:FormGroup
  displayedColumns: string[] = ['id','name','quantity','itemprice','totalprice','discount'];
  dataSource:any =[];
  orderCosts: any;
  constructor(public dialogRef: MatDialogRef<AddDiscountDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data:any,private fb:FormBuilder,
    private addDiscountService:AddDiscountService,
    private router:Router,private toastr:ToastrService
    ) { }

    ngOnInit(): void {
      
      this.getDiscount(this.data.id)
    //  this.orderCosts=this.data.order
      this.form = this.fb.group({
        discount:['',], 
        choice:['DEC',Validators.required]
      });
    }
    getDiscount(id: string){
      this.addDiscountService.getDiscount(id).subscribe(res=>{
       
       this.dataSource=res.itemListArray
       this.orderCosts=res.finalCosts  
      this.form.controls['discount'].setValue(res.orderDiscount.toString());
      this.form.controls['choice'].setValue(res.orderDiscountType);
    
      });
    }
submit(){
  const itemDiscounts= this.dataSource.map((s: { id: string;itemDiscount:string }) => ({itemId: s.id,itemDiscountValue:s.itemDiscount}));
  let obj:{orderDiscountValue:string,orderDiscountType:string,itemDiscounts:any}={
    'orderDiscountType': this.form.value.choice,
    'orderDiscountValue': this.form.value.discount,
    'itemDiscounts': itemDiscounts
  }
  if(this.form.valid&&((this.form.value.choice==='DEC')||(this.form.value.choice==='PERCENT'&&this.form.value.discount<this.orderCosts.grandTotal))){
    this.spinner=true
    this.addDiscountService.postAddDiscount(obj,this.data.id).subscribe(res=>{
      if(res.discountApplied === true)
      {
       this.toastr.success("SUCCESS", res.message)
       this.dialogRef.close(true);
      }
      else
      {
      this.toastr.error("ERROR",res.message)
      this.dialogRef.close(false);
      }
      console.log(res);
    

    },(err:HttpErrorResponse)=>{
      console.log("errorororororor");
        this.toastr.error("ERROR","Unable to add discount")
        this.dialogRef.close(false);
    })
  }
  else
  {
    this.toastr.error('ERROR','Enter Proper values');
    
  }
}
close(){
  this.dialogRef.close(false);

}
}
