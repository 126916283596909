import { LoginResponse } from './../../Models/loginResponse';
import { AuthService } from './../../Service/auth.service';
import { Component, Input, Output, EventEmitter, OnInit, ElementRef, ViewChild } from '@angular/core';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { NgxCaptchaModule, ReCaptcha2Component } from 'ngx-captcha';
import { DataService } from '../../Service/data.service';
import { ToastrService } from 'ngx-toastr';
import { environment } from 'src/environments/environment';
import { ForgotPasswordDialogComponent } from 'src/app/components/forgot-password-dialog/forgot-password-dialog.component';
import { MatDialog } from '@angular/material/dialog';

import { OrderListService } from 'src/app/Service/order-list.service';

@Component({
  selector: 'app-login-page',
  templateUrl: './login-page.component.html',
  styleUrls: ['./login-page.component.scss']
})
export class LoginPageComponent implements OnInit {

  hide = true;
  loading = false;
  form !: FormGroup
  public loginInvalid!: boolean;
  private formSubmitAttempt!: boolean;
  private returnUrl!: string;
  status!: String;
  isCheckedCaptch!: boolean;
  siteKey!: string;
  isRobort!: boolean;
  errorMessage: any;
  youtubeUrl: string = 'https://www.youtube.com/channel/UCqMifbRHpEGhRFyhk1kpRKg';
  linkedinUrl: string = 'https://www.linkedin.com/company/storimart-b2b/';
  facebookUrl: string = 'https://www.facebook.com/storimart';
  warehouseSettingsDetails: any;
  curDate!: string;
  ver!: number;


  constructor(
    
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private authService: AuthService,
    private dataService: DataService,
    private toastr: ToastrService,
    private dialog:MatDialog,
    
    private orderListService:OrderListService
  ) {

  }


  async ngOnInit() {

    this.curDate = new Date().getFullYear().toString().substr(-2) + '.' + (new Date().getMonth() + 1).toString();
    //  this.returnUrl = this.route.snapshot.queryParams.returnUrl || '/dashboard';
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'];
    this.form = this.fb.group({
      username: ['',Validators.required],
      password: ['', Validators.required]
      
    });
    
  }

  onSubmit() {
    this.loginInvalid = false;
    this.loading = true;
    this.formSubmitAttempt = false;
    if (this.form.valid) {
      sessionStorage.clear();
      sessionStorage.setItem('userName', this.form.get('username')?.value);
      sessionStorage.setItem('passWord', this.form.get('password')?.value);

      try {
        var obj = {
          "password": this.form.get('password')?.value,
          "username": this.form.get('username')?.value
        }
        this.authService.login(obj)
          .subscribe(async loginData => {
            if (loginData.body?.acknowledgment.status === "SUCCESS") {
              sessionStorage.setItem("AuthorizationToken", loginData.headers.get("Authorization")!)
              console.log(sessionStorage.getItem("retailers"));
              console.log(loginData.body);
              
              sessionStorage.setItem("CompanyName", loginData.body.warehouse.warehouseShopName)
              sessionStorage.setItem("OriginalCompany",loginData.body.companyName)
              sessionStorage.setItem("Logo",loginData.body.warehouse.warehouseLogo)
              sessionStorage.setItem("Address",JSON.stringify(loginData.body.warehouse.address))
              sessionStorage.setItem("WareHouseId",loginData.body.warehouse.warehouseShopId.toString())
              sessionStorage.setItem("isLogined", "true")
              console.log(sessionStorage.getItem('isLogined'));
              // this.dataService.sharedRetailerList=loginData.body.retailers
              // sessionStorage.setItem("retailers",JSON.stringify(loginData.body.retailers))
              // console.log(sessionStorage.getItem("retailers"));
              this.orderListService.periodicFetch()
              this.router.navigateByUrl('/live-orders')
              
            }
            else if(loginData.body?.acknowledgment.status==="ERRORED")
            {
              this.loginInvalid=true
              this.toastr.error(loginData.body?.acknowledgment.status,loginData.body?.acknowledgment.message);
              this.loading = false;
            }
          }, (err: HttpErrorResponse) => {
            console.log("errorororororor");

            if (err?.error?.acknowledgement?.status === "ERRORED") {
              this.errorMessage = err.error.acknowledgement.message
              this.loginInvalid = true;
              // recaptcha.resetCaptcha();
              // this.captchaElem.resetCaptcha();
              this.loading = false;
              this.toastr.error("ERROR", err.error.acknowledgement.message)
              console.log('error while logging in');
              
            }
            else
            {
              this.toastr.error("ERROR","Please try later")
              this.loading = false;
            }

          });
      }
      catch {
        this.loginInvalid = true;
      }
      return true
    }
    return false
  }

  openDialog(): void {
    const dialogRef = this.dialog.open(ForgotPasswordDialogComponent, {
      width: '600px',
      data: {}
    });
  }
}

