import { Injectable } from '@angular/core'
import { Router } from '@angular/router'
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpResponse, HttpErrorResponse, HttpHeaders } from '@angular/common/http'
import { Observable } from 'rxjs'
import { tap } from 'rxjs/operators' 
import { AuthService } from '../Service/auth.service'
@Injectable()
export class AppInterceptor implements HttpInterceptor {
  constructor(private authService: AuthService,  public router: Router) {
   
   }
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    console.log('inteceptor')
if( sessionStorage.getItem('AuthorizationToken')){    
    req = req.clone({
      headers:new HttpHeaders({'Authorization':sessionStorage.getItem('AuthorizationToken')!})
    })
}
    return next.handle(req).pipe(tap((event: HttpEvent<any>) => {
      if (event instanceof HttpResponse) {
        const token = event.headers.get('Authorization')
        if (token && token.length) {
          this.authService.updateToken(token)
        }
      }
    }, (err: any) => {
      if (err instanceof HttpErrorResponse) { 
        console.log('inteceptor')
          if (err.status === 401 ) {
          // this.appService.signOut()
    // this.authService.logout()
          this.router.navigate(['/login'])
        }
      }
    }))
  }
}
