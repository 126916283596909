import { Component, OnInit,Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';


@Component({
  selector: 'app-reject-dialog',
  templateUrl: './reject-dialog.component.html',
  styleUrls: ['./reject-dialog.component.css']
})
export class RejectDialogComponent implements OnInit {

  form: FormGroup = new FormGroup({});

  constructor(public dialogRef: MatDialogRef<RejectDialogComponent>,
    private fb: FormBuilder) {
      this.form = fb.group({
        note: ['', [Validators.required]],
      })
     }
   
  


  ngOnInit(): void {
  }
  onConfirm(): void {
    if(this.form.valid)
    this.dialogRef.close(this.form.controls['note'].value);
  }

onDismiss(): void {
   
    this.dialogRef.close();
}

}
