import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Order } from 'src/app/Models/orderList';
import { DataService } from 'src/app/Service/data.service';
import { OrderByIdService } from 'src/app/Service/order-by-id.service';
import { ProcessOrderService } from 'src/app/Service/process-order.service';

@Component({
  selector: 'app-edit-order-page',
  templateUrl: './edit-order-page.component.html',
  styleUrls: ['./edit-order-page.component.css']
})
export class EditOrderPageComponent implements OnInit {

  order!:Order;
  orders!:Order[]
  backRoute=""
  constructor(private dataService:DataService,private route:ActivatedRoute,private orderByIdService :OrderByIdService,public dialog:MatDialog,@Inject(MAT_DIALOG_DATA) public data:any,private router:Router,private toastr:ToastrService,) 
  {
    const param=this.route.snapshot.queryParamMap;
    this.orders=JSON.parse(sessionStorage.getItem('liveOrders')!)
    // this.toDetails(param.get('orderId')!);
    this.toDetails(data.orderId!);
    // this.backRoute=param.get('from')!
    console.log(this.backRoute);
   
  }

  toDetails(id:string){
    this.orderByIdService.getOrderlist(id)
    .subscribe(
      data => {
      if(data.acknowledgement.status === "SUCCESS")
      {
      this.order = data.order[0]
      }
    })
    //this.order=this.dataService.getOrderById(id)!;
  }
  toNumber=(number:any)=>Number(number)
  getBack(){
    this.router.navigateByUrl(this.backRoute)
  }
  ngOnInit(): void {
  }

}
