import { DatePipe } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { Inject } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AddPaymentModel } from 'src/app/Models/addPaymentModel';
import { AddPaymentService } from 'src/app/Service/add-payment.service';

@Component({
  selector: 'app-add-payment-dialog',
  templateUrl: './add-payment-dialog.component.html',
  styleUrls: ['./add-payment-dialog.component.css']
})
export class AddPaymentDialogComponent implements OnInit {

  form!:FormGroup
  spinner=false
  retailerBalance:any=""

  paymentModes=['Cash On Delivery', 'Cheque', 'Demand Draft', 'Online']
  constructor(public dialogRef: MatDialogRef<AddPaymentDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data:any,private fb:FormBuilder,
    private addPaymentService:AddPaymentService,private datePipe:DatePipe,
    private router:Router,private toastr:ToastrService) { 
      this.retailerBalance=data.accountBalance
    
    }

  ngOnInit(): void {
    this.form = this.fb.group({
      paidAmount: ['',[Validators.required, Validators.min(0)]],
      paidDate: [new Date(), Validators.required],
      paymentMode:['',Validators.required],
      referenceNumber:[''],
      paymentNote:['']
    });
  }

  onNoClick(): void {
    this.dialogRef.close(false);
  }
  submit=()=>{
    this.spinner=true
    var paymentObj:AddPaymentModel={
      orderId:this.data.orderId,
      shopId:this.data.shopId,
      retailerId:this.data.retailerId,
      paidAmount:this.form.value.paidAmount.toString(),
      paidDate:this.datePipe.transform(this.form.value.paidDate , 'dd/MM/yyyy')?.toString()!,
      paymentModeId:this.form.value.paymentMode.toString(),
      paymentNote:this.form.value.paymentNote,
      refNum:this.form.value.referenceNumber

    }
    
    this.addPaymentService.addPayment(paymentObj).subscribe(res=>{
      

      if(res.status==="SUCCESS")
      {
        this.toastr.success("SUCCESS",res.message)
    this.dialogRef.close(true);
      }
      else
      this.toastr.error("ERROR",res.message)

    },(err:HttpErrorResponse)=>{
      console.log("errorororororor");
        this.toastr.error("ERROR","Unable to add payment")
        this.dialogRef.close(false);
    }
    )
    
  }
  myFilter1 = (d: Date | null): boolean => {
    const time = (d || new Date()).getTime();
    const today=new Date(Date.now()) 
    return (time<today.getTime());
  }
}
