import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpHeaders, HttpBackend,HttpParams } from '@angular/common/http';
import {ToastrService} from 'ngx-toastr'
import { environment } from 'src/environments/environment';
import { OrderList } from '../Models/orderList';

@Injectable({
  providedIn: 'root'
})
export class OrderByIdService {
  // private http!:HttpClient;
  baseUrl=environment.serviceURL;
  httpOptions={
      headers:new HttpHeaders({'Authorization':sessionStorage.getItem('AuthorizationToken')!})
  }
constructor(
    handler:HttpBackend,
    private toastr:ToastrService,private httpclient: HttpClient
) 
{
    // this.http=new HttpClient(handler);
 }
 getOrderlist=(id:string)=>{
  this.httpOptions={
    headers:new HttpHeaders({'Authorization':sessionStorage.getItem('AuthorizationToken')!})
}
   return this.httpclient
 .get<OrderList>(this.baseUrl+'order/'+id+'/',this.httpOptions);
}

downloadInvoice=(id:string,type:string)=>{
  const headers = new HttpHeaders({ 'Content-Type': 'application/json',
  "Authorization":sessionStorage.getItem('AuthorizationToken')!, responseType : 'blob'});
  let queryParams = new HttpParams().append("type",type);
 
return this.httpclient
 .get<Blob>(this.baseUrl+'order/'+id+'/pdf',{ headers : headers,responseType : 
  'blob' as 'json',params:queryParams});
}

}
