<div class="example-container">
    <button mat-icon-button class="close-button" mat-dialog-close>
        <mat-icon class="close-icon" color="warn">close</mat-icon>
      </button>
    <div>{{data.title}}</div> 
    
    <div  mat-dialog-actions align="center">
        <button mat-raised-button color="primary" (click)="onDismiss()">{{data.btn1}}</button>
        <button mat-raised-button color="accent" (click)="onConfirm()">{{data.btn2}}</button>
    </div>

</div>

