import { HttpBackend, HttpClient, HttpHeaders,HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ReportService {

  private http!:HttpClient;
baseUrl=environment.serviceURL;
httpOptions={
  headers:new HttpHeaders({'Authorization':sessionStorage.getItem('AuthorizationToken')!})
}
  constructor(handler:HttpBackend,
    private toastr:ToastrService) 
    {
      this.http=new HttpClient(handler);
     }

     downloadOrderReport=(type:string, orderId: string, retailerName: string, fromDate: string, toDate: string, orderStatus: string, groupId: string):Observable<any>=>{
      let httpOptions={
        headers:new HttpHeaders({'Authorization':sessionStorage.getItem('AuthorizationToken')!}),
        params: new HttpParams().set('type',type)
        .set('orderId', orderId)
        .set('retailerName', retailerName)
        .set('fromDate', fromDate)
        .set('toDate', toDate)
        .set('orderStatus', orderStatus)
        .set('groupId', groupId)
          
    }
      return this.http.get<any>(this.baseUrl+"downloadOrderReport",httpOptions);
    } 
    
    downloadPaymentReport=(type:string):Observable<any>=>{
      let httpOptions={
        headers:new HttpHeaders({'Authorization':sessionStorage.getItem('AuthorizationToken')!}),
        params: new HttpParams().set('type',type)
    }
      return this.http.get<any>(this.baseUrl+"downloadPaymentReport",httpOptions);
    }   
}
