import { SelectionModel } from '@angular/cdk/collections';
import { DatePipe } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { AfterViewInit, Component, ViewChild , ChangeDetectorRef, OnDestroy, OnInit} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Observable, Subject, Subscriber, Subscription } from 'rxjs';
import { Order, OrderList } from 'src/app/Models/orderList';
import { DataService } from 'src/app/Service/data.service';
import { OrderByIdService } from 'src/app/Service/order-by-id.service';
import { OrderListService } from 'src/app/Service/order-list.service';
import { PaymentListService } from 'src/app/Service/payment-list.service';
import { ProcessOrderService } from 'src/app/Service/process-order.service';
import { OrderDetailsComponent } from '../order-details/order-details.component';
import * as moment from 'moment';
import { EditOrderPageComponent } from 'src/app/pages/edit-order-page/edit-order-page.component';
import { AddDiscountDialogComponent } from '../add-discount-dialog/add-discount-dialog.component';
import { ReportService } from 'src/app/Service/report.service';
import { SearchOrdersService } from 'src/app/Service/search-orders.service';
import { AddPaymentDialogComponent } from '../add-payment-dialog/add-payment-dialog.component';
import { ProFormaPageComponent } from 'src/app/pages/pro-forma-page/pro-forma-page.component';
import { UpdateLivePriceComponent } from '../update-live-price/update-live-price.component';
import { DeliverySlipComponent } from '../delivery-slip/delivery-slip.component';
import { OrderService } from 'src/app/Service/order.service';
import { NgxPaginationModule } from 'ngx-pagination';
import { RejectDialogComponent } from 'src/app/shared/reject-dialog/reject-dialog.component';
import { EditOrderTableComponent } from '../edit-order-table/edit-order-table.component';
import { error } from '@angular/compiler/src/util';
import { map, startWith } from 'rxjs/operators';
import { ConfirmDialogComponent } from 'src/app/shared/components/confirm-dialog/confirm-dialog.component';
import { ThrowStmt } from '@angular/compiler';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { MatSelect } from '@angular/material/select';
import { MatOption } from '@angular/material/core';





@Component({
  selector: 'app-ofs-table',
  templateUrl: './ofs-table.component.html',
  styleUrls: ['./ofs-table.component.css']
})
export class OfsTableComponent implements OnInit {
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatPaginator) paginatorSearch!: MatPaginator;
  @ViewChild(MatTable) table!: MatTable<any>;
  @ViewChild('select') select!: MatSelect;

  
  
  disabled=false;
  disabled1=true;
  filteredData:any;
  searchID=""
  statusString="ALL"
  orderList!:OrderList
  tableData:Order[]=[];
  startDate!:Date | null;
  endDate!:Date | null;
  dataSource:any;
  statuses=['ALL', 'Submitted', 'Accepted', 'Shipped','Pending'] 
  currency:any;
  selectedBuyerGroup: any = [];
  selection = new SelectionModel<Order>(true, []);
  subscription!:Subscription

  /** Columns displayed in the table. Columns IDs can be added, removed, or reordered. */
  displayedColumns = ['select', 'orderId', 'buyerName', 'buyerGroup','submittedUserName','submittedDate', 'estimatedDate','amount','status','financialStatus','location','actions']
 
  
  blob: Blob | undefined;
  searchForm!: FormGroup;
  isError: boolean = false;
  ifExport: boolean = false;
  orderId:string="";
  totalOrders=0;
  page:any;
  searchDataSource:any;
  isSearch:boolean=false;
  totalSearchOrders: any;
  orderIdArray: number[]=[];
  orderIdArrayForDeliverySlip : string[]=[];
  noProperDate:boolean=false;
  retailerName:string="";
  filteredOptions!: Observable<string[]> 
  retailerList=[]
  RetailersList:any = []
  buyerGroup: any;
  disabledFields: boolean = false;
  disabledOrderIdFields: boolean = false;

  allSelected=false;
  private readonly fetchRetailerSubject = new Subject<any>();

  
  constructor(private searchOrdersService:SearchOrdersService,private ordersService:OrderService, private cdr:ChangeDetectorRef,private reportService:ReportService,private router:Router,private orderByIdService:OrderByIdService,public date:DatePipe,private changeDetectorRefs: ChangeDetectorRef,private orderListService:OrderListService, private dataService:DataService, private processOrderService:ProcessOrderService, private toastr:ToastrService,private paymentListService:PaymentListService,private dialog:MatDialog) {
    
    
   
      this.ordersService.getOrders("live","25","0")
      .subscribe(data=>{
        if(data)
        {
          this.dataSource=new MatTableDataSource(data.order);
          this.cdr.detectChanges();
          this.dataSource.paginator=this.paginator;
          this.totalOrders = data.totalOrderCount
          this.disabled1=false
        }
        else
        this.toastr.error("ERROR","Couldn't fetch orders")
        
      },(error:HttpErrorResponse)=>{
        console.log("errorororororor");
        this.disabled1=false
        if (error instanceof HttpErrorResponse) {
          if (error.error instanceof ErrorEvent) {
            console.error("Error Event");
          } else {
            console.log(`error status : ${error.status} ${error.statusText}`);
            switch (error.status) {
              case 401:      //login
                this.router.navigateByUrl("/login");
                console.log(`redirect to login`);
                this.toastr.error("Session Expired, please login again")
                break;
              
            }
          }
        }
      })

          
    }    

    pageChanged(event:any){
      this.isSearch=false;
      this.disabled1=true;
      this.page = event;
      this.ordersService.getOrders("live",event.pageSize.toString(), (event.pageIndex * event.pageSize).toString())
      .subscribe(data=>{
        if(data)
        {
          this.dataSource=new MatTableDataSource(data.order);
          this.cdr.detectChanges();
          /* this.dataSource.paginator=this.paginator;
          this.totalOrders = data.totalOrderCount */
          this.disabled1=false
        }
        
      },(error:HttpErrorResponse)=>{
        console.log("errorororororor");
        this.disabled1=false
        if (error instanceof HttpErrorResponse) {
          if (error.error instanceof ErrorEvent) {
            console.error("Error Event");
          } else {
            console.log(`error status : ${error.status} ${error.statusText}`);
            switch (error.status) {
              case 401:      //login
                this.router.navigateByUrl("/login");
                console.log(`redirect to login`);
                this.toastr.error("Session Expired, please login again")
                break;
              
            }
          }
        }
      })
  }
  
  pageChangedSearch(event:any){
    this.isSearch=true;
    this.disabled1=true;
    this.page = event;
    this.searchOrdersService.searchOrder(this.date.transform(this.startDate,'dd/MM/yyyy')!,this.date.transform(this.endDate,'dd/MM/yyyy')!,this.statusString, this.retailerName,this.orderId,this.selectedBuyerGroup,"live",event.pageSize.toString(), (event.pageIndex * event.pageSize).toString()).subscribe(res=>{
      // this.searchOrdersService.searchOrder(params).subscribe(res=>{
        // this.isSearch=true;
  
    /* console.log(res);
   this.orderId="" */
    this.disabled1=false;
    this.disabled =false;
    this.searchDataSource=new MatTableDataSource(res.order)
    this.cdr.detectChanges();
    // this.totalSearchOrders = res.totalOrderCount;
    // this.searchDataSource.paginator=this.paginatorSearch;
    
   
  },(error:any)=>{
    console.log("errorororororor");
    
   this.disabled=false;
      this.isSearch=false;
      if (error instanceof HttpErrorResponse) {
        if (error.error instanceof ErrorEvent) {
          console.error("Error Event");
        } else {
          console.log(`error status : ${error.status} ${error.statusText}`);
          switch (error.status) {
            case 401:      //login
              this.router.navigateByUrl("/login");
              console.log(`redirect to login`);
              this.toastr.error("Session Expired, please login again")
              break;
            
          }
        }
      }  
  })
}

  applyFilter(event:Event) {
    const filterValue = (event.target as HTMLInputElement).value;
  this.dataSource.filter = filterValue.trim();
  }

  formatDate(date:string){
    var time=date.replace("T", " ")
    return moment(time).format('DD MMM YYYY');
  }

  reset(){
    this.resetForm()
    this.disabledFields = false;
    this.disabledOrderIdFields = false;
    this.selection.clear();
    this.isSearch=false;
    this.retailerName="";
    this.searchForm.reset();
    this.orderId=''
    this.searchID = ''
    this.statusString = ''
    this.selectedBuyerGroup = ''
    this.startDate = null
    this.endDate = null
    this.fetch_BuyerGroups('')
    this.fetch_retailer(0,0,'')
    this.filteredOptions = this.searchForm.controls.myControl.valueChanges
    .pipe(
      startWith(''),
      map(value => this._filter(value))
    );
  }

  resetForm() {
    this.searchForm = new FormGroup({ 
      startDate: new FormControl(),
      endDate: new FormControl(),
      orderId: new FormControl(),
      myControl: new FormControl(),
      status: new FormControl(),
      buyerGroup: new FormControl([])
     
  })

  }

  ngOnInit(){
    this.fetch_retailer(0,0,'')
    this.fetch_BuyerGroups('')

    this.searchForm = new FormGroup({ 
      startDate: new FormControl(),
      endDate: new FormControl(),
      orderId: new FormControl(),
      myControl: new FormControl(),
      status: new FormControl(),
      buyerGroup: new FormControl([])
     
  })

  // this.retailerList = retailers.map((datum: { retailerName: any; }) => datum.retailerName);
  //   console.log(this.retailerList)

  this.filteredOptions = this.searchForm.controls.myControl.valueChanges
  .pipe(
    startWith(''),
    map(value => this._filter(value))
  );
  
}

clearFormdata() {
  this.searchForm = new FormGroup({ 
    startDate: new FormControl(''),
    endDate: new FormControl(''),
    // orderId: new FormControl(),
    myControl: new FormControl(''),
    status: new FormControl(''),
    buyerGroup: new FormControl([])
   
})
}

// async fetch_retailer(limit:number,offset:number, groupId:any){
//   await this.ordersService.getRetailersList(limit,offset,groupId).subscribe({
//     next: (response) => {
//       console.log('retailes list', response)
//       this.dataService.sharedRetailerList=response.retailers
          
//           this.RetailersList=response.retailers
//           console.log( this.RetailersList.length)
//           this.retailerList = this.RetailersList.map((datum: { retailerName: any; }) => datum.retailerName);
//           this.filteredOptions = this.searchForm.controls.myControl.valueChanges
//           .pipe(
//             startWith(''),
//             map(value => this._filter(value))
//           );
//     }
//   })
// }

async fetch_retailer(limit: number, offset: number, groupId: any) {
  // Cancel the previous subscription if any
  if (this.subscription) {
    this.subscription.unsubscribe();
  }

  this.subscription = this.ordersService.getRetailersList(limit, offset, groupId).subscribe({
    next: (response) => {
      console.log('retailers list', response);
      this.dataService.sharedRetailerList = response.retailers;
      this.RetailersList = response.retailers;
      console.log(this.RetailersList.length);
      this.retailerList = this.RetailersList.map((datum: { retailerName: any }) => datum.retailerName);
      this.filteredOptions = this.searchForm.controls.myControl.valueChanges.pipe(
        startWith(''),
        map((value) => this._filter(value))
      );
    },
  });
}

fetch_BuyerGroups(retailer:string) {
  this.ordersService.getBuyerGroups(retailer).subscribe({
    next: (response) => {
      console.log('buyers group',response)
      if (response.status === 'SUCCESS') {
          this.buyerGroup = response.data
          // this.buyerGroup = response.data.map((group: any) => ({ ...group, isCheck: false }));
          // console.log('this.buyerGroup',this.buyerGroup)
      }
    },
  })
}

private _filter(value: string): string[] {
 
  const filterValue = value?.toLowerCase();

  return this.retailerList.filter((retailer:any) => retailer.toLowerCase().includes(filterValue));
}

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource?.data.length;
    return numSelected === numRows;
  }

  isSomeSelected() {
    
    return this.selection.selected.length > 0;
  }

  isAllSelected1() {
    const numSelected = this.selection.selected.length;
    const numRows = this.searchDataSource?.data.length;
    return numSelected === numRows;
  }


processOrder(){
  this.orderIdArray=[]
  
  if(this.selection.selected.length)
  {
    
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      maxWidth: "400px",
      data: {
          title: "Confirm Process Orders? This action cannot be undone.",
          btn1: "No",
          btn2: "Yes"
         }
    });
    dialogRef.afterClosed().subscribe((result: boolean) => {
      console.log('The dialog was closed');
      console.log(result);//returns undefined
     
    if(result === true)
    {

    this.disabled=true

    
     
    console.log(this.selection.selected);
    for(let x of this.selection.selected)
     { /* let action=""
      if(x.status.value==="Submitted")
      action="ACCEPT"
      else if(x.status.value==="Accepted")
      action="SHIP"
      else if(x.status.value==="Shipped")
      action="DELIVER" */
      this.orderIdArray.push(Number(x.orderId))
  }
      if(this.orderIdArray){

      var orderIdObj={
        orderIds:this.orderIdArray
      }
      this.processOrderService.bulkProcess(orderIdObj).subscribe(
        res=>{
        
          this.selection.clear();
          if(res.orderArray[0]?.isSuccess === true){  
           this.toastr.success("SUCCESS",res.message)
            if(this.page) {
            this.ordersService.getOrders("live",this.page.pageSize.toString(), (this.page.pageIndex * this.page.pageSize).toString())
      .subscribe(data=>{
        if(data)
        {
          this.dataSource=new MatTableDataSource(data.order);
          this.cdr.detectChanges();
        
          this.disabled=false;
        }
        
      },(error:HttpErrorResponse)=>{
        console.log("errorororororor");
        this.disabled=false
        if (error instanceof HttpErrorResponse) {
          if (error.error instanceof ErrorEvent) {
            console.error("Error Event");
          } else {
            console.log(`error status : ${error.status} ${error.statusText}`);
            switch (error.status) {
              case 401:      //login
                this.router.navigateByUrl("/login");
                console.log(`redirect to login`);
                this.toastr.error("Session Expired, please login again")
                break;
              
            }
          }
        }
      })
            
          }
          else{
            this.ordersService.getOrders("live","25","0")
      .subscribe(data=>{
        if(data)
        {
          this.dataSource=new MatTableDataSource(data.order);
          this.cdr.detectChanges();
          
          this.disabled=false;
        }
        
      },(error:HttpErrorResponse)=>{
        console.log("errorororororor");
        this.disabled=false
        if (error instanceof HttpErrorResponse) {
          if (error.error instanceof ErrorEvent) {
            console.error("Error Event");
          } else {
            console.log(`error status : ${error.status} ${error.statusText}`);
            switch (error.status) {
              case 401:      //login
                this.router.navigateByUrl("/login");
                console.log(`redirect to login`);
                this.toastr.error("Session Expired, please login again")
                break;
              
            }
          }
        }
      })
          }
        }
        else
        {
        this.toastr.error(res.message)
        this.disabled=false;
        }
        })

    }
  }
  else{
    this.disabled=false;
    this.selection.clear();
    return;
  }
})
    }
    else
    {
      this.disabled=false;
    this.toastr.info("Please select the orders to process")
    }
  
}


  masterToggle() {
    if (this.isAllSelected()) {
      this.selection.clear();
      return;
    }
    this.selection.select(...this.dataSource.data);
  }

  masterToggle1(){
    if (this.isAllSelected1()) {
      this.selection.clear();
      return;
    }
    this.selection.select(...this.searchDataSource.data);
  }

  processOrderById(row:any,orderId:string,action:string){
    var reason=""
    this.disabled1=true;
    if(orderId && action){
      if(action === 'REJECT'){

        const dialogRef = this.dialog.open(RejectDialogComponent, {
          width: '400px',
        });
        
        dialogRef.afterClosed().subscribe(result => {
         
          if(result){
            reason = result;
            this.processOrderService.processOrder(orderId,action,reason).subscribe(
              res=>{
              
                if(res.acknowledgement.status === "SUCCESS"){
                  this.toastr.success("SUCCESS",res.acknowledgement.message)
                  if(this.page) {  
                  this.ordersService.getOrders("live",this.page.pageSize.toString(), (this.page.pageIndex * this.page.pageSize).toString())
            .subscribe(data=>{
              if(data)
              {
                this.dataSource=new MatTableDataSource(data.order);
                this.cdr.detectChanges();
                
                this.disabled1=false
              }
              
            },(error:HttpErrorResponse)=>{
              console.log("errorororororor");
              this.disabled1=false
              if (error instanceof HttpErrorResponse) {
                if (error.error instanceof ErrorEvent) {
                  console.error("Error Event");
                } else {
                  console.log(`error status : ${error.status} ${error.statusText}`);
                  switch (error.status) {
                    case 401:      //login
                      this.router.navigateByUrl("/login");
                      console.log(`redirect to login`);
                      this.toastr.error("Session Expired, please login again")
                      break;
                    
                  }
                }
              }
            })
          }
          else{
            this.ordersService.getOrders("live","25","0")
            .subscribe(data=>{
              if(data)
              {
                this.dataSource=new MatTableDataSource(data.order);
                this.cdr.detectChanges();
               
                this.disabled1=false
              }
              
            },(error:HttpErrorResponse)=>{
              console.log("errorororororor");
              this.disabled1=false
              if (error instanceof HttpErrorResponse) {
                if (error.error instanceof ErrorEvent) {
                  console.error("Error Event");
                } else {
                  console.log(`error status : ${error.status} ${error.statusText}`);
                  switch (error.status) {
                    case 401:      //login
                      this.router.navigateByUrl("/login");
                      console.log(`redirect to login`);
                      this.toastr.error("Session Expired, please login again")
                      break;
                    
                  }
                }
              }
            })
      
          }
                  
                }
                else
                {
                  this.disabled1=false
                  this.toastr.error("ERROR",res.acknowledgement.message)
                }
               

              })
            
              
          
          }
          else {
            this.disabled1=false
          return;
          }
          })
        
        
      }
      else{

        const dialogRef = this.dialog.open(ConfirmDialogComponent, {
          maxWidth: "400px",
          data: {
              title: "Confirm Process Order? This action cannot be undone.",
              btn1: "No",
              btn2: "Yes"
             }
        });
        dialogRef.afterClosed().subscribe((result: boolean) => {
          console.log('The dialog was closed');
          console.log(result);//returns undefined
         
        if(result === true)
        {
      this.processOrderService.processOrder(orderId,action,reason).subscribe(
        res=>{
        
          if(res.acknowledgement.status === "SUCCESS"){
            this.toastr.success("SUCCESS",res.acknowledgement.message)
            if(this.isSearch === true){
              this.disabled1=false;
              this.search();
            }
            else{
            if(this.page) {  
            this.ordersService.getOrders("live",this.page.pageSize.toString(), (this.page.pageIndex * this.page.pageSize).toString())
      .subscribe(data=>{
        if(data)
        {
          this.dataSource=new MatTableDataSource(data.order);
          this.cdr.detectChanges();
         
          this.disabled1=false
        }
        
      },(error:HttpErrorResponse)=>{
        console.log("errorororororor");
        if (error instanceof HttpErrorResponse) {
          if (error.error instanceof ErrorEvent) {
            console.error("Error Event");
          } else {
            console.log(`error status : ${error.status} ${error.statusText}`);
            switch (error.status) {
              case 401:      //login
                this.router.navigateByUrl("/login");
                console.log(`redirect to login`);
                this.toastr.error("Session Expired, please login again")
                break;
              
            }
          }
        }
      })
    }
    else{
      this.ordersService.getOrders("live","25","0")
      .subscribe(data=>{
        if(data)
        {
          this.dataSource=new MatTableDataSource(data.order);
          this.cdr.detectChanges();
         
          this.disabled1=false
        }
        
      },(error:HttpErrorResponse)=>{
        console.log("errorororororor");
        this.disabled1=false
        if (error instanceof HttpErrorResponse) {
          if (error.error instanceof ErrorEvent) {
            console.error("Error Event");
          } else {
            console.log(`error status : ${error.status} ${error.statusText}`);
            switch (error.status) {
              case 401:      //login
                this.router.navigateByUrl("/login");
                console.log(`redirect to login`);
                this.toastr.error("Session Expired, please login again")
                break;
              
            }
          }
        }
      })
    }
   
  }
    }

    else
    {
      this.disabled1=false
    this.toastr.error("ERROR",res.acknowledgement.message) 
    }
  })  
      
     
  }
  else
  {this.disabled1=false;
    return;  }         
});

    }  
   
    }  

  }

  checkboxLabel(row?: Order): string {
    if (!row) {
      return `${this.isAllSelected() ? 'deselect' : 'select'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.orderId}`;
  }

  hasRoute(route:string){
    return this.router.url==route;
  }
  statusColour(status:any){
    if(status === 'Submitted')
      return '#5094e3'
  else if(status === 'Accepted')
    return '#D27D2D'
  else if(status === 'Shipped')
    return '#e563a2'
  else if((status ==='Rejected')||(status === 'Cancelled'))
    return '#ca3433'
  else if(status==='Pending')
  return '#FDDA0D'
  else  if(status==='Delivered')
    return '#006400'
  else
   return '#000'
    
  }

   toProForma(row:any){
    const dialogRef = this.dialog.open(ProFormaPageComponent, {
      width:'900px',
      data: row.orderId
    } )
  } 

  downloadOrderReport(){
   
    this.isError=false;
    this.ifExport=true
        this.reportService.downloadOrderReport("live",this.orderId,this.retailerName,this.date.transform(this.startDate,'dd/MM/yyyy')!,this.date.transform(this.endDate,'dd/MM/yyyy')!,this.statusString, this.selectedBuyerGroup).subscribe(
          (          res: { fileName: string; fileUrl: string; message: string;}) => {
            this.ifExport=false
            // this.toastr.success()

            if(res.fileName)
           { 
             //window.open(res.fileUrl)
            // window.location.href =res.fileUrl;
            //var downloadURL = window.URL.createObjectURL(res.fileName);
            
            const link = document.createElement('a');
            link.setAttribute('target', '_self');
            link.setAttribute('href', res.fileUrl);
            link.setAttribute('download',res.fileName);
            document.body.appendChild(link);
            link.click();
            link.remove(); 
            this.toastr.success(res.message)

           }
            else
            this.toastr.error(res.message)
            
          },
          (err: HttpErrorResponse) => {
            if (err) {
              this.ifExport=false
              this.toastr.error(err.error.message, "ERROR")
            }
  
  
          }
        );
      

    
  }

  buyer(event:any){

    this.retailerName= event.value;
  }


  search(){
    
    this.selection.clear();
    this.noProperDate=false;
    if(this.startDate && !this.endDate)
    {
    this.toastr.error("Please Enter End Date")
    this.noProperDate=true;
    return;
    }
    if(!this.startDate && this.endDate)
    {
    this.toastr.error("Please Enter start Date")
    this.noProperDate=true
    return;
    } 
    
    if(!this.noProperDate)
    {
      this.disabled =true;
      if (this.orderId) {
        this.startDate = null,
        this.endDate = null,
        this.statusString = '', 
        this.retailerName = '',
        this.selectedBuyerGroup = ''
      }
      this.searchOrdersService.searchOrder(this.date.transform(this.startDate,'dd/MM/yyyy')!,this.date.transform(this.endDate,'dd/MM/yyyy')!,this.statusString, this.retailerName,this.orderId,this.selectedBuyerGroup,"live","25","0").subscribe(res=>{
        // this.searchOrdersService.searchOrder(params).subscribe(res=>{
    
      /* console.log(res);
     this.orderId="" */
      if (res) {
        this.disabled =false;
        this.totalSearchOrders = res.totalOrderCount;
      this.searchDataSource=new MatTableDataSource(res.order)
      // this.cdr.detectChanges();
      // this.searchDataSource.paginator=this.paginatorSearch;
      }
      this.isSearch=true;

    },(error:any)=>{
      console.log("errorororororor");

     this.disabled=false;
        if (error instanceof HttpErrorResponse) {
          this.isSearch=false;
          if (error.error instanceof ErrorEvent) {
            console.error("Error Event");
          } else {
            console.log(`error status : ${error.status} ${error.statusText}`);
            switch (error.status) {
              case 401:      //login
                this.router.navigateByUrl("/login");
                console.log(`redirect to login`);
                this.toastr.error("Session Expired, please login again")
                break;
              
            }
          }
        }  
    })
  } 
  }
  myFilter1 = (d: Date | null): boolean => {
    const time = (d || new Date()).getTime();
    const today=new Date(Date.now()) 
    return this.endDate?((time<this.endDate.getTime())&&(time<today.getTime())):(time<today.getTime());
  }

  myFilter2 = (d: Date | null): boolean => {
    const time = (d || new Date()).getTime();
    const today=new Date(Date.now()) 
    return this.startDate?((time>this.startDate.getTime())&&(time<today.getTime())):(time<today.getTime());
  }

/*   filterSearch(searchID:string){
    this.searchID=searchID;
    if(!(this.startDate||this.endDate))
    {
      this.filteredData=this.tableData
      .filter(x=>(x.orderId.includes(searchID))&&x.status.value.includes(this.statusString))
      
      
    }
    else if((!this.endDate))
    {
      this.filteredData=this.tableData
      .filter(x=>{
        let xdate=new Date(x.deliveryTime);
        return (x.orderId.includes(searchID))&&x.status.value.includes(this.statusString)&&xdate.getTime()>=this.startDate.getTime()})
    }
    else if((!this.startDate))
    {
      this.filteredData=this.tableData
      .filter(x=>{
        let xdate=new Date(x.deliveryTime);
        return (x.orderId.includes(searchID))&&x.status.value.includes(this.statusString)&&xdate.getTime()<this.endDate.getTime()})
    }
    else
    {
      let endDate2=new Date(this.endDate.setDate(this.endDate.getDate()+1))
      
      
      this.filteredData=this.tableData
      .filter(x=>{
        let xdate=new Date(x.deliveryTime);
        return (x.orderId.includes(searchID))&&x.status.value.includes(this.statusString)&&xdate.getTime()>=this.startDate.getTime()&&xdate.getTime()<this.endDate.getTime()})
    }
    this.dataSource=new MatTableDataSource<Order>(this.filteredData);
    this.dataSource.paginator=this.paginator;
    this.dataSource.sort=this.sort;
  } */
  logStartDate(event:any)
  {
    if (event.value == ''){
      this.disabledOrderIdFields = false;
    }
    else {
    this.disabledOrderIdFields = true;
    this.startDate=event.value;
    //this.filterSearch(this.searchID)
    }
  }
  logEndDate(event:any)
  {
    if (event.value == ''){
      this.disabledOrderIdFields = false;
    }
    else {
    this.disabledOrderIdFields = true;
    this.endDate=event.value
    //this.filterSearch(this.searchID)
    }
  }
  clearStartDate(event: any) {
    if (event.target.value == '') {
      this.startDate = null
    }
  }
  clearEndDate(event: any) {
    if (event.target.value == '') {
      this.endDate = null
    }
  }
  disableOrederId(event:any) {
  if (event.target.value == ''){
    this.disabledOrderIdFields = false;
    this.retailerName = ''
    this.fetch_BuyerGroups('')
  }
  else {
    this.disabledOrderIdFields = true;
  }
}
buyerSelect(retailer: any) {
  this.fetch_BuyerGroups(retailer)
}

saveID(event:any){
  if (event.target.value == ''){
    this.orderId = ''
    this.disabledFields = false;
  }
  else {
    this.disabledFields = true;
    this.clearFormdata();
    this.orderId=event.target.value;
  }
 // this.filterSearch(event.target.value);
}
status(event:any){
  if (event.value == ''){
    this.disabledOrderIdFields = false;
    this.statusString = ''
  }
  else {
  this.disabledOrderIdFields = true;
  this.statusString=event.value;
  //this.filterSearch(this.searchID);
  }
}

// buyerGroups(event:any){
//   if (event.value == ''){
//     this.disabledOrderIdFields = false;
//     this.selectedBuyerGroup = []
//   }
//   else if (event.value == null){
//     this.disabledOrderIdFields = false;
//     this.selectedBuyerGroup = []
//   }
//   else if (event.value.includes('All')) {
//     this.disabledOrderIdFields = true;
//     this.selectedBuyerGroup = this.buyerGroup.map((group: any) => group.id);
//     this.buyerGroup.forEach((group: { isCheck: any; id: any; }) => {
//       group.isCheck = this.selectedBuyerGroup.includes(group.id);
//     });
//     console.log('All is checked true', this.buyerGroup)

//   } 
//   else {
//     this.disabledOrderIdFields = true;
//     this.selectedBuyerGroup = event.value.map((id: any) => id );  
//     this.buyerGroup.forEach((group: { isCheck: any; id: any; }) => {
//       group.isCheck = this.selectedBuyerGroup.includes(group.id);
//     });
//     console.log('is checked true', this.buyerGroup)
//   }

// }

selectAllBuyerGroup() {
  if (this.allSelected) {
    this.select.options.forEach((item: MatOption) => item.select());
    this.disabledOrderIdFields = true;
    this.selectedBuyerGroup = this.buyerGroup.map((group: any) => group.id);
    console.log('ALL selectedBuyerGroup', this.selectedBuyerGroup)
  } else {
    this.select.options.forEach((item: MatOption) => item.deselect());
    this.selectedBuyerGroup = this.buyerGroup.map((group: any) => group.id) || '';
  }
}
 selectBuyerGroup() {
  let newStatus = true;
  this.select.options.forEach((item: MatOption) => {
    if (!item.selected) {
      newStatus = false;
      this.disabledOrderIdFields = true;
      const buyerId = this.searchForm.controls.buyerGroup.value
    const selectedBuyerGroup = buyerId.map((id: any) => id ); 
    this.selectedBuyerGroup = selectedBuyerGroup 
    console.log('selectedBuyerGroup', this.selectedBuyerGroup)
    }
    else {
      const buyerId = this.searchForm.controls.buyerGroup.value
      this.selectedBuyerGroup = buyerId.map((id: any) => id ) || '';  
      console.log('selectedBuyerGroup else', this.selectedBuyerGroup)  
    }
  });
  this.allSelected = newStatus;
  this.fetch_retailer(0,0,this.selectedBuyerGroup)
  // this.fetchRetailerSubject.next(this.selectedBuyerGroup);

}

totalAmount(order:Order)
{
  return Number(order.totalAmount.valueWithoutTax)+Number(order.netTaxAmount)
}

toDetails(id:string,index:number){
  this.orderByIdService.getOrderlist(id)
  .subscribe(
    data => {
    if(data.acknowledgement?.status === "SUCCESS")
    {
      if(data.order[0])
      {
  const dialogRef = this.dialog.open(OrderDetailsComponent, {
    width:'70%',
    data: {order:data.order[0],from:this.router.url,index:index}
  } )

  dialogRef.afterClosed().subscribe(result => {

    if(this.isSearch === false)
    
    {
    this.reset();
    this.refresh_details();
    }

    if(this.isSearch === true){
      this.search();
    }
    })
 
     }
     }
   else{
    this.toastr.error(data.acknowledgement?.message)
   }  
  },(err:HttpErrorResponse)=>{
    console.log(err);
    this.toastr.error("ERROR",'Unfortunately could not get the details of order')     
  }
  );

}

hightlight=(order:Order):boolean=>{

  return this.selection.selected.includes(order)
}

toEditOrder(order: { orderId: any; items: any; }){
  // let url=this.router.url
  // this.router.navigate(["/edit-order"],{queryParams:{orderId:this.order.orderId,from:url}})
  const dialogRef = this.dialog.open(EditOrderTableComponent, {
   width:"600px",
    data: {orderId:order.orderId,items:order.items}
  });

  
}
downloadReport(){
  // this.orderListService.getOrdeReport(this.datasource).subscribe((data => {
  //   this.blob = new Blob([data], {type: 'text/csv' });
  //   var downloadURL = window.URL.createObjectURL(data);
  //   var link = document.createElement('a');
  //   link.href = downloadURL;
  //   // window.open(downloadURL);
  //   // let blob = new Blob([data], { type: 'text/csv' });
  //   // saveAs(this.blob, "data.txt");
  //   link.download ='Custom.xlsx';
  //   link.click();
  // }))
}
clearDate(event: { stopPropagation: () => void; }) {
    event.stopPropagation();
    // this.picker2 = null;
  }

refresh_details(){
  this.selection.clear();
  this.isSearch=false;
  this.disabled1=true
  var url=this.router.url;
  if(this.page)
  {
  this.ordersService.getOrders("live",this.page.pageSize.toString(), (this.page.pageIndex * this.page.pageSize).toString())
      .subscribe(data=>{
        if(data)
        {
          this.dataSource=new MatTableDataSource(data.order);
          this.cdr.detectChanges();
          
          this.disabled1=false
        }
        
      },(error:HttpErrorResponse)=>{
        console.log("errorororororor");
        this.disabled1=false
        if (error instanceof HttpErrorResponse) {
          if (error.error instanceof ErrorEvent) {
            console.error("Error Event");
          } else {
            console.log(`error status : ${error.status} ${error.statusText}`);
            switch (error.status) {
              case 401:      //login
                this.router.navigateByUrl("/login");
                console.log(`redirect to login`);
                this.toastr.error("Session Expired, please login again")
                break;
              
            }
          }
        }
      })
    }
    else{
      this.ordersService.getOrders("live","25","0")
      .subscribe(data=>{
        if(data)
        {
          this.dataSource=new MatTableDataSource(data.order);
          this.cdr.detectChanges();
          
          this.disabled1=false
        }
        
      },(error:HttpErrorResponse)=>{
        console.log("errorororororor");
        this.disabled1=false
        if (error instanceof HttpErrorResponse) {
          if (error.error instanceof ErrorEvent) {
            console.error("Error Event");
          } else {
            console.log(`error status : ${error.status} ${error.statusText}`);
            switch (error.status) {
              case 401:      //login
                this.router.navigateByUrl("/login");
                console.log(`redirect to login`);
                this.toastr.error("Session Expired, please login again")
                break;
              
            }
          }
        }
      })
    }
}

openDialog(row:any): void {
  const dialogRef = this.dialog.open(AddPaymentDialogComponent, {
    width: '600px',
    data: {orderId:row.orderId,orderDisplayName:row.orderDisplayName,retailerId:row.retailerShop.retailerShopId,shopId:row.warehouse.warehouseId,accountBalance:row.accountBalance}
  });
  
  dialogRef.afterClosed().subscribe(result => {
    if(result){

      
      
         
       /*    this.toastr.success('Successfully added payment','Success') */
       
      }
      else
        console.log('closed');
        
    
    });
  }

  generateDeliverySlips(){
    this.orderIdArrayForDeliverySlip=[];
    if(this.selection.selected.length)
  {

console.log(this.selection.selected);
    for(let x of this.selection.selected)
     {
      this.orderIdArrayForDeliverySlip.push((x.orderId))
  }
      if(this.orderIdArrayForDeliverySlip){

      var orderIdObj={
        orderIds:this.orderIdArrayForDeliverySlip
      }
      this.processOrderService.orderByIds(this.orderIdArrayForDeliverySlip.join(",")).subscribe(
        res=>{
          console.log(res);
          if(res){
            const dialogRef = this.dialog.open(DeliverySlipComponent, {
              width:'900px',
              data: {order:res.order.orderId}
            } )
          
          this.selection.clear();
          }
        })
      }
  }
  else
  this.toastr.show("Please choose orders for which to generate the Delivery Slip")
  } 

  openDeliverySlip(row:any){
    const dialogRef = this.dialog.open(DeliverySlipComponent, {
      width: '900px',height:'600px',
      data: row.orderId
    });
    
  }

 openDialogDiscount(row:any): void {
  const dialogRefDiscount = this.dialog.open(AddDiscountDialogComponent, {
    width: '900px',
    data: {id:row.orderId,no:row.orderDisplayName}
  });
  dialogRefDiscount.afterClosed().subscribe(result => {
    if(result)

     {
        
          console.log("result")
     }  
        

})

}

openUpdatePrice(row:any): void {
  const dialogRefLivePrice = this.dialog.open(UpdateLivePriceComponent, {
    width: '800px',
    data: {orderId:row.orderId,orderDisplayName:row.orderDisplayName}
  });
  dialogRefLivePrice.afterClosed().subscribe(result => {
    if(result)

     {
    
      console.log("result")
  }
       

})

}



}
