import { Injectable } from '@angular/core';
import { Order, OrderList } from 'src/app/Models/orderList';
import {PaymentList} from 'src/app/Models/paymentList'
import { Retailer } from '../Models/loginResponse';
import { RetailerList } from '../Models/retailerList';

@Injectable({
  providedIn: 'root'
})
export class DataService {
sharedDataOrders!:OrderList
sharedDataPayment!:PaymentList
sharedRetailerList!:Retailer[]
  constructor() { }

  getOrderById=(id:string)=>JSON.parse(sessionStorage.getItem('liveOrders')!).find((x: Order)=>x.orderId===id)||JSON.parse(sessionStorage.getItem('history')!).find((x: Order)=>x.orderId===id);
}
