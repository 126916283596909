import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { NavigationEnd, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import { map, shareReplay} from 'rxjs/operators'
import { AuthService } from 'src/app/Service/auth.service';
import { DataService } from 'src/app/Service/data.service';
import { OrderListService } from 'src/app/Service/order-list.service';
import { PaymentListService } from 'src/app/Service/payment-list.service';

import {NavItem} from '../../interfaces/navItem'
import { LogoutDialogComponent } from '../logout-dialog/logout-dialog.component';

@Component({
  selector: 'app-my-navbar',
  templateUrl: './my-navbar.component.html',
  styleUrls: ['./my-navbar.component.scss']
})
export class MyNavbarComponent implements OnInit {

  refresh=false
  isExpanded=true;
  headerName:string="";
  showSubmenu:boolean=false;
  businessName!:string;
  isShowing = false;
  links = []
  loading:boolean=false;
  userName!:string;

 
  
  menuArray:NavItem[] = [
    {
      displayName: 'Live Orders & Payments',
      iconName: 'local_shipping',
      route: 'live-orders'
    },
    {
      displayName:'Order History',
      iconName: 'history',
      route: 'order-history'
    },
    {
      displayName:'Payment History',
      iconName: 'menu',
      route: 'payment-history'
    }  

  ]

  isHandset$: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.Handset)
    .pipe(
      map(result => result.matches),
      shareReplay()
    );
    event$


  constructor(private breakpointObserver: BreakpointObserver,
    private router:Router,
    private authService:AuthService,
    private paymentListService:PaymentListService,
    private orderListService:OrderListService,
    private dataService:DataService,
    private toastr:ToastrService,
    private dialog:MatDialog,
    
    ) 
  { 
    this.businessName=sessionStorage.getItem('CompanyName')!
    this.userName = sessionStorage.getItem('userName')!
    this.event$=this.router.events.subscribe((event)=>{
      if (event instanceof NavigationEnd) {
        this.headerName= (event.url).substring(1).split('-').join(' ').toUpperCase();
        if(this.headerName.includes('ORDER DETAILS'))
          this.headerName='ORDER DETAILS'
      }
    },(err:HttpErrorResponse)=>{
      console.log("errorororororor");
        
        this.toastr.error("ERROR","Incomplete routing")
    })
    // this.businessName = sessionStorage.getItem("CompanyName");
  }

  ngOnDestroy(){
    this.event$.unsubscribe();
  }

  avatarName=((businessName:string)=> businessName
  .split(" ")
  .slice(0,2)
  .join(' '))
  // onmouseenter() {
  //   if (!this.isExpanded) {
  //     this.isShowing = true;
  //   }
  // }

  // onmouseleave() {
  //   if (!this.isExpanded) {
  //     this.isShowing = false;
  //   }
  // }



  ngOnInit(): void {
    
    this.router.navigate(['/live-orders']);
  }

  logout(){
    this.openDialog()
  }
  orderHistory(){

  } 
  liveOrders(){}
  toNumber=(number:any)=>Number(number)

   refresh_details=async()=>{
     this.refresh=true
     var url=this.router.url;
    this.orderListService.getOrderlist().subscribe(
      res=>{
        
        this.dataService.sharedDataOrders=res
       sessionStorage.setItem("liveOrders",JSON.stringify(res.order))
        
        this.paymentListService.getPaymentlist().subscribe (async resp=>{
         
          this.dataService.sharedDataPayment=resp;
       sessionStorage.setItem("paymentList",JSON.stringify(resp.payment))
          await this.router.navigateByUrl('.', { skipLocationChange: true });
          
          this.refresh=false          
        })
      }
    )
  }
  openDialog(): void {
    const dialogRef = this.dialog.open(LogoutDialogComponent, {
      width: '500px',
      data: {}
    });
  }
hasRoute(route:string){
  console.log(this.router.url.includes(route));
  
    return this.router.url.includes(route);
  }
  openOfs() {
    this.loading=true;
    var url = '';
    const obj={
      username:sessionStorage.getItem('userName')?sessionStorage.getItem('userName'):'',
      password:sessionStorage.getItem('passWord')?sessionStorage.getItem('passWord'):''
  }
 
    this.authService.openOfs()
      .subscribe(loginData => {
        
      //  window.location.href = loginData.url;
      //  window.open('https://seller.storimart.bytetale.com/#/dashboard', "_blank");
      
        this.loading=false;
       
      }, (err: HttpErrorResponse) => {
      // window.location.href = err.url;  
      if(err.url){
        this.loading=false;
        
      window.open(err.url, "_blank");
      }
        console.log(err.url);
      });
  }
}
